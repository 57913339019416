import { useEffect } from "react";
import Store from "../stores/Store.view";
import StoreHeatmap from "../stores/StoreHeatmap.view";

const StoresView = ({ onInit }) => {
  useEffect(() => {
    if (onInit) {
      onInit();
    }
  }, [onInit]);
  return <StoreHeatmap />;
};

export default StoresView;
