import { auth } from "../config/firebase.config";
import { CONST } from "../consts/consts";

const getContactList = async (body) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(body),
  };

  try {
    result = await fetch(
      `${CONST.API_CONTACTS_ROUTE}/getContactList`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getContactStatus = async () => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  try {
    result = await fetch(
      `${CONST.API_CONTACTS_ROUTE}/getContactStatus`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getContactDetail = async (contactId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(
      `${CONST.API_CONTACTS_ROUTE}/getContactDetail/${contactId}`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getContactVisit = async (body, userId) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(body),
  };

  try {
    result = await fetch(
      `${CONST.API_CONTACTS_ROUTE}/getContactVisits/${userId}`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const postContactStatusChange = async (body) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(body),
  };

  try {
    result = await fetch(
      `${CONST.API_CONTACTS_ROUTE}/postContactStatusChange`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getContactsCSV = async () => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(
      `${CONST.API_CONTACTS_ROUTE}/getDataContactCSV`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }
  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const putValidation = async (contactBody) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(contactBody),
  };

  try {
    result = await fetch(
      `${CONST.API_CONTACTS_ROUTE}/revalidateEmail`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.error;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

export {
  getContactList,
  getContactStatus,
  getContactDetail,
  getContactVisit,
  postContactStatusChange,
  getContactsCSV,
  putValidation,
};
