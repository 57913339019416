import React, { useState } from "react";
import {
  ELEMENTS_COMPONENTS,
  propertiesChild,
} from "../../../consts/splashPages.consts";
import useModalBlock from "../../../hooks/useModalBlock.hook";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import { FactoryModal } from "../../../utils/factoryElements.util";
import useToastContext from "../../../hooks/useToastContext.hook";
import { CONST } from "../../../consts/consts";

export default function BlockAdd({
  modalElementTypeSelectionVisibility,
  stateScreen,
  setModalElementTypeSelectionVisibility,
  optionsDropdown,
  dispatch
}) {
  const [label, setLabel] = useState("");
  const [text, setText] = useState("");
  const [extra, setExtra] = useState("");
  const toast = useToastContext();

  const resetStates = () => {
    setLabel("");
    setText("");
    setExtra("");
  };

  const findComponent = (componentName) => {
    return ELEMENTS_COMPONENTS.find(
      (element) => element.label === componentName
    );
  };

  const handleNewBlock = (elementName) => {
    let newComponentData = { ...findComponent(elementName) };

    if (newComponentData.unique) {
      let foundUniqueElement = stateScreen.children.find(
        (element) => element.id === newComponentData.id
      );
      if (foundUniqueElement) {
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          "Error al agregar nuevo elemento.",
          "Solo se puede tener un elemento de este tipo."
        );
        return resetStates();
      }
    }

    newComponentData.propsChild = { ...propertiesChild };
    if (newComponentData !== null) {
      newComponentData.propsChild.label = label;
      newComponentData.propsChild.text = text;
      newComponentData.propsChild.extra = extra;

      resetStates();

      stateScreen.children.push({
        ...newComponentData,
        key: stateScreen.children.length,
      });
      if (newComponentData.unique) {
        dispatch({
          type: "disableOption",
          idOption: newComponentData.id
        })
      }
    }
  };

  const [dropDownValue, ElementDropdown, footerModal] = useModalBlock(
    handleNewBlock,
    resetStates,
    setModalElementTypeSelectionVisibility,
    optionsDropdown
  );

  return (
    <>
      <NeoDialog
        header="Agregar nuevo bloque"
        visible={modalElementTypeSelectionVisibility}
        closable={false}
        footer={footerModal}
      >
        <ElementDropdown />
        <FactoryModal
          element={dropDownValue}
          setLabel={setLabel}
          label={label}
          setText={setText}
          text={text}
          setExtra={setExtra}
          extra={extra}
        />
      </NeoDialog>
    </>
  );
}
