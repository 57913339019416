import { useState } from "react";
import NeoTooltip from '../overlay/NeoTooltip.base';

const NeoCellDataStatus = (props) => {
    const [id] = useState(() => {
        const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        return Array(64).fill('').map(() => characters[Math.floor(Math.random() * characters.length)]).join('');
    });

    return (
        <span
            className={
                `cell-status 
                ${props.status ? `cell-status-${props.status}` : ""}
                ${props.circle === true ? `` : `no-circle`}
                ${props.extra ? props.extra : ""}
                ${id}-info`
            }
        >
            {
                (props.label) &&
                <span >{props.label}</span>
            }
            {
                (props.icon) &&
                <i className={`pi pi-${props.icon} `} />
            }
            {
                (props.tooltip && !props.icon) &&
                <i className={`pi pi-question-circle `} />
            }
            {
                (props.tooltip) &&
                <NeoTooltip target={`.${id}-info`} position='bottom'>
                    {props.tooltip}
                </NeoTooltip>
            }
        </span>
    );
};

export default NeoCellDataStatus;