import React, { createContext, useRef } from "react";
import NeoToast from "../design/design_components/neo/messages/NeoToast.base";

const ToastContext = createContext();
export default ToastContext;

export function ToastContextProvider({children}) {
  const toast = useRef(null)

  return(
    <>
      <ToastContext.Provider value={{toast}}>
        {children}
        <NeoToast ref={toast}/>
      </ToastContext.Provider>

    </>
  )
}
