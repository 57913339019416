import React from "react";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import ModalNewSplashPageGroup from "./components/ModalNewSplashPageGroup.component";
import SplashPagesTable from "./components/SplashPagesTable.component";
import Icono from "../../design/assets/img/wfi/icons/icon-splash-2.svg";
import CreateSplashPageGroupButtonSection from "./components/CreateSplashPageGroupButtonSection.component";

const SplashPages = () => {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title="Splash Pages" icon={Icono} />
        <CreateSplashPageGroupButtonSection />
      </NeoGridContainer>

      <SplashPagesTable />
    </>
  );
};

export default SplashPages;
