import React, { useRef } from "react";
import NeoCheckbox from "../../../design/design_components/neo/form/NeoCheckbox.base";
import NeoCheckBox from "../../../design/design_components/neo/form/NeoCheckbox.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";

export default function StoreChecker(props) {
  const mediaTableRef = useRef();

  const changeValue = (indexValue) => {
    let storeArray = Array.from(props.stores);
    const newStores = storeArray.map((store) => {
      if (store.id === indexValue) {
        store.checked = !store.checked;
        return store;
      }
      return store;
    });

    props.setStores(newStores);
  };

  const checkedBody = (storeData) => {
    return (
      <NeoCheckbox
        value={storeData.checked}
        onChange={() => changeValue(storeData.id)}
      />
    );
  };

  return (
    <>
      <NeoTable
        emptyMessage="No hay ninguna sucursal registrada"
        ref={mediaTableRef}
        value={props.stores}
        removableSort
        dataKey="id"
        paginator
        rows={5}
      >
        <NeoTableColumn
          field="checked"
          header="Selección"
          body={(e) => checkedBody(e)}
          sortable
        />

        <NeoTableColumn
          field="storeName"
          header="Sucursal"
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder="Nombre de sucursal"
        />

        <NeoTableColumn
          field="storeBanner"
          header="Banner"
          placeholder="Nombre de banner"
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder="Nombre de Banner"
        />
        <NeoTableColumn
          field="storeVideo"
          header="Video"
          sortable
          filter
          filterMatchMode="contains"
          filterPlaceholder="Nombre de Video"
        />
      </NeoTable>
    </>
  );
}
