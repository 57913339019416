import React from "react";
import NeoPieChartCustom from "../../../design/design_components/neo/chart/NeoPieChartCustom.base";

export default function CardContactEmailValidation(props) {
  const COLORES = {
    porNombre: ["secondary-color-2", "main-color-2", "yellow2", "gray", "red"],
    porCodigo: ["#17468D", "#51cf91", "#fdd850", "#BAC3C3", "#FF0000"],
  };
  return (
    <>
        <NeoPieChartCustom
          key={Math.random()}
          colors={COLORES}
          colorsLabelByKey="porNombre"
          colorsCharByKey="porCodigo"
          data={props.contacts}
          dataKey="COUNT"
          formatNumber={true}
          contractNumber={true}
          dataName="EMAIL_VALIDATION"
        />
    </>
  );
}
