import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import NeoGridContainer from "../../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../../design/design_components/neo/title/NeoTitleMain.base";
import Icono from "../../../design/assets/img/wfi/icons/icon-multimedia.svg";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import FileUploadSingle from "./FileUploadSingle.component";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useUploadMedia from "../../../hooks/useUploadMedia.hook";
import NeoSpinner from "../../../design/design_components/neo/overlay/NeoSpinner.base";
import NeoInnerTitle from "../../../design/design_components/neo/title/NeoInnerTitle.base";
import StoreChecker from "./StoreChecker.component";
import { CONST } from "../../../consts/consts";
import useToastContext from "../../../hooks/useToastContext.hook";
import {
  getMediaDataInfo,
  getMediaStore,
  updateOnlyMediaData,
} from "../../../service/Media.service";
import Routes from "../../../routers/Routes.router.json";
import NeoTitleSecond from "../../../design/design_components/neo/title/NeoTitleSecond.base";
import { getBlob, getStorage, ref } from "firebase/storage";
import NeoInnerSubtitle from "../../../design/design_components/neo/title/NeoInnerSubtitle.base";

export default function MediaAdmin() {
  const toast = useToastContext();
  const { mediaId } = useParams();
  const { fileTypeId } = useParams();
  const [loading, setLoading] = useState(false);
  const [mediaName, setMediaName] = useState("");
  const [linkImage, setLinkImage] = useState("");
  const [resolution, setResolution] = useState("");
  const fileUploadRef = useRef();
  const [file, setFile] = useState(null);
  const history = useHistory();
  const [uploadImage, uploadVideo, updateImage, updateVideo] = useUploadMedia();

  const [stores, setStores] = useState([]);
  const storage = getStorage();
  const [fileFirebaseRef, setFileFirebaseRef] = useState(null);

  useEffect(() => {
    let mount = true;
    if (mount) {
      settingUpStores();
      if (mediaId && fileTypeId) {
        settingUpDataMultimedia();
      }
    }

    return () => {
      mount = false;
    };
  }, []);

  const settingUpStores = async () => {
    let response = await getMediaStore(mediaId, fileTypeId);

    if (!response.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      setStores(response.result);
    }
  };

  const settingUpDataMultimedia = async () => {
    setLoading(true);
    let response = await getMediaDataInfo(mediaId, fileTypeId);

    if (!response.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      setMediaName(response.result.NAME);
      if (Number(fileTypeId) !== 3) {
        await setFirebaseResource(response);
      }
      setLinkImage(response.result.CLICK_URL);
    }

    setLoading(false);
  };

  const setFirebaseResource = async (response) => {
    const httpsReference = ref(storage, response.result.URL);
    setFileFirebaseRef(httpsReference);
    const blobReference = await getBlob(httpsReference);
    let fileNameRef = httpsReference.name;
    try {
      if (httpsReference.name.includes("_")) {
        if (!isNaN(httpsReference.name.split(/_(.*)/s)[0])) {
          fileNameRef = httpsReference.name.split(/_(.*)/s)[1];
        }
      }
    } catch (e) {
      fileNameRef = httpsReference.name;
    }
    setFile(
      new File([blobReference], fileNameRef, {
        type: blobReference.type,
      })
    );
    fileUploadRef.current?.state?.files?.push(
      new File([blobReference], fileNameRef, {
        type: blobReference.type,
        objectURL: response.result.URL,
      })
    );
    setLoading(false);
  };

  // Sets resolution of the media
  useEffect(() => {
    if (file?.type.toString().includes("image")) {
      let imageTest = new Image();
      imageTest.src = URL.createObjectURL(file);
      imageTest.onload = function () {
        setResolution(`${imageTest.width}x${imageTest.height}`);
      };
    }

    if (file?.type?.toString().includes("video")) {
      let videoTest = document.createElement("video");
      videoTest.src = URL.createObjectURL(file);
      videoTest.onloadeddata = function (event) {
        setResolution(`${videoTest.videoWidth}x${videoTest.videoHeight}`);
      };
    }
  }, [file]);

  const titleChoose = () => {
    if (!mediaId && !fileTypeId) {
      return "Agregar nueva multimedia";
    } else {
      return "Modificar multimedia";
    }
  };

  const optionalLink = () => {
    if (file?.type.toString().includes("image")) {
      return (
        <NeoColumn col="12">
          <NeoInputText
            label="Url completo para redirección de click"
            value={linkImage}
            onChange={(event) => setLinkImage(event.target.value)}
            maxLength={2048}
          />
        </NeoColumn>
      );
    }
  };

  const checkIfAllInputs = () => {
    if (Number(fileTypeId) !== 3) {
      return mediaName !== "" && file;
    } else {
      return mediaName !== "";
    }
  };

  const submitMedia = async () => {
    setLoading(true);

    let storesIds = stores.filter((store) => store.checked);

    let metaData = {
      mediaName: mediaName,
      resolution: resolution,
      linkImage: linkImage,
      storesIds: storesIds,
      mediaId: mediaId,
    };

    if (mediaId && fileTypeId) {
      if (Number(fileTypeId) !== 3) {
        if (file?.type.toString().includes("image")) {
          await updateImage(file, metaData, fileFirebaseRef);
        }

        if (file?.type.toString().includes("video")) {
          await updateVideo(file, metaData, fileFirebaseRef);
        }
      } else {
        let result = await updateOnlyMediaData({ metaData: metaData });
        if (result.success) {
          toast.setMessage(
            CONST.SEVERITY.SUCCESS,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
            CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
          );
          history.push(Routes.media.home);
        } else {
          toast.setMessage(
            CONST.SEVERITY.ERROR,
            CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
            CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
          );
        }
        setLoading(false);
      }
    } else {
      if (file?.type.toString().includes("image")) {
        await uploadImage(file, metaData);
      }

      if (file?.type.toString().includes("video")) {
        await uploadVideo(file, metaData);
      }
    }
  };

  const fileUploaderByTypeSubmit = () => {
    if (mediaId && fileTypeId) {
      // Change hardcoded constant
      if (Number(fileTypeId) === 1) {
        return (
          <FileUploadSingle
            maxSize={20000000}
            typeFileToAccept={["video/x-matroska", "video/ogg", "video/mp4"]}
            ref={fileUploadRef}
            setFileSelected={setFile}
          />
        );
      } else if (Number(fileTypeId) === 2) {
        return (
          <FileUploadSingle
            maxSize={20000000}
            typeFileToAccept={["image/jpeg", "image/png"]}
            ref={fileUploadRef}
            setFileSelected={setFile}
          />
        );
      } else {
        return (
          <NeoInnerSubtitle>
            Consulte con su asesor para poder modificar el contenido de está
            multimedia
          </NeoInnerSubtitle>
        );
      }
    } else {
      return (
        <FileUploadSingle
          maxSize={20000000}
          typeFileToAccept={[
            "image/jpeg",
            "image/png",
            "video/x-matroska",
            "video/ogg",
            "video/mp4",
          ]}
          ref={fileUploadRef}
          setFileSelected={setFile}
        />
      );
    }
  };

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          col="12"
          title={titleChoose()}
          icon={Icono}
          goBack={true}
        />
      </NeoGridContainer>
      {loading ? <NeoSpinner /> : null}
      <NeoCard>
        <NeoColumn col="6">
          <NeoInputText
            label="Nombre de multimedia"
            value={mediaName}
            onChange={(event) => setMediaName(event.target.value)}
            maxLength={120}
          />
        </NeoColumn>
        <NeoColumn col="12">{fileUploaderByTypeSubmit()}</NeoColumn>
        {optionalLink()}
      </NeoCard>

      <br />

      <NeoInnerTitle extra="primero">Configuración de uso</NeoInnerTitle>
      <NeoCard>
        <NeoInnerTitle extra="primero">
          Sucursales que mostrarán esta multimedia
        </NeoInnerTitle>
        <StoreChecker stores={stores} setStores={setStores} />
      </NeoCard>
      <NeoButtonSection align="right">
        <NeoButtonMain label="Cancelar" onClick={() => history.goBack()} />
        <NeoButtonMain
          label="Guardar"
          onClick={() => submitMedia()}
          disabled={!checkIfAllInputs()}
        />
      </NeoButtonSection>
    </>
  );
}
