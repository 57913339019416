import React, { useState } from "react";
import LoginForm from "./components/LoginForm.component";
import NeoSingleContainer from "../../design/design_components/neo/layout/NeoSingleContainer.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoLink from "../../design/design_components/neo/button/NeoLink.base";
import NeoInnerTitle from "../../design/design_components/neo/title/NeoInnerTitle.base";
import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoButtonSecondary from "../../design/design_components/neo/button/NeoButtonSecondary.base";
import LogoWhite from "../../design/assets/img/wfi/logo.svg";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../config/firebase.config";

export default function Login() {
  return (
    <NeoSingleContainer>
      <NeoGridContainer col="12" subExtra="h-100">
        <NeoColumn col="12" extra="px-0">
          <a href="">
            <img className="logo-intro" src={LogoWhite} alt="NeoValidador" />
          </a>
        </NeoColumn>

        <NeoColumn md="6" extra="p-md-offset-3 ">
          <NeoCard extra="text-align-center">
            <LoginForm />
          </NeoCard>
        </NeoColumn>
      </NeoGridContainer>
    </NeoSingleContainer>
  );
}
