import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import NeoSingleContainer from "../../design/design_components/neo/layout/NeoSingleContainer.base";
import NeoSpinner from "../../design/design_components/neo/overlay/NeoSpinner.base";
import { signInToken } from "../../utils/auth_signup_password.util";
import { cache } from "../../utils/sso.util";

export default function LoginSSO() {
  const { token } = useParams();
  const history = useHistory();
  const [error, setError] = useState("");
  useEffect(() => {
    signInWithToken();
  }, []);

  const signInWithToken = () => {
    cache.clear("sessionId");
    let success = signInToken(token);
    console.log(success);
    if (success) {
      history.push("/dashboard");
    } else {
      setError("ERRRROR");
    }
  };

  return <></>;
}
