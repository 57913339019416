import Submit from "../views/splashPages/components/splashPageElements/button/Submit/Submit.sp.component";
import Birthday from "../views/splashPages/components/splashPageElements/fields/Birthday/Birthday.sp.component";
import Email from "../views/splashPages/components/splashPageElements/fields/Email/Email.sp.component";
import FirstName from "../views/splashPages/components/splashPageElements/fields/FirstName/FirstName.sp.component";
import LastName from "../views/splashPages/components/splashPageElements/fields/LastName/LastName.sp.component";
import FullName from "../views/splashPages/components/splashPageElements/fields/Name/FullName.sp.component";
import Phone from "../views/splashPages/components/splashPageElements/fields/Phone/Phone.sp.component";
import Logo from "../views/splashPages/components/splashPageElements/others/Logo/Logo.sp.component";
import TermAndCondition from "../views/splashPages/components/splashPageElements/text/Link/TermsAndConditions.sp.component";
import NormalText from "../views/splashPages/components/splashPageElements/text/NormalText/NormalText.sp.component";
import SubtitleText from "../views/splashPages/components/splashPageElements/text/Subtitle/SubtitleText.sp.component";
import TitleText from "../views/splashPages/components/splashPageElements/text/Title/TitleText.sp.component";

export const ELEMENTS_LIST_NAME = {
  EMAIL: { label: "Email", name: "Email", id: 1 },
  FULLNAME: { label: "Nombre completo", name: "FullName", id: 2 },
  TITLE: { label: "Título", name: "Title", id: 3 },
  SUBTITLE: { label: "Subtítulo", name: "Subtitle", id: 4 },
  TEXT: { label: "Párrafo", name: "Text", id: 5 },
  SUBMIT: { label: "Botón", name: "Submit", id: 6 },
  TERM: { label: "Términos y Condiciones", name: "Term", id: 7 },
  LOGO: { label: "Logo", name: "Logo", id: 8 },
  PHONE: { label: "Teléfono", name: "Phone", id: 9 },
  BIRTHDAY: { label: "Cumpleaños", name: "Birthday", id: 10 },
  FIRSTNAME: { label: "Nombre", name: "FirstName", id: 11 },
  LASTNAME: { label: "Apellido", name: "LastName", id: 12 },
};

export const GO_TO_SETTINGS_IDS = [
  ELEMENTS_LIST_NAME.LOGO.id,
  ELEMENTS_LIST_NAME.TERM.id,
];

export const GROUPS_OPTIONS = {
  FIELDS: {
    id: 0,
    label: "Campos",
    items: [],
  },
  TEXTS: {
    id: 1,
    label: "Textos",
    items: [],
  },
};

export const propertiesChild = { label: "", extra: "", text: "", name: "" };

export const ELEMENTS_COMPONENTS = [
  {
    label: ELEMENTS_LIST_NAME.EMAIL.label,
    name: ELEMENTS_LIST_NAME.EMAIL.name,
    Component: Email,
    propsChild: { ...propertiesChild },
    id: 1,
    lock: true,
    unique: true,
    inUse: false,
    group: GROUPS_OPTIONS.FIELDS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.FULLNAME.label,
    name: ELEMENTS_LIST_NAME.FULLNAME.name,
    Component: FullName,
    propsChild: { ...propertiesChild },
    id: 2,
    lock: false,
    unique: true,
    inUse: false,
    group: GROUPS_OPTIONS.FIELDS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.TITLE.label,
    name: ELEMENTS_LIST_NAME.TITLE.name,
    Component: TitleText,
    propsChild: { ...propertiesChild },
    id: 3,
    lock: false,
    unique: false,
    inUse: false,
    group: GROUPS_OPTIONS.TEXTS.id,
  },

  {
    label: ELEMENTS_LIST_NAME.SUBTITLE.label,
    name: ELEMENTS_LIST_NAME.SUBTITLE.name,
    Component: SubtitleText,
    propsChild: { ...propertiesChild },
    id: 4,
    lock: false,
    unique: false,
    inUse: false,
    group: GROUPS_OPTIONS.TEXTS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.TEXT.label,
    name: ELEMENTS_LIST_NAME.TEXT.name,
    Component: NormalText,
    propsChild: { ...propertiesChild },
    id: 5,
    lock: false,
    unique: false,
    inUse: false,
    group: GROUPS_OPTIONS.TEXTS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.SUBMIT.label,
    name: ELEMENTS_LIST_NAME.SUBMIT.name,
    Component: Submit,
    propsChild: { ...propertiesChild },
    id: 6,
    lock: true,
    unique: true,
    inUse: false,
    group: null,
  },
  {
    label: ELEMENTS_LIST_NAME.TERM.label,
    name: ELEMENTS_LIST_NAME.TERM.name,
    Component: TermAndCondition,
    propsChild: { ...propertiesChild },
    id: 7,
    lock: true,
    unique: true,
    inUse: false,
    group: GROUPS_OPTIONS.TEXTS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.LOGO.label,
    name: ELEMENTS_LIST_NAME.LOGO.name,
    Component: Logo,
    propsChild: { ...propertiesChild },
    id: 8,
    lock: true,
    unique: true,
    inUse: false,
    group: GROUPS_OPTIONS.TEXTS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.PHONE.label,
    name: ELEMENTS_LIST_NAME.PHONE.name,
    Component: Phone,
    propsChild: { ...propertiesChild },
    id: 9,
    lock: false,
    unique: true,
    inUse: false,
    group: GROUPS_OPTIONS.FIELDS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.BIRTHDAY.label,
    name: ELEMENTS_LIST_NAME.BIRTHDAY.name,
    Component: Birthday,
    propsChild: { ...propertiesChild },
    id: 10,
    lock: false,
    unique: true,
    inUse: false,
    group: GROUPS_OPTIONS.FIELDS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.FIRSTNAME.label,
    name: ELEMENTS_LIST_NAME.FIRSTNAME.name,
    Component: FirstName,
    propsChild: { ...propertiesChild },
    id: 11,
    lock: false,
    unique: true,
    inUse: false,
    group: GROUPS_OPTIONS.FIELDS.id,
  },
  {
    label: ELEMENTS_LIST_NAME.LASTNAME.label,
    name: ELEMENTS_LIST_NAME.LASTNAME.name,
    Component: LastName,
    propsChild: { ...propertiesChild },
    id: 12,
    lock: false,
    unique: true,
    inUse: false,
    group: GROUPS_OPTIONS.FIELDS.id,
  },
];
