import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoSingleContainer from "../../design/design_components/neo/layout/NeoSingleContainer.base";
import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoInnerTitle from "../../design/design_components/neo/title/NeoInnerTitle.base";
import {
  sendReviewCommentOpinion,
  checkIfAlreadyReviewed
} from "../../service/Mail.service";
import NeoRating from "../../design/design_components/neo/form/NeoRating.base";
import NeoInputTextarea from "../../design/design_components/neo/form/NeoInputTextarea.base";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";

export default function RecieveReview() {
  const { visitId, value: initialRating } = useParams();
  const [feedbackData, setFeedbackData] = useState(null);
  const [opinion, setOpinion] = useState(undefined);
  const [rating, setRating] = useState(initialRating);
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    let isMounted = true;

    if (visitId) {
      checkIfAlreadyReviewed({ visitId }).then((resp) => {
        if (isMounted) {
          if(resp.result?.REVIEW_STARS) {
            setFeedbackData([true, resp.result])
          } else {
            setFeedbackData([false, new Date(resp.result)])
          }
        }
      });
    }

    return () => {
      isMounted = false;
    };
  }, [visitId]);

  const renderRating = ([answered, data]) => {
    if (!answered) {
      const month = data.getMonth() + 1;
      const day = data.getDate();
      const year = data.getFullYear();
      const dateFormatted = `${day}/${month}/${year}`

      return (
        <>
          <NeoInnerTitle style={{textAlign: "center" }} extra="primero">¡Tu opinión nos importa!</NeoInnerTitle>
          <p style={{textAlign: "center", marginBottom: 0}}>Tu calificación sobre nuestro <strong>servicio de conexión a internet</strong> durante tu visita del día {dateFormatted} fue:</p>
          <p style={{textAlign: "center", "margin-top": 5, fontSize: 12, opacity: 0.8}}>Da clic en las estrellas para cambiar tu respuesta</p>
          <NeoRating
            value={rating}
            onChange={(e) => {
              setRating(e.value);
            }}
            stars={5}
            cancel={false}
          />

          <p style={{textAlign: "left", marginBottom: -10, marginTop: 30, paddingLeft: "0.5rem"}}>Nos encantaría saber más...</p>
          <NeoInputTextarea
            value={opinion}
            placeholder={"El registro fue muy rápido y pude acceder a mis redes sociales sin problema"}
            onChange={(e) => setOpinion(e.target.value)}
          />

          <NeoButtonMain
            label="Enviar encuesta"
            onClick={sendReviewComment}
          />
        </>
      );
    } else {
      const updatedAtDate = (new Date(data.UPDATED_AT))
      const month = updatedAtDate.getMonth() + 1;
      const day = updatedAtDate.getDate();
      const year = updatedAtDate.getFullYear();
      const dateFormatted = `${day}/${month}/${year}`
      
      return (
        <>
          <NeoInnerTitle extra="primero" style={{textAlign: "center"}}>
            Ya respondiste esta encuesta
          </NeoInnerTitle>
          <p>
          Tu calificación sobre nuestro <strong> servicio de conexión a internet </strong> durante tu visita del día {dateFormatted} fue:</p>
          <NeoRating value={data.REVIEW_STARS} readOnly cancel={false} />
          {data.COMMENTS && <>
            <p style={{textAlign:"left", marginBottom: 0}}>Tu comentario:</p>
            <p style={{textAlign:"left"}}>{data.COMMENTS}</p>
          </>}
        </>
      );
    }
  };

  const finishedView = () => {
    return (
      <>
        <NeoInnerTitle style={{ textAlign: "center" }} extra="primero">
          Gracias por tu tiempo
        </NeoInnerTitle>
        <p>Tus respuestas son muy importantes para nosotros</p>
      </>
    );
  };

  const sendReviewComment = async () => {
    setFinished(true);
    setFeedbackData(null);
    await sendReviewCommentOpinion({
      visitId: visitId,
      message: opinion ?? '',
      rating: Number(rating)
    });
    setFeedbackData(false);
  };

  return (
    <NeoSingleContainer>
      <NeoGridContainer col="12" subExtra="h-100">
        <NeoColumn col="12" extra="px-0"></NeoColumn>
        <NeoColumn md="6" extra="p-md-offset-3 ">
          <NeoCard extra="text-align-center">
            <NeoColumn extra="px-0">
              {finished ? (
                finishedView()
              ) : feedbackData === null ? (
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "2em" }}
                ></i>
              ) : (
                renderRating(feedbackData)
              )}
            </NeoColumn>
          </NeoCard>
        </NeoColumn>
      </NeoGridContainer>
    </NeoSingleContainer>
  );
}
