import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { CONST } from "../../../consts/consts";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "../../../design/design_components/neo/button/NeoButtonOutlined.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import useToastContext from "../../../hooks/useToastContext.hook";
import { postNewSplashPageGroup } from "../../../service/SplashPages.service";

export default function ModalNewSplashPageGroup() {
  const [modalVisibility, setModalVisibility] = useState(false);
  const [splashPageGroupName, setSplashPageGroupName] = useState("");
  const toast = useToastContext();
  const history = useHistory();

  const handleSubmit = async () => {
    if (splashPageGroupName === "") {
      setModalVisibility(false);
      return toast.setMessage(
        CONST.SEVERITY.ERROR,
        "No puede tener el nombre vacío",
        "Por favor, llene correctamente el campo nombre"
      );
    }
    let submit = await postNewSplashPageGroup({ name: splashPageGroupName });

    if (submit.success) {
      toast.setMessage(
        CONST.SEVERITY.SUCCESS,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
      );
      history.go(0);
    } else {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    }
    setModalVisibility(false);
  };

  const handleCancelBlock = () => {
    setModalVisibility(false);
  };

  const footerModal = (
    <>
      <NeoButtonOutlined label="Cancelar" onClick={handleCancelBlock} />
      <NeoButtonMain label={"Aceptar"} onClick={handleSubmit} />
    </>
  );

  return (
    <>
      <NeoButtonMain
        label="Crear nuevo grupo de splash pages"
        onClick={() => setModalVisibility(true)}
      />
      <NeoDialog
        header="Crear nuevo grupo de splash pages"
        visible={modalVisibility}
        closable={false}
        footer={footerModal}
      >
        <NeoInputText
          label={"Nombre"}
          value={splashPageGroupName}
          onChange={(e) => setSplashPageGroupName(e.target.value)}
        />
      </NeoDialog>
    </>
  );
}
