import React from "react";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import NewMediaButton from "./components/NewMediaButton.component";
import MediaTable from "./components/MediaTable.component";
import Icono from "../../design/assets/img/wfi/icons/icon-multimedia.svg";

export default function MediaList() {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain
          title="Anuncios"
          col="12"
          icon={Icono}
        />

        <NeoButtonSection
          align="right"
          col="12"
          md="6"
          extra="p-col-align-center p-flex-sm-row p-flex-column"
        >
          {/* <NewMediaButton /> */}
        </NeoButtonSection>
      </NeoGridContainer>

      <MediaTable />
    </>
  );
}
