import React, { useState, useEffect } from "react";
import { CONST } from "../../../consts/consts";
import NeoDataSecond from "../../../design/design_components/neo/data/NeoDataSecond.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import { ScrollPanel } from "primereact/scrollpanel";
import Contract from "./Contract.component";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import { mxCurrencyFormatter } from "../../../utils/commons.util";
import Routes from "../../../routers/Routes.router.json";
import { useHistory } from "react-router-dom";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoInnerTitle from "../../../design/design_components/neo/title/NeoInnerTitle.base";
import { signupWithPassword } from "../../../utils/auth_signup_password.util";
import { postNewUser } from "../../../service/User.service";
import UserDataModal from "./UserDataModal.component";
import useToastContext from "../../../hooks/useToastContext.hook";

export default function SummaryDetails({ location }) {
  const noValue = CONST.NO_VALUE;
  const combos = CONST.COMBO_DEALS;
  const [selectedCombo, setSelectedCombo] = useState({
    apCount: 0,
    validationCount: 0,
    emailCount: 0,
    pricePerAp: 0,
  });
  const [dealRequest, setDealRequest] = useState({ apCount: 0, email: "" });
  const history = useHistory();
  const [fullName, setFullName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToastContext();

  useEffect(() => {
    let mount = true;

    const getSummary = (apRequested) => {
      let applicableCombos = combos.filter(
        (combo) => combo.apCount <= apRequested
      );
      return applicableCombos.reduce((prev, current) =>
        prev.apCount > current.apCount ? prev : current
      );
    };

    if (mount) {
      setSelectedCombo(getSummary(location.state.apCount));
      setDealRequest(location.state);
    }

    return () => {
      mount = false;
    };
  }, [location.state, combos]);

  const getPrice = () => {
    if (dealRequest.apCount && selectedCombo.pricePerAp) {
      return mxCurrencyFormatter.format(
        dealRequest.apCount * selectedCombo.pricePerAp
      );
    }
    return noValue;
  };

  const checkFullNameHasBeenPlaced = () => {
    let fullNameToTest = fullName;

    return !/\s/g.test(fullNameToTest.trim());
  };

  const payHandler = async () => {
    setLoading(true);
    let email = location.state.email;
    let password = Math.random().toString(36).slice(-8);
    let user;
    try {
      user = await signupWithPassword(email, password);
    } catch (error) {
      setLoading(false);
      return toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    }
    let result;
    result = await postNewUser({
      uid: user.uid,
      apCount: dealRequest.apCount,
      password: password,
      email: email,
      fullName: fullName,
      company: company,
      phone: phone,
    });

    if (result.success) {
      history.push(Routes.deals.shippingConfiguration, {
        apCount: dealRequest.apCount,
      });
      setLoading(false);
    } else {
      setLoading(false);
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    }
  };

  const handleNewUser = () => {
    payHandler();
  };

  return (
    <>
      <NeoInputText
        label="Nombre completo del contratante"
        name="purchaserName"
        value={fullName}
        onChange={(e) => setFullName(e.target.value)}
      />
      <NeoInnerTitle>Detalle de contratación</NeoInnerTitle>

      <UserDataModal
        setPhone={setPhone}
        setCompany={setCompany}
        phone={phone}
        company={company}
        visible={modalVisible}
        submit={handleNewUser}
        loading={loading}
      />

      <NeoColumn md={4} col="12">
        <NeoDataSecond fact={dealRequest.apCount} label="Cantidad de AP's" />
      </NeoColumn>
      <NeoColumn md={4} col="12">
        <NeoDataSecond
          fact={selectedCombo.validationCount}
          label="Cantidad de validaciones"
        />
      </NeoColumn>
      <NeoColumn md={4} col="12">
        <NeoDataSecond
          fact={selectedCombo.emailCount}
          label="Cantidad de envíos de correos"
        />
      </NeoColumn>
      <NeoColumn md={6} col="12">
        <NeoDataSecond fact={"12 Meses"} label="Plazo de pago" />
      </NeoColumn>
      <NeoColumn md={6} col="12">
        <NeoDataSecond fact={`${getPrice()} MXN`} label="Mensualidad" />
      </NeoColumn>

      <NeoInnerTitle>Contrato</NeoInnerTitle>

      <NeoColumn col="12">
        <ScrollPanel style={{ width: "100%", height: "200px" }}>
          <Contract />
        </ScrollPanel>
      </NeoColumn>
      <NeoButtonSection align="center">
        <NeoButtonMain
          label={"Firmar contrato y continuar a pago"}
          onClick={() => setModalVisible(true)}
          disabled={checkFullNameHasBeenPlaced()}
          loading={loading}
        />
      </NeoButtonSection>
    </>
  );
}
