import React from "react";
import NeoMultiSelectForm from "../../../design/design_components/neo/form/NeoMultiSelect.base";
import {
  MAC_COLUMN,
  DURATION_COLUMN,
  OS_COLUMN,
  REVIEW_COLUMN,
  SENTIMENT_COLUMN
} from "../filterableColumns.enum.js";

const FilterableColumns = ({columnsSelected, setColumnsSelected}) => {
  const values = [
    DURATION_COLUMN,
    OS_COLUMN,
    MAC_COLUMN,
    REVIEW_COLUMN,
    SENTIMENT_COLUMN
  ];

  const selectedColumnsLabel = () => {
    if (columnsSelected) {
      if (columnsSelected.length === values.length) return "Todas";
      return `${columnsSelected.length} columnas seleccionadas`;
    }
  };

  return (
    <div style={{ minWidth: 257 }}>
      <label
        style={{
          fontSize: "12px",
          color: "#767F87",
          marginLeft: 8,
          marginTop: 20,
          marginBottom: -13,
          display: "block"
        }}
      >
        Filtrar columnas
      </label>
      <NeoMultiSelectForm
        style={{ maxWidth: 250 }}
        value={columnsSelected}
        options={values}
        onChange={(e) => {
          setColumnsSelected(e.value);
        }}
        placeholder="Seleccionar columnas"
        selectedItemsLabel={selectedColumnsLabel()}
        selectedItemTemplate={(val) => {
          if (columnsSelected.length === 2) {
            const [first, second] = columnsSelected;
            if (val === first) return first + ", ";
            return second;
          } else if (columnsSelected.length === 1) {
            return val;
          } else {
            return val;
          }
        }}
        maxSelectedLabels={2}
      />
    </div>
  );
};

export default FilterableColumns;
