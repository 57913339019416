import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import { getMediaInfoList } from "../../../service/Media.service";
import { filterRangeNumber } from "../../../utils/commons.util";
import { dateFormat, filterDate } from "../../../utils/date.utils";
import * as MEDIA_CONSTS from "../../../consts/media.consts.js";

export default function MediaTable() {
  const mediaTableRef = useRef();
  const [loading, setLoading] = useState(false);
  const [mediaCollection, setMediaCollection] = useState([]);
  const history = useHistory();

  const mediaTypeCollection = [
    { label: "Video", value: "Video" },
    { label: "Imagen", value: "Imagen" },
    { label: "HTML", value: "HTML" },
  ];

  const gettingMediaData = async () => {
    setLoading(true);
    const rawMedia = await getMediaInfoList();
    setMediaCollection(rawMedia.result);
    setLoading(false);
  };

  useEffect(() => {
    let mount = true;

    if (mount) {
      gettingMediaData();
    }
    return () => {
      mount = false;
    };
  }, []);

  const nameFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"NAME"}
        placeholder={"Buscar nombre de multimedia"}
        filterMatch={"contains"}
      />
    );
  };

  const mediaTypeFilterElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={mediaTableRef}
        options={mediaTypeCollection}
        field={"FILE_TYPE"}
        placeholder="Todos"
        selectedItemsLabel="{0} tipos de media"
      />
    );
  };

  const createdAtFilterElement = () => {
    return (
      <NeoDateRangeFilter
        ref={mediaTableRef}
        field={"CREATED_AT"}
        placeholder={"Buscar por fecha"}
        matchFilter="custom"
      />
    );
  };

  const renderedFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"RENDERED"}
        minPlaceholder={"Mín"}
        maxPlaceholder={"Máx"}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const clicksFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"CLICKS"}
        minPlaceholder={"Mín"}
        maxPlaceholder={"Máx"}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const createdAtBodyTemplate = (createdAt) => {
    return (
      <>
        <span className="p-column-title">Fecha de creación</span>
        {dateFormat(createdAt)}
      </>
    );
  };

  const clicBodyTemplate = (data) => {
    return (
      <>
        <span className="p-column-title">Clics</span>
        {data.FILE_TYPE_ID === MEDIA_CONSTS.TYPE.VIDEO.ID
          ? "-"
          : data.CLICKS
        }
      </>
    );
  }

  const onSelectRow = (data) => {
    history.push(
      `/administracion-media/estadisticas/${data.ID_MEDIA}/${data.FILE_TYPE_ID}/${data.NAME}`
    );
  };

  return (
    <>
      <NeoTable
        emptyMessage="No hay ningún archivo multimedia"
        ref={mediaTableRef}
        value={mediaCollection}
        loading={loading}
        removableSort
        selectionMode={"single"}
        dataKey="ID"
        onRowSelect={(e) => onSelectRow(e.data)}
        paginator
        rows={8}
      >
        <NeoTableColumn
          field="NAME"
          header="Nombre"
          placeholder="Nombre de multimedia"
          sortable
          filter
          filterElement={nameFilterElement()}
          filterMatchMode="contains"
        />
        <NeoTableColumn
          field="FILE_TYPE"
          header="Tipo de archivo"
          sortable
          filter
          filterElement={mediaTypeFilterElement()}
          filterMatchMode="contains"
        />
        <NeoTableColumn
          field="CREATED_AT"
          header="Fecha de creación"
          body={(e) => createdAtBodyTemplate(e.CREATED_AT)}
          sortable
          filter
          filterElement={createdAtFilterElement()}
          filterFunction={filterDate}
          filterMatchMode="custom"
        />
        <NeoTableColumn field="RESOLUTION" header="Resolución" sortable />
        <NeoTableColumn
          field="RENDERED"
          header="Impresiones"
          sortable
          filter
          filterElement={renderedFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
        />
        <NeoTableColumn
          field="CLICKS"
          header="Clics"
          body={(e) => clicBodyTemplate(e)}
          sortable
          filter
          filterElement={clicksFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
        />
      </NeoTable>
    </>
  );
}
