import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import NeoSingleContainer from "../../design/design_components/neo/layout/NeoSingleContainer.base";
import NeoSpinner from "../../design/design_components/neo/overlay/NeoSpinner.base";
import { cache } from "../../utils/sso.util";
import { getLoginPrivateToken } from "../../service/User.service";

export default function RequestPrivateToken() {
  const history = useHistory();
  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get("token");
    const sessionId = cache.get("sessionId");

    getPrivateToken(token, sessionId);
  }, []);

  const getPrivateToken = async (token, sessionId) => {
    let loginAuth = await getLoginPrivateToken(sessionId, token);

    let authToken = loginAuth.result.token_auth;

    if (authToken) {
      history.push(`/loginSSO/${authToken}`);
    }
  };

  return <></>;
}
