import React, { useEffect, useState } from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "../../../design/design_components/neo/button/NeoButtonOutlined.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoInputNumber from "../../../design/design_components/neo/form/NeoInputNumber.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoDropdown from "../../../design/design_components/neo/form/NeoDropdown.base";
import NeoInputTextarea from "../../../design/design_components/neo/form/NeoInputTextarea.base";
import { getStoreConfig, putStoreConfig } from "../../../service/Store.service";
import { isEmptyTheState } from "../../../utils/commons.util";
import { useParams } from "react-router-dom";
import { CONST } from "../../../consts/consts";
import useToastContext from "../../../hooks/useToastContext.hook";
import NeoSpinner from "../../../design/design_components/neo/overlay/NeoSpinner.base";

export default function StoreConfigurationButton() {
  const { id } = useParams();
  const toast = useToastContext();
  const MaxNetworkNameLength = 24;
  const thousandMegabits = 1000;
  const [storeConfigModalVisibility, setStoreConfigModalVisibility] =
    useState(false);
  const [merakiSplashPageConfig, setMerakiSplashPageConfig] = useState(false);
  const [timeZoneData, setTimeZoneData] = useState([]);
  const [splashPagesToUseData, setSplashPagesToUseData] = useState([]);
  const [changeToNewSplashPage, setChangeToNewSplashPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState({
    networkName: null,
    storeName: null,
    speedPerClientOfUpload: null,
    speedPerClientOfDownload: null,
    timeout: null,
    physicalAddress: null,
    timeZoneOption: null,
    splashPageToUseOption: null,
    storesToAffect: [],
    apSuppliers: [],
  });
  useEffect(() => {
    const gettingStoreConfig = async () => {
      setLoading(true);
      let storeConfigDataRq = await getStoreConfig(id);
      const storeConfigData = storeConfigDataRq.result;
      const newFormState = { ...storeConfigData };
      setFormState(newFormState);
      setTimeZoneData(storeConfigData.timeZoneData);
      setSplashPagesToUseData(storeConfigData.splashPagesToUseData);
      setLoading(false);
    };
    gettingStoreConfig();
  }, []);
  const handleClickSaveConfig = async () => {
    if (isEmptyTheState(formState)) {
      return;
    }

    console.log(changeToNewSplashPage);

    if (false) {
      return setMerakiSplashPageConfig(true);
    }

    let submit = await putStoreConfig(id, formState);

    if (submit.success) {
      toast.setMessage(
        CONST.SEVERITY.SUCCESS,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
      );
    } else {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    }
    setChangeToNewSplashPage(false);
    setStoreConfigModalVisibility(false);
  };

  const handleClickSaveMerakiChange = async () => {
    let submit = await putStoreConfig(id, formState);

    if (submit.success) {
      toast.setMessage(
        CONST.SEVERITY.SUCCESS,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
      );
    } else {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    }

    setChangeToNewSplashPage(false);
    setMerakiSplashPageConfig(false);
    setStoreConfigModalVisibility(false);
  };

  const handleChangeForm = (e) => {
    if (!e?.target) {
      const name = e.originalEvent.target.name;

      if (
        name === "speedPerClientOfUpload" ||
        name === "speedPerClientOfDownload"
      ) {
        if (e.value > thousandMegabits) {
          setFormState({
            ...formState,
            [e.originalEvent.target.name]: thousandMegabits,
          });
          return;
        }
        setFormState({
          ...formState,
          [e.originalEvent.target.name]: e.value,
        });
      }
      setFormState({
        ...formState,
        [e.originalEvent.target.name]: e.value,
      });
      return;
    }
    if (
      e.target.name === "networkName" &&
      e.target.value.length > MaxNetworkNameLength
    ) {
      return;
    }
    if (e.target?.name === "splashPageToUseOption") {
      console.log("hey");
      setChangeToNewSplashPage(true);
    }
    setFormState({ ...formState, [e.target.name]: e.target.value });
  };
  const footerTemplateConfigModal = (
    <>
      <NeoButtonOutlined
        label="Cancelar"
        onClick={() => setStoreConfigModalVisibility(false)}
      />
      <NeoButtonMain label={"Guardar"} onClick={handleClickSaveConfig} />
    </>
  );

  const footerTemplateMerakiChangeModal = (
    <>
      <NeoButtonOutlined
        label="Cancelar"
        onClick={() => setMerakiSplashPageConfig(false)}
      />
      <NeoButtonMain
        label={"Continuar"}
        onClick={handleClickSaveMerakiChange}
      />
    </>
  );

  return (
    <>
      <NeoButtonMain
        label={"Configurar sucursal"}
        icon="pi pi-cog"
        onClick={() => setStoreConfigModalVisibility(true)}
      />

      <NeoDialog
        header="Alerta de cambio de splash page"
        footer={footerTemplateMerakiChangeModal}
        visible={merakiSplashPageConfig}
        closable={false}
      >
        <p>
          Los equipos Meraki que usted maneja no permiten configurar splash
          pages diferentes por sucursal. Si desea continuar con el cambio, todas
          estás sucursales se verán afectadas:
        </p>
        <ul>
          {formState.storesToAffect.map((store, key) => {
            return <li key={key}>{store.NAME}</li>;
          })}
        </ul>
        Si desea que está sucursal tenga una splash page que no afecte a otras
        sucursales, por favor, comuniquese con el área de soluciones.
      </NeoDialog>

      <NeoDialog
        header="Configuración sucursal"
        footer={footerTemplateConfigModal}
        visible={storeConfigModalVisibility}
        closable={false}
      >
        <NeoInputText
          label="Nombre de sucursal"
          name="storeName"
          value={formState.storeName}
          onChange={handleChangeForm}
          disabled
        />
        <NeoInputText
          label="Nombre de red (SSID)"
          name="networkName"
          value={formState.networkName}
          onChange={handleChangeForm}
          disabled
        />
        <NeoInputNumber
          label="Velocidad de subida"
          name="speedPerClientOfUpload"
          min={0}
          max={1000}
          suffix=" Mb"
          value={formState.speedPerClientOfUpload}
          onChange={handleChangeForm}
          disabled
        />
        <NeoInputNumber
          label="Velocidad de bajada"
          name="speedPerClientOfDownload"
          min={0}
          max={1000}
          suffix=" Mb"
          value={formState.speedPerClientOfDownload}
          onChange={handleChangeForm}
          disabled
        />

        <NeoInputNumber
          label="Timeout (tiempo de navegación)"
          name="timeout"
          suffix=" min"
          value={formState.timeout}
          onChange={handleChangeForm}
          disabled
        />
        <NeoDropdown
          label="Splash page a usar"
          name="splashPageToUseOption"
          options={splashPagesToUseData}
          value={formState.splashPageToUseOption}
          onChange={handleChangeForm}
        />
        <NeoDropdown
          label="Zona horaria"
          name="timeZoneOption"
          options={timeZoneData}
          value={formState.timeZoneOption}
          onChange={handleChangeForm}
          disabled
        />
        <NeoInputTextarea
          label="Dirección"
          name="physicalAddress"
          value={formState.physicalAddress}
          onChange={handleChangeForm}
          disabled
        />
      </NeoDialog>
    </>
  );
}
