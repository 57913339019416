import React from "react";
import NeoInputText from "../../../../../../design/design_components/neo/form/NeoInputText.base";

export default function Phone(props) {
  return (
    <NeoInputText
      value={props.label}
      label={"Etiqueta de teléfono"}
      onChange={(e) => props.setLabel(e.target.value)}
    />
  );
}
