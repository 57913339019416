import React from "react";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";

export default function BaseConfigurationModal(props) {
  const onHideHandler = () => {
    props.setVisible(false);
  };

  return (
    <>
      <NeoDialog
        header={props.header}
        visible={props.visible}
        draggable={false}
        closable={true}
        onHide={onHideHandler}
        footer={props.footer}
      >
        {props.children}
      </NeoDialog>
    </>
  );
}
