import './App.scss';
import './design/assets/styles/wfi/splash.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import "primeicons/primeicons.css";
import React from 'react';
import MainRouter from './routers/MainRouter.router';
import { AuthProvider } from "./contexts/useUserAuth.context";

export default function App() {
  return (
    <>
      <AuthProvider>
        <MainRouter />
      </AuthProvider>
    </>
  );
}

