import { useEffect } from "react";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import { useLooker } from "../../hooks/useLooker.hook";

const SocialNetworksView = ({ onInit }) => {
  useEffect(() => {
    if (onInit) {
      onInit();
    }
  }, [onInit]);
  const { url } = useLooker("5");
  return <iframe title="Dashbaord General" src={url} allowFullScreen />;
};

export default SocialNetworksView;
