/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { CONST } from "../../consts/consts";
import { useUserAuth } from "../../contexts/useUserAuth.context";
import NeoSingleContainer from "../../design/design_components/neo/layout/NeoSingleContainer.base";
import NeoSpinner from "../../design/design_components/neo/overlay/NeoSpinner.base";
import { actualUrl, cache, generarID } from "../../utils/sso.util";

export default function LoginTest() {
  const { user, loading } = useUserAuth();
  const history = useHistory();
  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(async () => {
    if (!user && !loading) {
      if (process.env.REACT_APP_ENVIRONMENT === "STAGING") {
        let sessionId = cache.get("sessionId");

        if (!sessionId) {
          sessionId = generarID();
          cache.set("sessionId", sessionId);
        }
        const urlLogin = new URL(CONST.STAGING_CONSTS.URL_LOGIN_STAGING);
        urlLogin.searchParams.set("continue", actualUrl());
        urlLogin.searchParams.set("session", sessionId);
        urlLogin.searchParams.set("app", CONST.STAGING_CONSTS.PROJECT_NAME);
        window.location.replace(urlLogin);
      } else if (process.env.REACT_APP_ENVIRONMENT === "TEST") {
        let sessionId = cache.get("sessionId");

        if (!sessionId) {
          sessionId = generarID();
          cache.set("sessionId", sessionId);
        }
        const urlLogin = new URL(CONST.TEST_CONSTS.URL_LOGIN_TEST);
        urlLogin.searchParams.set("continue", actualUrl());
        urlLogin.searchParams.set("session", sessionId);
        urlLogin.searchParams.set("app", CONST.TEST_CONSTS.PROJECT_NAME);
        window.location.replace(urlLogin);
      } else if (process.env.REACT_APP_ENVIRONMENT === "PROD") {
        let sessionId = cache.get("sessionId");

        if (!sessionId) {
          sessionId = generarID();
          cache.set("sessionId", sessionId);
        }
        const urlLogin = new URL(CONST.PROD_CONSTS.URL_LOGIN_PROD);
        urlLogin.searchParams.set("continue", actualUrl());
        urlLogin.searchParams.set("session", sessionId);
        urlLogin.searchParams.set("app", CONST.PROD_CONSTS.PROJECT_NAME);
        window.location.replace(urlLogin);
      } else {
        history.push("/login");
      }
    }

    if (user && !loading) {
      await delay(2000);
      history.push("/dashboard");
    }
  }, [user, loading]);

  return (
    <>
      <NeoSingleContainer>
        <NeoSpinner />
      </NeoSingleContainer>
    </>
  );
}
