import React from 'react'

import NeoHeader from '../../design/design_components/neo/layout/NeoHeader.base'
import NeoNavBar from '../../design/design_components/neo/menu/NeoNavBar.base'
import NeoMainContainer from '../../design/design_components/neo/layout/NeoMainContainer.base'
import Logo from "../../design/assets/img/wfi/neowifi-logo-white.svg";

export default function Principal(props) {
  return (
    <>
      {((props.headerShow === undefined || true) && props.headerShow !== false) && <>
        <NeoHeader>
          <NeoNavBar
            items={props.menu}
            logo={Logo}
            extra="navbar-principal blue-nav p-jc-between"
          />
        </NeoHeader>
        <NeoMainContainer>{props.children}</NeoMainContainer>
      </>}
      {props.headerShow === false && props.children}
    </>
  );
}
