import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";

/**
 * @param {{
 *  open: boolean
 * }} DoNotRefreshPageDialogProps
 * @returns
 */
export default function DoNotRefreshPageDialog({ open }) {
  return (
    <NeoDialog
      header="Descargando registros..."
      visible={open}
      closable={false}
      dismissableMask={false}
    >
      Tu descarga está en proceso. Por favor, no cierres ni actualices la página
      hasta que el proceso termine.
    </NeoDialog>
  );
}
