import React, { useEffect } from "react";
import { useUserAuth } from "../contexts/useUserAuth.context";
import NeoSingleContainer from "../design/design_components/neo/layout/NeoSingleContainer.base";
import NeoSpinner from "../design/design_components/neo/overlay/NeoSpinner.base";
import ClientRoutes from "./ClientRoutes.router";
import UnauthorizeRoutes from "./UnauthorizeRoutes.router";

export default function MainRouter() {
  const { user, loading } = useUserAuth();
  if (!loading) {
    if (user) {
      return <ClientRoutes />;
    } else {
      return <UnauthorizeRoutes />;
    }
  } else {
    return <NeoSpinner />;
  }
}
