import React, { useState } from 'react';
import NeoInputNumber from '../form/NeoInputNumber.base';
import NeoFlexContainer from '../layout/NeoFlexContainer.base';

const NeoRangeNumberFilter = (props, ref) => {

  const [inputMin, setInputMin] = useState(null)
  const [inputMax, setInputMax] = useState(null)

  const onInputMinChange = (e) => {
    setInputMin(e.value)
    let value = e.value
    let inputMaxValue = inputMax
    if (value !== null && props.format) {
      value = props.format(e.value)
    }

    if (inputMaxValue !== null && props.format) {
      inputMaxValue = props.format(inputMaxValue)
    }

    ref.current.filter([value, inputMaxValue], props.field, props.matchFilter ? props.matchFilter : "range")
  }

  const onInputMaxChange = (e) => {
    setInputMax(e.value)
    let value = e.value
    let inputMinValue = inputMin
    if (value !== null && props.format) {
      value = props.format(e.value)
    }

    if (inputMinValue !== null && props.format) {
      inputMinValue = props.format(inputMin)
    }
    ref.current.filter([inputMinValue, value], props.field, props.matchFilter ? props.matchFilter : "range")
  }

  return (
    <>

      <NeoFlexContainer>

        <NeoInputNumber
          col="6"
          placeholder={props.minPlaceholder}
          value={inputMin}
          name="inputMin"
          onChange={(e) => (onInputMinChange(e))}
          mode="decimal"
          minFractionDigits={props.minDigit}
          maxFracionDigits={props.maxDigit} />

        <NeoInputNumber
          col="6"
          placeholder={props.maxPlaceholder}
          value={inputMax}
          name="inputMax"
          onChange={(e) => (onInputMaxChange(e))}
          mode="decimal"
          minFractionDigits={props.minDigit}
          maxFracionDigits={props.maxDigit} />

      </NeoFlexContainer>

    </>
  )
}

export default React.forwardRef(NeoRangeNumberFilter);
