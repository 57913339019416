import NeoLine from "./NeoLine.base"
import NeoBar from "./NeoBar.base";
import NeoCartesianGrid from "./NeoCartesianGrid.base";
import NeoChartTooltip from "./NeoChartTooltip.base";
import NeoComposedChart from "./NeoComposedChart.base";
import NeoLegend from "./NeoLegend.base";
import NeoResponsiveContainer from "./NeoResponsiveContainer.base";
import NeoXAxis from "./NeoXAxis.base";
import NeoYAxis from "./NeoYAxis.base";

export default function NeoSameDataComposed(props) {
  return (
    <>
      <div style={{ width: "100%", height: 300 }} className={ props.extra ? props.extra : ''}>
        <NeoResponsiveContainer>
          <NeoComposedChart width={1000} height={250} data={props.data}>
            <NeoXAxis dataKey={props.dataKeyAxisX} />
            <NeoYAxis
              label={{
                value: props.labelAxisY,
                angle: -90,
                position: "insideBottomLeft",
              }}
            />
            <NeoChartTooltip />
            <NeoLegend />
            <NeoCartesianGrid stroke={props.strokeCartesianGrid ? props.strokeCartesianGrid : "#f5f5f5"} />
            <NeoBar
              name={props.barName}
              dataKey={props.barDataKey}
              barSize={25}
              fill={props.barFill ? props.barFill : "#17468D"}
            />
            <NeoLine
              strokeWidth={5}
              name={props.lineName}
              type="monotone"
              dataKey={props.lineDataKey}
              stroke={props.lineStroke ? props.lineStroke : "#FF9B35"}
            />
          </NeoComposedChart>
        </NeoResponsiveContainer>
      </div>
    </>
  );
}