import React from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "../../../design/design_components/neo/button/NeoButtonOutlined.base";
import BaseConfigurationModal from "./BaseConfigurationModal.component";

export default function ConfirmationModal(props) {
  const footer = (
    <>
      <NeoButtonOutlined label="Regresar" onClick={() => goBack()} />
      <NeoButtonMain label="Aceptar" onClick={() => handleSubmit()} />
    </>
  );

  const handleSubmit = () => {
    props.setVisible(false);
    props.submit();
  };

  const goBack = () => {
    if (props.valueNumber) {
      props.setHeader(`Configuración de ${props.template?.NAME.toLowerCase()}`);
      props.setParametersVisible(true);
    } else {
      props.setHeader(props.template?.NAME);
      props.setConfigurationVisible(true);
    }

    props.setVisible(false);
  };

  return (
    <>
      <BaseConfigurationModal
        header={props.header}
        template={props.template}
        visible={props.visible}
        setVisible={props.setVisible}
        setValueNumber={props.setValueNumber}
        footer={footer}
      >
        {props.description}
      </BaseConfigurationModal>
    </>
  );
}
