import React from "react";

export default function Submit(props) {
  return (
    <>
      <div className="sp-block sp-button-block">

        <input type="button" value={props.label} id="submit" name="submit" />
      </div>
    </>
  );
}
