import { useState, useEffect } from "react";
import NeoTooltip from "../overlay/NeoTooltip.base";
import NeoFlexContainer from "../layout/NeoFlexContainer.base";
import BaseInputPhoto from "../../sisu/form/BaseInputPhoto.sisu";
import NeoButtonMain from "../button/NeoButtonMain.base";

const NeoInputPhoto = (props) => {
  const [src, setSrc] = useState();
  const [height] = useState(props.height ?? "100px");
  const [id] = useState(() => {
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return Array(64)
      .fill("")
      .map(() => characters[Math.floor(Math.random() * characters.length)])
      .join("");
  });

  useEffect(() => {
    if (props.url || props.file) {
      const url = props.url ?? window.URL.createObjectURL(props.file);
      setSrc(url);
    }
  }, [props.file, props.url]);

  return (
    <NeoFlexContainer ai="center" jc="center">
      <div>
        <div
          className={`profile-photo ${
            props.circle === true ? "profile-photo--circle" : ""
          }`}
          style={{ width: props.circle === true ? height : "", height }}
        >
          <img src={src} style={{ height }} />
          <BaseInputPhoto id={`explorer-${id}`} onChange={props.onChange} />
          <NeoButtonMain
            label="Cambiar foto"
            icon="pi pi-camera"
            onClick={() => document.getElementById(`explorer-${id}`).click()}
          />
        </div>
      </div>
      {props.tooltip && (
        <>
          <i
            className={`pi pi-question-circle ${id}-info`}
            style={{ paddingLeft: "1em" }}
          />
          <NeoTooltip target={`.${id}-info`} position="bottom">
            {props.tooltip}
          </NeoTooltip>
        </>
      )}
    </NeoFlexContainer>
  );
};

export default NeoInputPhoto;
