import React, { useRef, useState, useEffect } from "react";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import { dateTimeTemplate, filterDate } from "../../../utils/date.utils";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base";
import { getSplashPageList } from "../../../service/SplashPages.service";
import { useHistory } from "react-router-dom";
import Routes from "../../../routers/Routes.router.json"
import { getUserStatus } from "../../../service/User.service";
import NeoMessages from "../../../design/design_components/neo/messages/NeoMessages.base";

const SplashPagesTable = () => {
  const [splashPages, setSplashPages] = useState([]);
  const [userState, setUserState] = useState(null);
  const [loading, setLoading] = useState(false);
  const tableSplashRef = useRef();
  const history = useHistory();
  const msgs1 = useRef(null);

  const gettingSplashPageList = async () => {
    setLoading(true);
    const splashPageList = await getSplashPageList();
    setUserState((await getUserStatus()).result);
    setSplashPages(splashPageList.result.splashPages);
    setLoading(false);
    msgs1.current.show(
      !userState?.enable_sp
        ? [
            {
              severity: "info",
              detail: (
                <>
                  Actualmente tiene una configuración personalizada para su
                  Splash Page. Si desea realizar alguna modificación consulte
                  con su desarrollador de negocio
                </>
              ),
              sticky: true,
              closable: false,
            },
          ]
        : []
    );
  };

  useEffect(() => {
    gettingSplashPageList();
  }, []);

  const nameBodyTemplate = (name) => {
    return (
      <>
        <span className="p-column-title">Nombre de la splash page</span>
        {name}
      </>
    );
  };
  const nameFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={tableSplashRef}
        field={"NAME"}
        placeholder={"Buscar por nombre"}
        filterMatch={"contains"}
      />
    );
  };
  const createdAtBodyTemplate = (date) => {
    return (
      <>
        <span className="p-column-title">Fecha de creacion</span>
        {dateTimeTemplate(date)}
      </>
    );
  };
  const createdAtFieldElement = () => {
    return (
      <NeoDateRangeFilter
        ref={tableSplashRef}
        field={"CREATED_AT"}
        placeholder={"Buscar por fecha"}
        matchFilter="custom"
      />
    );
  };
  const storeCountBodyTemplate = (count) => {
    return (
      <>
        <span className="p-column-title">Recuento</span>
        {count}
      </>
    );
  };
  const storeCountFieldElement = () => {
    return (
      <NeoInputTextFilter
        ref={tableSplashRef}
        field={"STORE_COUNT"}
        placeholder={"Buscar por cantidad"}
        filterMatch={"contains"}
      />
    );
  };
  const redirectToSplashPageDetail = (splashPageData) => {
    const { ID } = splashPageData;
    history.push(
      `${Routes.splashPages.detailsWithOutParams}/${ID}`,
      splashPageData
    );
  };
  return (
    <>
      {userState?.enable_sp || loading ? null : <NeoMessages ref={msgs1} />}
      <NeoTable
        emptyMessage="No hay ningún grupo de splash pages"
        ref={tableSplashRef}
        value={splashPages}
        loading={loading}
        removableSort
        selectionMode={userState?.enable_sp ? "single" : null}
        dataKey="ID"
        onRowSelect={(e) => redirectToSplashPageDetail(e.data)}
        extra={"no-filters"}
      >
        <NeoTableColumn
          field="NAME"
          header="Nombre"
          body={(e) => nameBodyTemplate(e.NAME)}
          sortable
          filterElement={nameFieldElement()}
        />
        <NeoTableColumn
          field="CREATED_AT"
          header="Fecha de creación"
          body={(e) => createdAtBodyTemplate(e.CREATED_AT)}
          sortable
          filterFunction={filterDate}
          filterMatchMode="custom"
          filterElement={createdAtFieldElement()}
        />
        <NeoTableColumn
          field="STORE_COUNT"
          header="Cantidad de sucursales utilizándola"
          body={(e) => storeCountBodyTemplate(e.STORE_COUNT)}
          sortable
          filterElement={storeCountFieldElement()}
        />
      </NeoTable>
    </>
  );
};

export default SplashPagesTable;
