import React from 'react';

const BaseLink = (props) => {
  return (<>
    <a {...props} className={`${props.custom ? props.custom : ""} ${props.extra ? props.extra : ""}`}>
      {props.children}
    </a>
  </>);
};

export default BaseLink;