import React, {useState} from 'react';
import VisitsTable from './components/VisitsTable.component';
import NeoGridContainer from '../../design/design_components/neo/layout/NeoGridContainer.base'
import NeoTitleMain from '../../design/design_components/neo/title/NeoTitleMain.base'
import Icono from '../../design/assets/img/wfi/icons/icon-visits.svg';
import FilterableColumns from "./components/FilterableColumns.component";
import PeriodsFilter, { currentMonthAsValidPeriod } from "./components/PeriodsFilter.component";


import {
  OS_COLUMN,
  REVIEW_COLUMN,
} from "./filterableColumns.enum.js";

export default function Visits() {

  const [columnsSelected, setColumnsSelected] = useState([
    OS_COLUMN,
    REVIEW_COLUMN
  ]);

  const [filterPeriod, setFilterPeriod] = useState(() => currentMonthAsValidPeriod());

  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title="Visitas" icon={Icono} />
        <PeriodsFilter filterPeriod={filterPeriod} setFilterPeriod={setFilterPeriod} />
        <FilterableColumns columnsSelected={columnsSelected} setColumnsSelected={setColumnsSelected} />
      </NeoGridContainer>
      <VisitsTable filterPeriod={filterPeriod} columnsSelected={columnsSelected} />
    </>
  );
}
