import { onAuthStateChanged, signOut } from "firebase/auth";
import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "../config/firebase.config";

const authContext = createContext();

export const useUserAuth = () => {
  return useContext(authContext);
};

export const logout = async () => {
  await signOut(auth);
};

export function AuthProvider({ children }) {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <authContext.Provider value={{ user, logout, loading }}>
      {children}
    </authContext.Provider>
  );
}
