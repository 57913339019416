import React from "react";
import useModalBlock from "../../../hooks/useModalBlock.hook";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import { FactoryModal } from "../../../utils/factoryElements.util";

export default function BlockEdit({
  modalEditBlockVisibility,
  setModalEditBlockVisibility,
  handleEditBlock,
  currentValue,
  setCurrentValue,
}) {
  const updateCurrentValue = (propertie, value) => {
    setCurrentValue({
      ...currentValue,
      propsChild: {
        ...currentValue.propsChild,
        [propertie]: value,
      },
    });
  };

  // eslint-disable-next-line
  const [, , footerModal] = useModalBlock(
    handleEditBlock,
    () => setModalEditBlockVisibility(false),
    setModalEditBlockVisibility,
    null,
    true
  );

  return (
    <>
      <NeoDialog
        visible={modalEditBlockVisibility}
        closable={false}
        footer={footerModal}
        header="Editar bloque"
      >
        <FactoryModal
          element={currentValue.label}
          setLabel={(value) => updateCurrentValue("label", value)}
          label={currentValue.propsChild.label}
          setText={(value) => updateCurrentValue("text", value)}
          text={currentValue.propsChild.text}
          setExtra={(value) => updateCurrentValue("extra", value)}
          extra={currentValue.propsChild.extra}
        />
      </NeoDialog>
    </>
  );
}
