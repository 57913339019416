import React, { useEffect, useState } from "react";
import { CONST } from "../../../consts/consts";
import useToastContext from "../../../hooks/useToastContext.hook";
import {
  getDashboardData,
  getDashboardOptions,
} from "../../../service/Dashboard.service";
import CardContactEmailValidation from "./CardContactEmailValidation.component";
import CardVisitChart from "./CardVisitChart.component";
import NeoInnerTitle from "../../../design/design_components/neo/title/NeoInnerTitle.base";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import NeoCardPrincipal from "../../../design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "../../../design/design_components/neo/table/NeoInnerBlankCardMainText.base";
import NeoInnerBlankCardSecondText from "../../../design/design_components/neo/table/NeoInnerBlankCardSecondText.base";
import NeoSpinner from "../../../design/design_components/neo/overlay/NeoSpinner.base";
import PeriodFilterDialog from "../../../design/design_components/neo/filters/PeriodFilterDialog.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoMultiSelect from "../../../design/design_components/neo/form/NeoMultiSelect.base";
import moment from "moment";
import "moment-timezone";

export default function ChartSection() {
  const [dashboardData, setDashboardData] = useState({
    contactEmailValidation: [],
  });
  const toast = useToastContext();
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    type: "",
    period: null,
    payload: null,
    label: "",
    periodISO: {
      from: moment().subtract(6, "d").format(),
      to: moment().add(1, "d").set({ hour: 5 }).format(),
    },
  });
  const [showDateFilterDialog, setShowDateFilterDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedDashboardOptions, setSelectedDashboardOptions] =
    useState(null);

  useEffect(() => {
    let mount = true;
    if (mount) {
      setAllDashboardData();
      console.log(selectedDateFilter.periodISO);
    }
    return () => {
      mount = false;
    };
  }, [selectedDateFilter.periodISO, selectedDashboardOptions]);

  const setAllDashboardData = async () => {
    setLoading(true);
    let dashboardData;
    if (!selectedDashboardOptions) {
      let options = await getDashboardOptions();

      if (options.success) {
        setStores(options.result);
        dashboardData = await getDashboardData({
          ...selectedDateFilter.periodISO,
          storeIds: options.result.map((e) => e.value),
        });
      }
    } else {
      dashboardData = await getDashboardData({
        ...selectedDateFilter.periodISO,
        storeIds: selectedDashboardOptions,
      });
    }

    if (!dashboardData.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      setDashboardData(dashboardData.result);
    }
    setLoading(false);
  };

  const noData = () => {
    return (
      <>
        {loading ? (
          <NeoSpinner />
        ) : (
          <NeoCardPrincipal>
            <NeoInnerBlankCardMainText>
              Aún no se han generado estadísticas para tu cuenta
            </NeoInnerBlankCardMainText>
            <NeoInnerBlankCardSecondText>
              A medida de que se vayan conectando tus clientes, en está sección
              podrás monitorearlos.
            </NeoInnerBlankCardSecondText>
          </NeoCardPrincipal>
        )}
      </>
    );
  };

  const data = () => {
    return (
      <>
        {loading ? <NeoSpinner /> : null}
        <PeriodFilterDialog
          visible={showDateFilterDialog}
          visibleSetter={setShowDateFilterDialog}
          value={selectedDateFilter}
          onChange={(event) => setSelectedDateFilter(event)}
        />
        <NeoCard extra="p-mb-6">
          <NeoInnerTitle extra="primero">Contactos</NeoInnerTitle>
          <CardContactEmailValidation
            contacts={dashboardData.contactEmailValidation}
          />
        </NeoCard>
        <NeoCard extra="p-mb-6">
          <NeoInnerTitle extra="primero">Visitas</NeoInnerTitle>
          <NeoInputText
            label="Periodo"
            value={selectedDateFilter?.label}
            onClick={() => setShowDateFilterDialog(true)}
          />
          <NeoMultiSelect
            options={stores}
            value={selectedDashboardOptions}
            onChange={(e) => setSelectedDashboardOptions(e.value)}
            placeholder={"Filtrar por sucursal"}
            selectedItemsLabel={"Filtrado por {0} sucursales"}
            maxSelectedLabels={2}
          />
          <CardVisitChart contacts={dashboardData.contactLastSevenDay} />
        </NeoCard>{" "}
      </>
    );
  };

  return (
    <>
      {dashboardData.contactEmailValidation.length === 0 ? noData() : data()}
      {/* <div style={{ display: "block", paddingBottom: "8rem" }}>
        <iframe
          src="https://lookerstudio.google.com/embed/reporting/9af671e6-0c0c-4823-b50f-bf621a27e69b/page/p_xy2eo8jv7c"
          style={{ border: "none", width: "100%", minHeight: "180rem" }}
          allowFullScreen
        />
      </div>
      <div style={{ display: "block", paddingBottom: "8rem" }}>
        <iframe
          src="https://lookerstudio.google.com/embed/reporting/4255f7b1-88c9-4884-bdce-6f75b662444b/page/p_2quiho1c8c"
          style={{ border: "none", width: "100%", minHeight: "120rem" }}
          allowFullScreen
        />
      </div>
      <div style={{ display: "block", paddingBottom: "8rem" }}>
        <iframe
          src="https://lookerstudio.google.com/embed/reporting/70dc8739-146d-4273-911b-2cd5dae55397/page/p_qt17gbfc8c"
          style={{ border: "none", width: "100%", minHeight: "60rem" }}
          allowFullScreen
        />
      </div>
      <div style={{ display: "block", paddingBottom: "8rem" }}>
        <iframe
          src="https://lookerstudio.google.com/embed/reporting/d8618600-1c9a-4b5f-b7b9-1c07e7101993/page/xEMYD"
          style={{ border: "none", width: "100%", minHeight: "60rem" }}
          allowFullScreen
        />
      </div> */}
    </>
  );
}
