import React from "react";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";

import StoreConfigurationButton from "./components/StoreConfigurationButton.component";
import StoreDetailContent from "./components/StoreDetailContent.component";
import Icono from "../../design/assets/img/wfi/icons/icon-branch-3.svg";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";

import Routes from "../../routers/Routes.router.json";
import { useParams, useHistory } from "react-router-dom";

export default function StoreDetails() {
  const params = useParams();
  const history = useHistory();

  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          title="Detalle de sucursal"
          icon={Icono}
          goback={"true"}
        />

        <NeoButtonSection align="right">
          <StoreConfigurationButton />
        </NeoButtonSection>
      </NeoGridContainer>

      <StoreDetailContent />
    </>
  );
}
