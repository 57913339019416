import SentimentIconSatisfied from "../../../design/assets/img/wfi/sentiment_satisfied.svg";
import SentimentIconMood from "../../../design/assets/img/wfi/mood.svg";
import SentimentIconNeutral from "../../../design/assets/img/wfi/sentiment_neutral.svg";
import SentimentIconDissatisfied from "../../../design/assets/img/wfi/sentiment_dissatisfied.svg";
import SentimentIconExtremelyDissatisfied from "../../../design/assets/img/wfi/sentiment_extremely_dissatisfied.svg";

export default function SentimentIcon({score}) {
  let icon;
  let scoreNum = Number(score);

  if (scoreNum >= 0.61) {
    icon = SentimentIconMood;
  } else if (scoreNum >= 0.21) {
    icon = SentimentIconSatisfied;
  } else if (scoreNum >= -0.19) {
    icon = SentimentIconNeutral;
  } else if (scoreNum >= -0.59) {
    icon = SentimentIconDissatisfied;
  } else {
    icon = SentimentIconExtremelyDissatisfied;
  }

  return <img style={{display: "inline-block", position:"absolute", transform: "translate(4px, 4px)"}} src={icon} alt="Icon" />;
}
