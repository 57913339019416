import React from "react";
import NeoSameDataComposed from "../../../design/design_components/neo/chart/NeoSameDataComposed.base";

export default function CardVisitChart(props) {
  return (
    <>
      <NeoSameDataComposed
        data={props.contacts}
        dataKeyAxisX="name"
        labelAxisY="Cantidad de contactos"
        strokeCartesianGrid={"#f5f5f5"}
        barName="Cantidad de visitas"
        barDataKey="visit"
        barFill="#17468D"
        lineName="Nuevos contactos"
        lineDataKey="newContact"
        lineStroke="#51cf91"
      />
    </>
  );
}
