import backendRequestUtil from "../utils/backendRequest.util";
import { CONST } from "../consts/consts";
import { auth } from "../config/firebase.config";

export async function getLooker(dashboard) {
    const { success, body, error } = await backendRequestUtil.get(`/api/v2/looker/dashboard/${dashboard}`)
    return success ? { success, payload: body } : { success, error }
} 

export async function getDashboards() {
    const { success, body, error } = await backendRequestUtil.get(`/api/v2/looker/dashboard`)
    return success ? { success, payload: body } : { success, error }
}


const getDataLookerDashboard = async (startDate, endDate, apIds, dashboardType) => {
    let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
    let result;

    const queryParams = new URLSearchParams({
      startDate,
      endDate,
      apIds,
      dashboardType
    }).toString();

    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: auth.currentUser.uid,
      },
    };
  
    try {
      result = await fetch(
        `${CONST.API_LOOKER_ROUTE}/getDataLookerDashboard?${queryParams}`,
        requestOptions
      );
      if (!result.ok) {
        let errorResult = await result.json();
        response.message = errorResult.details;
        throw new Error();
      }
    } catch (error) {
      return response;
    }
    const resultJson = await result.json();
    response.result = resultJson.result;
    response.success = true;
    response.message = CONST.MESSAGES.SUCCESS;
    return response;
  };
  export { getDataLookerDashboard }