import React from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import StoresConfigTable from "./components/StoresConfigTable.component";
import Icono from "../../design/assets/img/wfi/icons/icon-envio.svg";

const ServiceConfiguration = () => {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title="Configuración para envío" icon={Icono} />
      </NeoGridContainer>
      <StoresConfigTable />
    </>
  );
};

export default ServiceConfiguration;
