import React, { useEffect, useMemo, useRef, useState } from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";

import Icono from "../../design/assets/img/wfi/icons/icon-branch-3.svg";

import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoDropdown from "../../design/design_components/neo/form/NeoDropdown.base";
import NeoCalendar from "../../design/design_components/neo/form/NeoCalendar.base";

import {
  getStoreFloors,
  getStoreHeatmap,
  getStoreHeatmapDuration,
  getStoreList,
  getStoreTrajectory
} from "../../service/Store.service";
import { CONST } from "../../consts/consts";
import useToastContext from "../../hooks/useToastContext.hook";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import moment from "moment-timezone";

import NeoTabMenu from "../../design/design_components/neo/menu/NeoTabMenu.base";
import NeoSelectButton from "../../design/design_components/neo/button/NeoSelectButton.base";
import NeoTabView from "../../design/design_components/neo/panel/NeoTabView.base";
import NeoTabPanel from "../../design/design_components/neo/panel/NeoTabPanel.base";
import NeoTable from "../../design/design_components/neo/table/NeoTable.base";
import NeoColumn from "../../design/design_components/neo/table/NeoTableColumn.base";
import NeoMessage from "../../design/design_components/neo/messages/NeoMessage.base";
import StoreHeatmapTab from "./components/StoreHeatmapTab.component";
import InternalSpinner from "./components/InternalSpinner.component";
import useToggle from "../../hooks/useToggle";

const apColors = [
  { border: "#119CD4", background: "#119CD480" },
  { border: "#3DA8C0", background: "#3DA8C080" },
  { border: "#66B3AD", background: "#66B3AD80" },
  { border: "#8FBE9A", background: "#8FBE9A80" },
  { border: "#C5CB5D", background: "#C5CB5D80" },
  { border: "#DBD376", background: "#DBD37680" },
  { border: "#F7CF66", background: "#F7CF6680" },
  { border: "#F7B15D", background: "#F7B15D80" },
  { border: "#F79354", background: "#F7935480" },
  { border: "#F7764C", background: "#F7764C80" },
  { border: "#F65642", background: "#F6564280" },
  { border: "#F63439", background: "#FF373C80" },
];

const zeroPad = (num, places) => String(num).padStart(places, "0");

const ComponentFloorPlan = ({ floor, setState }) => {
  const imgRef = useRef();

  function handleImageLoad() {
    setState((prev) => ({
      ...prev,
      image: {
        naturalWidth: imgRef.current?.naturalWidth,
        naturalHeight: imgRef.current?.naturalHeight,
        clientWidth: imgRef.current?.clientWidth,
        clientHeight: imgRef.current?.clientHeight
      },
      loading: {
        ...prev.loading,
        image: false
      }
    }));
  }

  function onResize() {
    setState((prev) => ({
      ...prev,
      image: {
        naturalWidth: imgRef.current?.naturalWidth,
        naturalHeight: imgRef.current?.naturalHeight,
        clientWidth: imgRef.current?.clientWidth,
        clientHeight: imgRef.current?.clientHeight
      }
    }));
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <>
      {floor?.URL_PLAN && (
        <img
          ref={imgRef}
          key={1}
          alt="mapa"
          className="heatmap-map"
          src={floor.URL_PLAN}
          onLoad={handleImageLoad}
        />
      )}
    </>
  );
};

const formatDatetime = (date) => {
  return Intl.DateTimeFormat("es-MX", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "UTC",
    hourCycle: 'h23'
  })
    .format(date)
    .replace(",", "");
};

export default function StoreHeatmap() {
  const now = new Date();
  const toast = useToastContext();
  const [state, setState] = useState({
    loading: {
      stores: true,
      floors: false,
      aps: false,
      trajectory: false,
      image: true
    },
    index: 0,
    aps: [],
    stats: {},
    trajectory: [],
    image: {
      naturalHeight: 0,
      naturalWidth: 0,
      clientHeight: 0,
      clientWidth: 0
    }
  });
  const [options, setOptions] = useState({
    stores: [],
    floors: []
  });
  const [form, setForm] = useState({
    store: null,
    floor: null,
    from: moment(now).subtract(24, "hour").toDate(),
    to: moment(now).add(10, "minutes").toDate()
    // from: moment(now).subtract(24 * 240, "hour").toDate(),
    // to: moment(now).add(10, "day").toDate()
  });
  const [floorActiveIndex, setFloorActiveIndex] = useState(0);
  const canvasRef = useRef();
  const [apRankingDescOrder, changeApRankingOrder] = useToggle(true);

  useEffect(() => {
    getStores();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.clientWidth, canvas.clientHeight);
  }, [state.index]);

  useEffect(() => {
    if (!form.store || !form.floor) {
      return;
    }
    getAps(form.store?.ID, form.floor?.ID, { from: form.from, to: form.to });
  }, [form.store?.ID, form.floor?.ID, form.from, form.to]);

  useEffect(() => {
    if (!form.store || !form.floor) {
      return;
    }
    switch (state.index) {
      case 1:
        getApsDuration(form.store?.ID, { from: form.from, to: form.to });
        break;
      case 2:
        getTrajectory(form.store?.ID, form.floor?.ID, {
          from: form.from,
          to: form.to
        });
        break;
      case 0:
      default:
    }
  }, [form.floor?.ID, form.from, form.to, state.index]);

  async function getStores() {
    try {
      const { result, success } = await getStoreList(true, {
        networkStatus: false
      }); // userId
      if (!success) {
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
          CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
        );
        return;
      }
      setOptions((prev) => ({ ...prev, stores: result.storesList }));
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, stores: false }
      }));
    } catch (error) {
      console.error("Error cargando sucursales:", error);
    } finally {
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, stores: false }
      }));
    }
  }

  async function getFloors(storeId) {
    setState((prev) => ({
      ...prev,
      loading: { ...prev.loading, floors: true }
    }));
    const { payload, success, error } = await getStoreFloors(storeId);
    if (!success || error) {
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, floors: false }
      }));
      return;
    }
    const { floors } = payload;
    setOptions((prev) => ({ ...prev, floors }));
    setForm((prev) => ({ ...prev, floor: floors[0] }));
    setState((prev) => ({
      ...prev,
      loading: { ...prev.loading, floors: false }
    }));
    setFloorActiveIndex(0);
  }

  async function getAps(storeId, floorId, { from, to }) {
    setState((prev) => ({ ...prev, loading: { ...prev.loading, aps: true } }));
    if (from && to === null) {
      to = new Date(from);
      to.setHours(23, 59, 59, 999);
    }
    const { success, payload, error } = await getStoreHeatmap(
      storeId,
      floorId,
      formatDatetime(from),
      formatDatetime(to)
    );
    if (!success || error) {
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, aps: false }
      }));
      return;
    }
    const { heatmap = [], stats = [], prominentDevice, apRankingAsc = [], apRankingsDesc = []} = payload;
    setState((prev) => ({
      ...prev,
      aps: heatmap,
      stats: {...stats, device: prominentDevice?.device_os, apRankingAsc, apRankingsDesc},
      loading: { ...prev.loading, aps: false }
    }));
  }

  async function getApsDuration(storeId, { from, to }) {
    const { error, success, payload } = await getStoreHeatmapDuration(
      storeId,
      formatDatetime(from),
      formatDatetime(to)
    );
    if (error || !success) {
      return;
    }
    setState((prev) => ({
      ...prev,
      aps: prev.aps.map((ap) => ({
        ...ap,
        avgTime:
          payload?.rows.find((row) => row.ap_mac === ap.macAddress)?.avg_time ??
          0
      }))
    }));
  }

  function handleChange(event) {
    const {
      value,
      target: { name }
    } = event;
    if (value) {
      setForm((prev) => ({ ...prev, [name]: value }));
    }
    return value;
  }

  function handleStoreChange(event) {
    const store = handleChange(event);
    if (!store) {
      return;
    }
    getFloors(store.ID);
  }

  const apsWithStyles = (() => {
    const compareProperties = {
      0: { compareBy: "connections", zeroValue: 0 },
      1: { compareBy: "avgTime", zeroValue: 0 },
      2: { compareBy: "connections", zeroValue: 0 }
    };

    if (!compareProperties[state.index]) {
      return [];
    }

    const { compareBy, zeroValue } = compareProperties[state.index];

    const max = state.aps.reduce((max, ap) => Math.max(max, ap[compareBy]), 0);
    const apsWithColors = state.aps.map((ap) => {
      if (ap[compareBy] === zeroValue) {
        return {
          ...ap,
          border: "#97979740",
          background: "#97979740"
        };
      }
      const colorValue = (ap[compareBy] / max) * (apColors.length - 1);
      const colorIndex = Math.round(colorValue);
      return {
        ...ap,
        border: apColors[colorIndex]?.border,
        background: apColors[colorIndex]?.background
      };
    });
    return apsWithColors;
  })();
  const sortedAps = apsWithStyles.toSorted((a, b) => b.coordX - a.coordX);

  async function getTrajectory(storeId, floorId, { from, to }) {
    const { success, error, payload } = await getStoreTrajectory(
      storeId,
      floorId,
      formatDatetime(from),
      formatDatetime(to)
    );
    if (error || !success) {
      return;
    }
    const { rows } = payload;
    setState((prev) => ({
      ...prev,
      trajectory: rows[0]?.ruta.split(",") ?? []
    }));
  }

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.lineWidth = 4;
    ctx.strokeStyle = "#ff0000";
    ctx.lineCap = "square";

    ctx.beginPath();

    if (state.trajectory.length === 0) {
      return;
    }

    const firstAp =
      sortedAps.find(
        (ap) =>
          ap.macAddress?.toUpperCase() === state.trajectory[0]?.toUpperCase()
      ) ??
      sortedAps.find(
        (ap) =>
          ap.macAddress?.toUpperCase() === state.trajectory[1]?.toUpperCase()
      ) ??
      sortedAps.find(
        (ap) =>
          ap.macAddress?.toUpperCase() === state.trajectory[2]?.toUpperCase()
      );

    ctx.moveTo(
      (firstAp?.coordX * state.image.clientWidth) / state.image.naturalWidth,
      (firstAp?.coordY * state.image.clientHeight) / state.image.naturalHeight
    );

    let i = 1;
    ctx.font = "1rem Open Sans";
    for (const mac of state.trajectory) {
      const ap = sortedAps.find((ap) => ap.macAddress === mac);
      ctx.lineTo(
        (ap?.coordX * state.image.clientWidth) / state.image.naturalWidth,
        (ap?.coordY * state.image.clientHeight) / state.image.naturalHeight
      );
      // ctx.fillText(i++, (ap?.coordX * state.image.clientWidth) + 20 / state.image.naturalWidth, (ap?.coordY * state.image.clientHeight) / state.image.naturalHeight + 20);
    }

    ctx.stroke();
  }, [state.trajectory]);

  if (state.loading.stores) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <InternalSpinner />
      </div>
    );
  }


  /**
   * @param {number} num
   * @returns {number}
   */
  function secsToRawMins(num) {
    const date = moment.duration(num, 'seconds');
    return ~~date.asMinutes();
  }

  /**
   * @param {number} num
   * @returns {string}
   */
  function numToMinsAndSecs(num) {
    const date = moment.duration(num, 'seconds');
    const [minutes, seconds] = [date.minutes(), date.seconds()];
    const hours = ~~date.subtract(minutes, 'minutes').subtract(seconds, 'seconds').asHours();
    const hoursStr = hours > 0 ? ( hours < 10 ? `0${hours}:` : `${hours}:`) : "";
    const timeSuffix = hours > 0 ? "hrs" : "min";
    return ` ${hoursStr}${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds} ${timeSuffix}`;
  }

  const maxVisitorDuration = numToMinsAndSecs(state.stats.max_duration ?? 0);
  const minVisitorDuration = numToMinsAndSecs(state.stats.min_duration ?? 0);
  const avgPerUser = !!state.stats.total_duration && !!state.stats.total_contacts ? numToMinsAndSecs(state.stats.total_duration / state.stats.total_contacts) : "00:00 min";

  const compareProperties = {
    0: { compareBy: "connections", zeroValue: 0 },
    1: { compareBy: "avgTime", zeroValue: 0 },
    2: { compareBy: "connections", zeroValue: 0 }
  };

  const compareBy = compareProperties[state.index].compareBy;

  
  const nonZeroUsersAps = state.aps.filter((ap) => ap[compareBy] !== 0);
  const leastUsersAp = nonZeroUsersAps.reduce((max, ap) => Math.min(max, ap[compareBy]), nonZeroUsersAps?.[0]?.[compareBy] ?? 0);
  const mostUsersAp = state.aps.reduce((max, ap) => Math.max(max, ap[compareBy]), 0);

  return (
    <div style={{ marginLeft: "0.5rem" }}>
      <div style={{ height: "1rem" }} />
      <div style={{ display: "flex", gap: 10 }}>
        <div style={{ width: 280 }}>
          <NeoDropdown
            style={{ width: 280 }}
            label="Sucursal"
            value={form.store}
            onChange={handleStoreChange}
            options={options.stores}
            optionLabel="NAME"
            name="store"
          />
        </div>
        <div style={{ width: 380 }}>
          <NeoCalendar
            style={{ maxWidth: 340 }}
            label="Rango de fechas"
            value={[form.from, form.to]}
            onChange={(e) => {
              const [startDate, endDate] = e.value;
              setForm((prev) => ({
                ...prev,
                from: startDate,
                to: endDate
              }));
            }}
            selectionMode="range"
            readOnlyInput
            optionLabel="NAME"
            showTime
          />
        </div>
      </div>

      <div style={{ height: "1rem" }} />

      <NeoCard>
        {!form.floor && (
          <p
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "0"
            }}
          >
            Selecciona una sucursal
          </p>
        )}

        {!form.floor ? null : (
          <>
            <div
              className="top-map-area"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: 32,
                marginBottom: 14
              }}
            >
              <div style={{ width: "1%", "flex-grow": "1" }}>
                <NeoTabView
                  panelContainerStyle={{ backgroundColor: "red" }}
                  onTabChange={(e) => {
                    setForm((prev) => ({
                      ...prev,
                      floor: options.floors[e.index]
                    }));

                    setFloorActiveIndex(e.index);
                  }}
                  activeIndex={floorActiveIndex}
                  scrollable={options.floors.length > 6}
                >
                  {options.floors.map((floor) => {
                    return (
                      <NeoTabPanel
                        unstyled
                        key={floor.ID}
                        header={floor.NAME}
                        headerStyle={{ margin: 0, padding: 0 }}
                        style={{ margin: 0, padding: 0 }}
                      ></NeoTabPanel>
                    );
                  })}
                </NeoTabView>
              </div>

              <NeoSelectButton
                style={{
                  maxHeight: "39px"
                }}
                value={
                  state.index === 0
                    ? "Conexiones"
                    : state.index === 1
                    ? "Duración"
                    : "Trayectoria"
                }
                onChange={(e) => {
                  switch (e.target.value) {
                    case "Conexiones":
                      setState((prev) => ({ ...prev, index: 0 }));
                      break;
                    case "Duración":
                      setState((prev) => ({ ...prev, index: 1 }));
                      break;
                    case "Trayectoria":
                      setState((prev) => ({ ...prev, index: 2 }));
                      break;
                    default:
                      break;
                  }
                }}
                options={[
                  "Conexiones", 
                  "Duración", 
                  // "Trayectoria"
                ]}
              />
            </div>

            <div style={{ display: "flex", gap: 14 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "1%",
                  flexGrow: 1
                }}
              >
              {state.index === 1 &&
                <NeoMessage
                  style={{
                    color: "#007EB4",
                    width: "100%",
                    justifyContent: "start",
                    fontSize: 14
                  }}
                  custom="map-message"
                  text="Solo se toma en cuenta la duración de las conexiones de contactos registrados"
                />}
                <div
                  className="heatmap-container p-col p-col-12"
                  style={{ minHeight: "31rem" }}
                >
                  <ComponentFloorPlan floor={form.floor} setState={setState} />

                  {!state.loading.image && (
                    <canvas
                      ref={canvasRef}
                      height={state.image.clientHeight}
                      width={state.image.clientWidth}
                      style={{
                        position: "absolute",
                        top: 0
                      }}
                    />
                  )}
                  {!state.loading.image && (
                    <div className="heatmap-heat-points">
                      {state.index === 0 &&
                        sortedAps.map((ap) => (
                          <StoreHeatmapTab
                            key={ap.id}
                            background={ap.background}
                            border={`thin solid ${ap.border}`}
                            coordX={ap.coordX}
                            coordY={ap.coordY}
                            label={ap.label}
                            image={state.image}
                            department={ap.department}
                          >
                            {ap.connections} usuario
                            {ap.connections === 1 ? "" : "s"}
                          </StoreHeatmapTab>
                        ))}

                      {state.index === 1 &&
                        sortedAps.map((ap) => (
                          <StoreHeatmapTab
                            key={ap.id}
                            background={ap.background}
                            border={ap.border}
                            coordX={ap.coordX}
                            coordY={ap.coordY}
                            label={ap.label}
                            image={state.image}
                            department={ap.department}
                          >
                            {(() => {
                              const totalMinutes = Math.floor(
                                (ap.avgTime ?? 0) / 60
                              );

                              const seconds = Math.floor(
                                (ap.avgTime ?? 0) % 60
                              );
                              const hours = Math.floor(totalMinutes / 60);
                              const minutes = totalMinutes % 60;

                              const hoursFormatted = zeroPad(hours, 2);
                              const minutesFormatted = zeroPad(minutes, 2);
                              const secondsFormatted = zeroPad(seconds, 2);

                              return `${hoursFormatted}:${minutesFormatted}:${secondsFormatted}`;
                            })()}
                          </StoreHeatmapTab>
                        ))}

                      {state.index === 2 &&
                        sortedAps.map((ap) => (
                          <StoreHeatmapTab
                            key={ap.id}
                            background={ap.background}
                            border={ap.border}
                            coordX={ap.coordX}
                            coordY={ap.coordY}
                            label={ap.label}
                            image={state.image}
                            department={ap.department}
                          >
                            {ap.connections} usuario
                            {ap.connections === 1 ? "" : "s"}
                          </StoreHeatmapTab>
                        ))}
                    </div>
                  )}
                </div>
              </div>

              <div
                style={{
                  width: "266px",
                  display: "flex",
                  flexDirection: "column",
                  gap: 14,
                  paddingTop: 8
                }}
              >
                <h1
                  style={{
                    "font-family": "Nunito",
                    "font-size": "20px",
                    "font-weight": "700",
                    "line-height": "27px",
                    "letter-spacing": "0em",
                    "text-align": "left",
                    color: "#194893"
                  }}
                >
                  Permanencia en el piso
                </h1>

                <div
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <p style={{ fontSize: 14, margin: 0 }}>
                    Contactos únicos conectados
                  </p>
                  <p style={{ fontWeight: 700, margin: 0 }}>
                    {state.stats.total_contacts ?? 0}
                  </p>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <p style={{ fontSize: 14, margin: 0 }}>
                    Promedio por contacto
                  </p>
                  <p style={{ fontWeight: 700, margin: 0 }}>
                  {avgPerUser}
                  </p>
                </div>
              
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 4 }}
                >
                  <p style={{ fontSize: 14, margin: 0 }}>
                    Dispositivo con más permanencia
                  </p>
                  <p style={{ fontWeight: 700, margin: 0 }}>{state.stats.device ?? "---"}</p>
                </div>
                {/* <h1
                  style={{
                    "font-family": "Nunito",
                    "font-size": "20px",
                    "font-weight": "700",
                    "line-height": "27px",
                    "letter-spacing": "0em",
                    "text-align": "left",
                    color: "#194893"
                  }}
                >
                  Ranking de APs
                </h1>
                <NeoTable
                  onSort={changeApRankingOrder}
                  emptyMessage={"No hay resultados, intenta aplicando otros filtros"}
                  sortField="dailyAvg"
                  sortOrder={apRankingDescOrder ? -1 : 1}
                  value={
                    (apRankingDescOrder ? state.stats?.apRankingsDesc : state.stats?.apRankingAsc)?.map((ap, index) => ({
                      ap: ap.row_num + ". " + ap.name,
                      dailyAvg: secsToRawMins(ap.avg)
                    })) ?? []
                  }
                  custom="ap-ranking-table"
                >
                  <NeoColumn field="ap" header="AP" style={{ width: "60%" }} />
                  <NeoColumn
                    sortable
                    field="dailyAvg"
                    header="Promedio diario"
                    style={{ width: "40%" }}
                  />
                </NeoTable> */}
              </div>
            </div>

            <div className="heatmap-bar-container">
              <div className="heatmap-bar" />
              <div className="heatmap-bar__symbols">
                <p>{leastUsersAp}</p>
                <p>{mostUsersAp}</p>
              </div>
            </div>
          </>
        )}
      </NeoCard>
    </div>
  );
}
