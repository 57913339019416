import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoSpinner from "../../design/design_components/neo/overlay/NeoSpinner.base";
import Icono from "../../design/assets/img/wfi/icons/icon-multimedia.svg";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";
import * as MediaService from "../../service/Media.service";
import MediaStatisticsStoreTable from "./components/MediaTableStatisticsStore.component";
import useApp from "../../hooks/useApp.hook";
import useComponent from "../../hooks/useComponent.hook";

export default function MediaStatisticsStore() {
  const app = useApp();
  const { mediaId, fileTypeId, mediaName, storeId, storeName } = app.query.params;
  const component = useComponent();
  const [visitsData, setVisitsData] = component.useState({
    VISITIS: []
  });

  component.useEffect(async () => await initialize(), []);

  const initialize = async () => {
    await Promise.all(
      getStatisticsStore()
    );
  }

  const getStatisticsStore = component.useAsync(async () => {
    const response = await MediaService.getStatisticsStore(mediaId, fileTypeId, storeId);
    if(response.success) {
      setVisitsData(response.payload);
    }
  });

  return (
    <>
      {component.isLoading ? <NeoSpinner /> : null}
      <NeoGridContainer>
        <NeoTitleSecond
          title={`Detalle de anuncio en ${storeName}`}
          subtitle={mediaName}
          col="12"
          icon={Icono}
          goback={"True"}
        />
      </NeoGridContainer>

      <MediaStatisticsStoreTable
        fileTypeId={fileTypeId}
        value={visitsData}
      />
    </>
  );
}
