import React, { useState, useRef, useEffect } from "react";
import { TYPE_VALUES } from "../../../consts/emails";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import {
  getEmailTemplates,
  updateTemplate,
} from "../../../service/Mail.service";
import useToastContext from "../../../hooks/useToastContext.hook";
import ConfigurationModal from "./ConfigurationModal.component";
import ConfirmationModal from "./ConfirmationModal.component";
import ParametersModal from "./ParametersModal.component";
import { CONST } from "../../../consts/consts";
import NeoTooltip from "../../../design/design_components/neo/overlay/NeoTooltip.base";
import NeoCellDataStatus from "../../../design/design_components/neo/data/NeoCellDataStatus.base";

export default function EmailsTemplateTable() {
  const [emailsTemplate, setEmailsTemplate] = useState([]);
  const [loading, setLoading] = useState(false);
  const emailTemplateRef = useRef();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [configurationModalVisibility, setConfigurationModalVisibility] =
    useState(false);
  const [confirmationVisibility, setConfirmationVisibility] = useState(false);
  const [parametersModalVisibility, setParametersModalVisibility] =
    useState(false);
  const [changesToConfirm, setChangesToConfirm] = useState("");
  const [valueNumber, setValueNumber] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [modalHeader, setModalHeader] = useState(null);
  const [emailDescription, setEmailDescription] = useState(null);
  const toast = useToastContext();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      setLoading(true);
      setEmailTemplatesByUser();
    }

    return () => {
      mounted = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const setEmailTemplatesByUser = async () => {
    let resultEmailTemplate;
    resultEmailTemplate = await getEmailTemplates();

    if (resultEmailTemplate.success) {
      setEmailsTemplate(resultEmailTemplate.result);
    } else {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    }
    setLoading(false);
  };

  const determineTypeValue = (template) => {
    if (template?.VALUE) {
      setTypeValue(TYPE_VALUES.frequency);
    } else if (template?.WEEK_PERIOD) {
      setTypeValue(TYPE_VALUES.period);
    } else if (template?.REVIEW_PERIOD) {
      setTypeValue(TYPE_VALUES.review);
    } else {
      return null;
    }
  };

  const changesState = (updatedField, newValue, ID) => {
    let updatedData = emailsTemplate.map((item) => {
      if (item.ID === ID) {
        let newItem = { ...item };
        newItem[updatedField] = newValue;
        return newItem;
      } else {
        return { ...item };
      }
    });
    console.log(updatedData);
    setEmailsTemplate(updatedData);
  };

  const handleSubmit = async () => {
    let result;
    if (!typeValue && !valueNumber) {
      result = await updateTemplate({
        ...selectedTemplate,
        ACTIVE: !selectedTemplate.ACTIVE,
      });
      changesState("ACTIVE", !selectedTemplate.ACTIVE, selectedTemplate.ID);
    } else if (typeValue === TYPE_VALUES.frequency && valueNumber) {
      result = await updateTemplate({
        ...selectedTemplate,
        VALUE: valueNumber,
      });
      changesState("VALUE", valueNumber, selectedTemplate.ID);
    } else if (typeValue === TYPE_VALUES.period && valueNumber) {
      result = await updateTemplate({
        ...selectedTemplate,
        WEEK_PERIOD: valueNumber,
      });
      changesState("WEEK_PERIOD", valueNumber, selectedTemplate.ID);
    } else if (typeValue === TYPE_VALUES.review && valueNumber) {
      result = await updateTemplate({
        ...selectedTemplate,
        REVIEW_PERIOD: valueNumber,
      });
      changesState("REVIEW_PERIOD", valueNumber, selectedTemplate.ID);
    } else {
      result.success = false;
    }
    if (result.success) {
      toast.setMessage(
        CONST.SEVERITY.SUCCESS,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
      );
    } else {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    }
    setValueNumber(null);
    setTypeValue(null);
  };
  const activeBodyColumn = (status) => {
    return (
      <NeoCellDataStatus
        status={status ? "on" : "off"}
        circle={true}
        label={status ? "Activo" : "Inactivo"}
      />
    );
  };

  const configurationBody = (data) => {
    if (data.CONFIGURABLE) {
      if (data?.VALUE) {
        return (
          <span>
            {data.VALUE} {singular("visita", data.VALUE)} por mes
          </span>
        );
      } else if (data?.WEEK_PERIOD) {
        return (
          <span>
            {data.WEEK_PERIOD} {singular("semana", data.WEEK_PERIOD)} sin
            conectarse
          </span>
        );
      } else if (data?.REVIEW_PERIOD) {
        return (
          <span>
            {data.REVIEW_PERIOD} {singular("hora", data.REVIEW_PERIOD)} después
            de la visita
          </span>
        );
      } else {
        return <span>Sin configuración</span>;
      }
    }
    return "N/A";
  };

  const singular = (word, variable) => {
    if (variable) return variable === 1 ? `${word}` : `${word}s`;
  };

  const nameBodyColumn = (emailName) => {
    const TOOLTIP_DELAY = 350;
    const POSITION = "bottom";
    switch (emailName) {
      case "Correo de bienvenida":
        return (
          <>
            <NeoTooltip
              target=".correoBienvenida"
              showDelay={TOOLTIP_DELAY}
              position={POSITION}
            >
              <>
                Este correo se enviará a todos los{" "}
                <strong>nuevos clientes</strong> que se conecten a tu red de
                wifi
              </>
            </NeoTooltip>
            {/* <>
                  <NeoTooltip target=".emailValidation" data-pr-showdelay={1000} />
                  Validación del correo
                  <i
                    className="pi pi-question-circle emailValidation"
                    data-pr-tooltip={emailValidationQuestionMark()}
                  ></i>
                </> */}
            {emailName} {"  "}
            <i className="pi pi-question-circle correoBienvenida" />
          </>
        );
      case "Correo de cliente frecuente":
        return (
          <>
            <NeoTooltip
              target=".correoFrecuente"
              showDelay={TOOLTIP_DELAY}
              position={POSITION}
            >
              <>
                Este correo se enviará a todos los clientes que se hayan
                conectado una cierta cantidad de veces durante el mes en curso
              </>
            </NeoTooltip>
            {emailName} <i className="pi pi-question-circle correoFrecuente" />
          </>
        );
      case "Correo de cliente perdido":
        return (
          <>
            <NeoTooltip
              target=".correoClientePerdido"
              showDelay={TOOLTIP_DELAY}
              position={POSITION}
            >
              <>
                Este correo se enviará a todos los clientes que no se hayan
                vuelto a conectar en una cantidad de semanas determinda
              </>
            </NeoTooltip>
            {emailName}{" "}
            <i className="pi pi-question-circle correoClientePerdido" />
          </>
        );
      case "Correo de cumpleaños":
        return (
          <>
            <NeoTooltip
              target=".correoBirthday"
              showDelay={TOOLTIP_DELAY}
              position={POSITION}
            >
              <>
                Este correo se enviará a todos los clientes en el día de su
                cumpleaños. Es necesario solicitar está información en tus
                splash pages.
              </>
            </NeoTooltip>
            {emailName} <i className="pi pi-question-circle correoBirthday" />
          </>
        );
      case "Correo de encuesta":
        return (
          <>
            <NeoTooltip
              target=".correoEncuesta"
              showDelay={TOOLTIP_DELAY}
              position={POSITION}
            >
              <>
                Este correo se enviará a todos los clientes después de un tiempo
                determinado de su visita
              </>
            </NeoTooltip>
            {emailName} <i className="pi pi-question-circle correoEncuesta" />
          </>
        );
        default:
        return (
          <>
            <NeoTooltip
              target=".otrosCorreos"
              showDelay={TOOLTIP_DELAY}
              position={POSITION}
            >
              <>
                Notificación personalizada
              </>
            </NeoTooltip>
            {emailName} <i className="pi pi-question-circle otrosCorreos" />
          </>
        );
    }
  };

  const handleConfiguration = (rowData) => {
    setSelectedTemplate(rowData);
    emailDescriptionSelector(rowData.NAME);
    setModalHeader(rowData.NAME);
    setConfigurationModalVisibility(true);
  };

  const emailDescriptionSelector = (emailName) => {
    switch (emailName) {
      case "Correo de bienvenida":
        return setEmailDescription(
          <>
            Este correo se enviará a todos los <strong>nuevos clientes</strong>{" "}
            que se conecten a tu red de wifi
          </>
        );
      case "Correo de cliente frecuente":
        return setEmailDescription(
          "Este correo se enviará a todos los clientes que se hayan conectado una cierta cantidad de veces durante el mes en curso"
        );
      case "Correo de cliente perdido":
        return setEmailDescription(
          "Este correo se enviará a todos los clientes que no se hayan vuelto a conectar en una cantidad de semanas determinda"
        );
      case "Correo de cumpleaños":
        return setEmailDescription(
          "Este correo se enviará a todos los clientes en el día de su cumpleaños. Es necesario solicitar está información en tus splash pages."
        );
      case "Correo de encuesta":
        return setEmailDescription(
          "Este correo se enviará a todos los clientes después de un tiempo determinado de su visita"
        );
      default:
        break;
    }
  };

  return (
    <>
      <NeoTable
        ref={emailTemplateRef}
        value={emailsTemplate}
        loading={loading}
        selectionMode="single"
        dataKey="ID"
        onRowSelect={(e) => handleConfiguration(e.data)}
        extra={"no-filters"}
        rowClassName={(e) => {
          return {
            "datatable-row-disabled": !e.OPERATING,
          };
        }}
      >
        <NeoTableColumn
          field="NAME"
          header="Nombre de notificación"
          body={(e) => nameBodyColumn(e.NAME)}
        />
        <NeoTableColumn
          field="ACTIVE"
          header="Estatus"
          body={(e) => activeBodyColumn(e.ACTIVE)}
        />
        <NeoTableColumn
          header="Configuración de plantilla"
          body={(e) => configurationBody(e)}
        />
      </NeoTable>
      <ConfigurationModal
        template={selectedTemplate}
        visible={configurationModalVisibility}
        setVisible={setConfigurationModalVisibility}
        setDescription={setChangesToConfirm}
        setVisibleConfirmation={setConfirmationVisibility}
        setParametersVisible={setParametersModalVisibility}
        setValueNumber={setValueNumber}
        setTypeValue={setTypeValue}
        setHeader={setModalHeader}
        header={modalHeader}
        emailDescription={emailDescription}
      />
      <ParametersModal
        template={selectedTemplate}
        visible={parametersModalVisibility}
        setVisible={setParametersModalVisibility}
        setDescription={setChangesToConfirm}
        setVisibleConfirmation={setConfirmationVisibility}
        setConfigurationVisible={setConfigurationModalVisibility}
        setValueNumber={setValueNumber}
        valueNumber={valueNumber}
        determineTypeValue={determineTypeValue}
        typeValue={typeValue}
        setTypeValue={setTypeValue}
        setHeader={setModalHeader}
        header={modalHeader}
        submit={handleSubmit}
      />
      <ConfirmationModal
        changesText={changesToConfirm}
        visible={confirmationVisibility}
        setVisible={setConfirmationVisibility}
        setParametersVisible={setParametersModalVisibility}
        setConfigurationVisible={setConfigurationModalVisibility}
        template={selectedTemplate}
        description={changesToConfirm}
        valueNumber={valueNumber}
        setValueNumber={setValueNumber}
        submit={handleSubmit}
        setHeader={setModalHeader}
        header={modalHeader}
      />
    </>
  );
}
