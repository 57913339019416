import { useLooker } from "../../hooks/useLooker.hook";

const MarketingView = ({ from, to }) => {
  const { url } = useLooker("2")
   // Función para formatear las fechas
   const formatDate = (date) => {
    if (!date) return '';
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}`;
  };

  // Agregar las fechas como parámetros de la query en la URL
  const urlWithDates = `${url}?from=${formatDate(from)}&to=${formatDate(to)}`;
  console.log("URL WITH DATES:",urlWithDates);
  return (
    <iframe
          title="Dashbaord General"
          src={url}
          allowFullScreen
        />
  )
}

export default MarketingView;
