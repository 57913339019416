import { ELEMENTS_LIST_NAME } from "../consts/splashPages.consts";
import Submit from "../views/splashPages/components/splashPageElements/button/Submit/Submit.modal.component";
import Birthday from "../views/splashPages/components/splashPageElements/fields/Birthday/Birthday.modal.component";
import Email from "../views/splashPages/components/splashPageElements/fields/Email/Email.modal.component";
import FirstName from "../views/splashPages/components/splashPageElements/fields/FirstName/FirstName.modal.component";
import LastName from "../views/splashPages/components/splashPageElements/fields/LastName/LastName.modal.component";
import FullName from "../views/splashPages/components/splashPageElements/fields/Name/FullName.modal.component";
import Phone from "../views/splashPages/components/splashPageElements/fields/Phone/Phone.modal.component";
import Logo from "../views/splashPages/components/splashPageElements/others/Logo/Logo.modal.component";
import TermAndCondition from "../views/splashPages/components/splashPageElements/text/Link/TermsAndConditions.modal.component";
import NormalText from "../views/splashPages/components/splashPageElements/text/NormalText/NormalText.modal.component";
import SubtitleText from "../views/splashPages/components/splashPageElements/text/Subtitle/SubtitleText.modal.component";
import TitleText from "../views/splashPages/components/splashPageElements/text/Title/TitleText.modal.component";

const FactoryModal = (props) => {
  switch (props.element) {
    case ELEMENTS_LIST_NAME.EMAIL.label:
      return <Email {...props} />;

    case ELEMENTS_LIST_NAME.TITLE.label:
      return <TitleText {...props} />;

    case ELEMENTS_LIST_NAME.SUBTITLE.label:
      return <SubtitleText {...props} />;

    case ELEMENTS_LIST_NAME.TEXT.label:
      return <NormalText {...props} />;

    case ELEMENTS_LIST_NAME.SUBMIT.label:
      return <Submit {...props} />;

    case ELEMENTS_LIST_NAME.TERM.label:
      return <TermAndCondition {...props} />;

    case ELEMENTS_LIST_NAME.LOGO.label:
      return <Logo {...props} />;

    case ELEMENTS_LIST_NAME.PHONE.label:
      return <Phone {...props} />;

    case ELEMENTS_LIST_NAME.BIRTHDAY.label:
      return <Birthday {...props} />;

    case ELEMENTS_LIST_NAME.FULLNAME.label:
      return <FullName {...props} />;

    case ELEMENTS_LIST_NAME.FIRSTNAME.label:
      return <FirstName {...props} />;

    case ELEMENTS_LIST_NAME.LASTNAME.label:
      return <LastName {...props} />;

    default:
      return <></>;
  }
};

export { FactoryModal };
