import React, { useState } from 'react';
import NeoMultiSelect from '../form/NeoMultiSelect.base'

const NeoMultiSelectFilter = (props, ref) => {

  const [elementSelected, setElementSelected] = useState(null);
  const [customSelectedItemsLabel, setCustomSelectedItemsLabel] = useState(null);

  const onElementSelectedChange = (e) => {
    ref.current.filter(e.value, props.field, props.matchFilter ?? "in")
    setElementSelected(e.value)

    if (typeof props.selectedItemsLabel === "function") {
      console.log(typeof props.selectedItemsLabel === "string" ? props.selectedItemsLabel : customSelectedItemsLabel)
      const newSelectedItemsTemplate = props.selectedItemsLabel(
        e.value
      );
      setCustomSelectedItemsLabel(newSelectedItemsTemplate);
    }
  };

  const selectedItemTemplate = (selectedVal) => {
    if (props.selectedItemTemplate !== null && props.selectedItemTemplate !== undefined) {
      return props.selectedItemTemplate(selectedVal, elementSelected);
    }
  }
  
  return ( 
    <NeoMultiSelect value={elementSelected} options={props.options} 
      onChange={onElementSelectedChange} placeholder={props.placeholder} 
      selectedItemsLabel={typeof props.selectedItemsLabel === "string" ? props.selectedItemsLabel : customSelectedItemsLabel} 
      selectedItemTemplate={props.selectedItemTemplate ? selectedItemTemplate : null}
      itemTemplate={props.itemTemplate ?? ((option) => (
        <div className="p-multiselect-representative-option">
            <span className="image-text">{option.label}</span>
        </div>
      ))}
      className="p-column-filter"
    />
  )
}

export default React.forwardRef(NeoMultiSelectFilter);
