import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import { filterRangeNumber } from "../../../utils/commons.util";
import ROUTES from "../../../routers/Routes.router.json";
import useApp from "../../../hooks/useApp.hook";
import * as MEDIA_CONSTS from "../../../consts/media.consts.js";

export default function MediaStatisticsTable(_props) {
  const app = useApp();
  const [statistics, setStatistics] = useState([]);
  const mediaTableRef = useRef();

  useEffect(() => {
    setStatistics(_props.value);
  }, [_props.value])

  const nameFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={mediaTableRef}
        field={"NAME"}
        placeholder={"Buscar nombre de sucursal"}
        filterMatch={"contains"}
      />
    );
  };

  const renderedFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"TRACKER.RENDEREDS"}
        minPlaceholder={"Mín"}
        maxPlaceholder={"Máx"}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const clicksFilterElement = () => {
    return (
      <NeoRangeNumberFilter
        ref={mediaTableRef}
        field={"TRACKER.CLICKS"}
        minPlaceholder={"Mín"}
        maxPlaceholder={"Máx"}
        minDigit={0}
        maxDigit={0}
        matchFilter="custom"
      />
    );
  };

  const onSelectRow = (data) => {
    const {mediaId, fileTypeId, mediaName} = _props;
    const {ID: storeId, NAME: storeName} = data;

    app.redirect(ROUTES.media.statisticStore, {
      mediaId, fileTypeId, mediaName, storeId, storeName
    });
  };

  return (
    <>
      <NeoTable
        emptyMessage="Esta multimedia no generó visitas en ninguna sucursal"
        ref={mediaTableRef}
        value={statistics}
        removableSort
        selectionMode={"single"}
        onRowSelect={(e) => onSelectRow(e.data)}
        paginator
        rows={8}
      >
        <NeoTableColumn
          field="NAME"
          header="Sucursal"
          placeholder="Sucursal"
          sortable
          filter
          filterElement={nameFilterElement()}
          filterMatchMode="contains"
        />
        <NeoTableColumn
          field="TRACKER.RENDEREDS"
          header="Impresiones"
          sortable
          filter
          filterElement={renderedFilterElement()}
          filterFunction={filterRangeNumber}
          filterMatchMode="custom"
        />
        {Number(_props.fileTypeId) !== MEDIA_CONSTS.TYPE.VIDEO.ID &&
          <NeoTableColumn
            field="TRACKER.CLICKS"
            header="Clics"
            sortable
            filter
            filterElement={clicksFilterElement()}
            filterFunction={filterRangeNumber}
            filterMatchMode="custom"
          />
        }
      </NeoTable>
    </>
  );
}
