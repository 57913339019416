import React, { useState, useEffect } from "react";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import useModalBlock from "../../../hooks/useModalBlock.hook";

export default function PrivacyTermsDialog(props) {
  const [urlCurrent, setUrlCurrent] = useState()

  useEffect(() => {
    if(props.visibility){
      setUrlCurrent(props.urlLink)
    }
  }, [props.visibility])
  
  const handleAcept = ()=>{
    props.setUrlLink(urlCurrent)
    props.setVisibility(false)
  }

  // eslint-disable-next-line
  const [, , footerModal] = useModalBlock(
    handleAcept,
    () => props.setVisibility(false),
    props.setVisibility, null, true
  );

  return (
    <>
      <NeoDialog
        header={"Cambio de link de términos y condiciones"}
        visible={props.visibility}
        closable={false}
        footer={footerModal}
      >
        <NeoInputText
          label={"URL de términos y condiciones"}
          name={"TermsAndConditions"}
          value={urlCurrent}
          onChange={(e) => setUrlCurrent(e.target.value)}
        />
      </NeoDialog>
    </>
  );
}
