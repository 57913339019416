import { useState, useEffect } from "react";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import { addressDataFormatting } from "../../../utils/commons.util";
import { getUserApBilled } from "../../../service/User.service";
import { CONST } from "../../../consts/consts";
import useToastContext from "../../../hooks/useToastContext.hook";
import { useHistory } from "react-router-dom";
import { postStores } from "../../../service/Store.service";
import NewAddress from "./NewAddress.component";
import EditAddress from "./EditAddress.component"
import NeoDataMain from "../../../design/design_components/neo/data/NeoDataMain.base";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";

const StoresConfigTable = () => {
  const toast = useToastContext();
  const history = useHistory();
  const [apsNumber, setApsNumber] = useState(0);
  const [apsNumberTotalAdded, setApsNumberTotalAdded] = useState(0);
  const [modalAddAddressVisibility, setModalAddAddressVisibility] =
    useState(false);
  const [modalEditAddressVisibility, setModalEditAddressVisibility] =
    useState(false);
  const [addressCurrent, setAddressCurrent] = useState(null)
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let mount = true;

    if (mount) {
      getApsShipped();
    }

    return () => {
      mount = false;
    };
  }, []);

  useEffect(() => {
    setApsNumberTotalAdded(tableData.length);
  }, [tableData]);

  const getApsShipped = async () => {
    let apCount;
    apCount = await getUserApBilled();

    if (!apCount.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      setApsNumber(apCount.result);
    }
  };

  const handleSubmit = async () => {
    let storesToSubmit = tableData.map((store) => {
      return {
        address: {
          zipCode: store.address.postalCode,
          state: store.address.state,
          city: store.address.city,
          streetLine1: store.address.street,
          streetLine2: store.address.colony,
          internalNumber: store.address.internalNumber,
          references: store.references,
          receiverName: store.receiverName,
        },
        storeData: {
          storeName: store.storeName,
        },
      };
    });

    let storesSubmit = await postStores({ stores: storesToSubmit });

    if (!storesSubmit.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      history.push("/compra-finalizada");
    }
  };

  const onRowSelect = (event) => {
    setAddressCurrent(event.data.position)
    setModalEditAddressVisibility(true)
  }

  const generateBodyTemplate = (value, columnTitle) => {
    return (
      <>
        <span className="p-column-title">{columnTitle}</span>
        {value}
      </>
    );
  };

  const addressBodyTemplate = (value, columnTitle) => {
    return (
      <>
        <span className="p-column-title">{columnTitle}</span>
        {addressDataFormatting(value)}
      </>
    );
  };

  const missingApsToAdd = apsNumber - apsNumberTotalAdded;
  return (
    <>
      <p>
        Compártenos la dirección de las sucursales a las cuales enviaremos los Access Points.
      </p>
      <NeoTable
        value={tableData}
        emptyMessage="No has agregado ninguna sucursal para envío"
        extra="no-filters"

        footer={
          missingApsToAdd
            ? <NeoDataMain extra="text-align-center"
              fact={`${apsNumberTotalAdded} / ${apsNumber}`}
              label="Equipos con dirección configurada"
            />
            : <NeoDataMain extra="text-align-center"
              fact={`${apsNumberTotalAdded} / ${apsNumber}`}
              label="Todos los equipos tienen una dirección de envío"
            />

        }
        selectionMode="single"
        onRowSelect={onRowSelect}
      >
        <NeoTableColumn
          className="text-align-left"
          field="address"
          header="Dirección"
          body={(e) => addressBodyTemplate(e.address, "Dirección Calle y numero")}
        />
        <NeoTableColumn
          field="references"
          header="Referencias"
          body={(e) => generateBodyTemplate(e.references, "Referencias")}
        />
        <NeoTableColumn
          field="receiverName"
          header="Destinatario"
          body={(e) =>
            generateBodyTemplate(e.receiverName, "Nombre del destinatario")
          }
        />
        <NeoTableColumn
          field="apsCounterToSend"
          header="Cantidad de APs"
          body={(e) =>
            generateBodyTemplate(
              e.apsCounterToSend,
              "Cantidad de APs a enviar a esta direccion"
            )
          }
        />
      </NeoTable>

      <NeoButtonSection align="right">
        <NeoButtonMain
          label="Agregar nueva sucursal"
          disabled={apsNumberTotalAdded < apsNumber ? false : true}
          onClick={() => setModalAddAddressVisibility(true)}
        />
        <NeoButtonMain
          label="Guardar"
          disabled={apsNumberTotalAdded < apsNumber}
          onClick={handleSubmit}
        />
      </NeoButtonSection>

      <NewAddress
        modalAddAddressVisibility={modalAddAddressVisibility}
        setModalAddAddressVisibility={setModalAddAddressVisibility}
        setTableData={setTableData}
        tableData={tableData} />

      <EditAddress
        modalEditAddressVisibility={modalEditAddressVisibility}
        setModalEditAddressVisibility={setModalEditAddressVisibility}
        tableData={tableData}
        addressCurrent={addressCurrent}
      />
    </>
  );
};

export default StoresConfigTable;
