export const cache = {
  KEY: "Cache",
  get: (field) => {
    const appCache = localStorage.getItem(cache.KEY);
    if (!appCache) {
      return undefined;
    } else {
      const data = JSON.parse(appCache);
      return data[field];
    }
  },
  set: (field, data) => {
    const appCache = localStorage.getItem(cache.KEY);
    if (!appCache) {
      const cached = {};
      cached[field] = data;
      localStorage.setItem(cache.KEY, JSON.stringify(cached));
    } else {
      const cached = JSON.parse(appCache);
      cached[field] = data;
      localStorage.setItem(cache.KEY, JSON.stringify(cached));
    }
  },
  clear: (field) => {
    const appCache = localStorage.getItem(cache.KEY);
    if (appCache) {
      const cached = JSON.parse(appCache);
      delete cached[field];
      localStorage.setItem(cache.KEY, JSON.stringify(cached));
    }
  },
};

export const generarID = () => {
  const ID_LENGTH = 36;
  const START_LETTERS_ASCII = 97;
  const ALPHABET_LENGTH = 26;

  return [...new Array(ID_LENGTH)]
    .map(() =>
      String.fromCharCode(START_LETTERS_ASCII + Math.random() * ALPHABET_LENGTH)
    )
    .join("");
};

export const actualUrl = (route = "privateToken") => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const pathname =
    window.location.pathname !== "/" ? window.location.pathname : "";

  return `${protocol}//${host}/${route}`;
};
