import React, { useState, useEffect } from 'react'
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoInputTextDisabled from "../../../design/design_components/neo/form/NeoInputText_disabled.base";
import NeoDropdown from "../../../design/design_components/neo/form/NeoDropdown.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoInputTextarea from "../../../design/design_components/neo/form/NeoInputTextarea.base";
import NeoButtonMain from '../../../design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from '../../../design/design_components/neo/button/NeoButtonOutlined.base';
import { getPostalCodeInfo } from "../../../service/Deals.service";
import { mapTheInfoCPForDropdown } from "../../../utils/zipCode.utils";
import { validateExistence } from '../../../utils/commons.util';
import NeoInnerSubtitle from '../../../design/design_components/neo/title/NeoInnerSubtitle.base';

export default function EditAddress({
  modalEditAddressVisibility,
  setModalEditAddressVisibility,
  tableData,
  addressCurrent
}) {
  const [dataDropdown, setDataDropdown] = useState([]);
  const [errorRequestPostalCode, setErrorRequestPostalCode] = useState(false);
  const [loadingButtonValidatePostalCode, setLoadingButtonValidatePostalCode] =
    useState(false);
  const [storeName, setStoreName] = useState('')
  const [receiverName, setReceiverName] = useState('')
  const [street, setStreet] = useState('')
  const [internalNumber, setInternalNumber] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [state, setState] = useState('');
  const [city, setCity] = useState('')
  const [colony, setColony] = useState('')
  const [references, setReferences] = useState('')
  const [apsCounterToSend, setApsCounterToSend] = useState('1')

  useEffect(() => {
    if (tableData.length > 0) {
      setStoreName(tableData[addressCurrent].storeName)
      setReceiverName(tableData[addressCurrent].receiverName)
      setStreet(tableData[addressCurrent].address.street)
      setInternalNumber(tableData[addressCurrent].address.internalNumber)
      setPostalCode(tableData[addressCurrent].address.postalCode)
      setState(tableData[addressCurrent].address.state)
      setCity(tableData[addressCurrent].address.city)
      setColony(tableData[addressCurrent].address.colony)
      setReferences(tableData[addressCurrent].references)
      setApsCounterToSend(tableData[addressCurrent].apsCounterToSend)
      dataLoad(tableData[addressCurrent].address.postalCode)
    }
  }, [modalEditAddressVisibility])

  const dataLoad = async (postalCode) => {
    setLoadingButtonValidatePostalCode(true);
    let response;
    let codePostalInfo;
    try {
      response = await getPostalCodeInfo(postalCode);
      codePostalInfo = response.result;
    } catch (error) {
    }
    if (!codePostalInfo) {
      setErrorRequestPostalCode(true);
      setLoadingButtonValidatePostalCode(false);
      return;
    }
    setErrorRequestPostalCode(false);

    //codePostalInfo.asentamiento.push(tableData[addressCurrent].address.colony) //<--only when in development testing
    const codePostalInfoMaped = mapTheInfoCPForDropdown(
      codePostalInfo.asentamiento
    );
    setDataDropdown(codePostalInfoMaped);
    setLoadingButtonValidatePostalCode(false);
    return codePostalInfo;
  }

  const validateStateForm = () => {
    let isValid = true;
    isValid = isValid && validateExistence(storeName).itIsValid;
    isValid = isValid && validateExistence(receiverName).itIsValid;
    isValid = isValid && validateExistence(street).itIsValid;
    isValid = isValid && validateExistence(postalCode).itIsValid;
    isValid = isValid && validateExistence(state).itIsValid;
    isValid = isValid && validateExistence(city).itIsValid;
    isValid = isValid && validateExistence(colony).itIsValid;
    return !isValid;
  }

  const resetStates = () => {
    setStoreName('')
    setReceiverName('')
    setStreet('')
    setInternalNumber('')
    setPostalCode('')
    setState('')
    setCity('')
    setColony('')
    setReferences('')
    setApsCounterToSend('')
    dataLoad('')
  }

  const handleClickValidatePostalCode = async (e) => {
    const codePostalInfo = await dataLoad(postalCode)
    setState(codePostalInfo.estado)
    setCity(codePostalInfo.ciudad)
  };

  const handleClickEditAddress = () => {
    const formattedAddress = {
      postalCode: postalCode,
      state: state,
      city: city,
      street: street,
      internalNumber: internalNumber,
      colony: colony,
    };
    tableData[addressCurrent] = {
      position: tableData[addressCurrent].position,
      storeName: storeName,
      address: formattedAddress,
      references: references,
      receiverName: receiverName,
      apsCounterToSend: apsCounterToSend,
    };
    setModalEditAddressVisibility(false);
    resetStates();
  };

  const renderFooterModalAddAddress = (
    <>
      <NeoButtonOutlined
        label="Cancelar"
        onClick={() => {
          resetStates()
          setDataDropdown([]);
          setModalEditAddressVisibility(false);
          setErrorRequestPostalCode(false);
          setLoadingButtonValidatePostalCode(false);
        }}
      />
      <NeoButtonMain
        label="Guardar cambios"
        disabled={validateStateForm()}
        onClick={handleClickEditAddress}
      />
    </>
  );

  return <NeoDialog
    extra="dialog-m"
    header="Actualizar sucursal"
    closable={false}
    visible={modalEditAddressVisibility}
    footer={renderFooterModalAddAddress}
  >
    <NeoGridContainer>
      <NeoColumn col="6">
        <NeoInputText
          label="Nombre de sucursal"
          value={storeName}
          onChange={(event) => setStoreName(event.target.value)} />
      </NeoColumn>
      <NeoColumn col="6">
        <NeoInputText
          label="Cantidad de APs a enviar a sucursal"
          name="apsCounterToSend"
          disabled
          keyfilter={/^[0-9,$]*$/}
          value={apsCounterToSend}
          onChange={event => setApsCounterToSend(event.target.value)} />
      </NeoColumn>
      <NeoColumn col="12">
        <NeoInputText
          label="Nombre del destinatario del envío"
          value={receiverName}
          onChange={event => setReceiverName(event.target.value)} />
      </NeoColumn>
      <NeoColumn col="12">
        <NeoInnerSubtitle>Dirección de sucursal</NeoInnerSubtitle>
      </NeoColumn>
      <NeoColumn col="8" extra="p-pb-0">
        <NeoInputText
          label="Dirección calle y numero"
          value={street}
          onChange={event => setStreet(event.target.value)} />
      </NeoColumn>
      <NeoColumn col="4" extra="p-pb-0">
        <NeoInputText
          label="Número interno (opcional)"
          name="internalNumber"
          keyfilter={/^[0-9,$]*$/}
          value={internalNumber}
          onChange={event => setInternalNumber(event.target.value)} />
      </NeoColumn>
      <NeoColumn col="6" extra="p-pb-0">
        <div className="p-d-flex input-btn ">
          <NeoInputText
            col="auto"
            error={errorRequestPostalCode ? " " : ""}
            label="Código postal"
            keyfilter={/^[0-9,$]*$/}
            name="postalCode"
            value={postalCode}
            onChange={event => setPostalCode(event.target.value)} />
          <NeoButtonMain
            label="Validar código postal"
            loading={loadingButtonValidatePostalCode}
            disabled={
              postalCode?.toString().length > 5 ||
              postalCode?.toString().length < 5 ||
              !postalCode
            }
            onClick={handleClickValidatePostalCode} />
        </div>
      </NeoColumn>
      <NeoColumn col="6">
        <NeoDropdown
          label="Colonia"
          name="colony"
          options={dataDropdown}
          value={colony}
          onChange={event => setColony(event.target.value)}
        />
      </NeoColumn>
      <NeoColumn>
        <NeoInputTextDisabled label="Pais" value="México" />
      </NeoColumn>
      <NeoColumn>
        <NeoInputTextDisabled
          label="Estado"
          name="state"
          value={state}
          onChange={event => setState(event.target.value)} />
      </NeoColumn>
      <NeoColumn>
        <NeoInputTextDisabled
          label="Ciudad"
          name="city"
          value={city}
          onChange={event => setCity(event.target.value)} />
      </NeoColumn>
      <NeoColumn col="12">
        <NeoInputTextarea
          label="Referencias (opcional)"
          name="references"
          value={references}
          onChange={event => setReferences(event.target.value)} />
      </NeoColumn>
    </NeoGridContainer>
  </NeoDialog>
}
