import { useEffect } from 'react';
import { useFirstMountState } from './useFirstMountState';

// https://github.com/streamich/react-use/blob/master/docs/useUpdateEffect.md

/**
 * @param {import('react').EffectCallback} effect
 * @param {import('react').DependencyList} [deps]
 * @returns {void}
 */
const useUpdateEffect = (effect, deps) => {
  const isFirstMount = useFirstMountState();

  useEffect(() => {
    if (!isFirstMount) {
      return effect();
    }
  }, deps);
};

export default useUpdateEffect;