import { useRef, useEffect, useState, createRef } from "react";
import useMount from "../../../hooks/useMount";


export default function ContainedImages({
  currentFloorId,
  floors,
  setImg,
  containerStyles = {},
  setAllImagesLoaded
}) {
  const totalImages = floors.length;
  const container = useRef();
  const imgRef = useRef();
  const imagesRefs = useRef([]);
  const [finishedLoadingImages, setFinishedLoadingImages] = useState(() =>
    Array(totalImages).fill(false)
  );
  const allLoaded =
    finishedLoadingImages.length !== 0 &&
    finishedLoadingImages.every((img) => img);

  if (imagesRefs.current.length !== totalImages) {
    imagesRefs.current = Array(totalImages)
      .fill()
      .map((_, i) => imagesRefs.current[i] || createRef());
  }

  useEffect(() => {
    setFinishedLoadingImages(Array(floors.length).fill(false));
  }, [floors]);

  useEffect(() => {
    setAllImagesLoaded(allLoaded);
  }, [allLoaded, setAllImagesLoaded]);

  function handleImageLoad() {
    const prevDisplay = imgRef.current.style.display;
    imgRef.current.style.display = "block";

    const [sX, sY] = calculateSpacing(imgRef);

    const data = {
      naturalWidth: imgRef.current?.naturalWidth,
      naturalHeight: imgRef.current?.naturalHeight,
      clientWidth: imgRef.current?.clientWidth,
      clientHeight: imgRef.current?.clientHeight,
      spacingHorizontal: sX,
      spacingVertical: sY,
      loading: false
    };

    imgRef.current.style.display = prevDisplay;

    setImg(data);
  }

  useEffect(() => {
    if (!allLoaded) return;

    let refI = 0;

    for (let i = 0; i < floors.length; i++) {
      if (floors[i].id === currentFloorId) {
        refI = i;
        break;
      }
    }

    const imgRef = imagesRefs.current[refI];
    const [sX, sY] = calculateSpacing(imgRef);

    const data = {
      naturalWidth: imgRef.current?.naturalWidth,
      naturalHeight: imgRef.current?.naturalHeight,
      clientWidth: imgRef.current?.clientWidth,
      clientHeight: imgRef.current?.clientHeight,
      spacingHorizontal: sX,
      spacingVertical: sY,
      loading: false
    };

    setImg(data);
  }, [allLoaded, currentFloorId, floors, setImg]);

  function calculateSpacing(ref) {
    const containerWidth = container.current?.clientWidth;
    const containerHeight = container.current?.clientHeight;

    const containerRatio = containerWidth / containerHeight;

    const imgWidth = ref.current?.naturalWidth;
    const imgHeight = ref.current?.naturalHeight;

    const imgRatio = imgWidth / imgHeight;
    const ratioIsFIne = imgRatio === containerRatio;

    if (ratioIsFIne) return;
    let spacingVertical = containerHeight - containerWidth / imgRatio;
    let spacingHorizontal = containerWidth - containerHeight * imgRatio;

    if (spacingHorizontal < 0) {
      spacingHorizontal = 0;
    }

    if (spacingVertical < 0) {
      spacingVertical = 0;
    }

    return [spacingHorizontal, spacingVertical];
  }

  function onResize() {
    const imgRef = imagesRefs.current.find((ref) => ref.current.style.display === "block");

    if (!imgRef) return;

    const [sX, sY] = calculateSpacing(imgRef);

    const data = {
      naturalWidth: imgRef.current?.naturalWidth,
      naturalHeight: imgRef.current?.naturalHeight,
      clientWidth: imgRef.current?.clientWidth,
      clientHeight: imgRef.current?.clientHeight,
      spacingHorizontal: sX,
      spacingVertical: sY,
      loading: false
    };

    setImg(data);
  }

  useMount(() => {
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  // TODO: every ap is slighly more down than they should be for some reason
  // There is a bit of extra bottom space when changing display to inline which is the default img tag display
  // maybe there's something there?
  return (
    <div
      ref={container}
      style={{
        height: "100%",
        width: "100%",
        position: "relative",
        ...containerStyles
      }}
    >
      {floors.map(({ id, url }, index) => {
        const floorId = id;
        let visible = false;
        if (allLoaded) {
          visible = currentFloorId === +floorId;
        }

        return (
          <img
            alt="mapa"
            key={index}
            src={url}
            ref={imagesRefs.current[index]}
            onLoad={(e) => {
              if (index === 0) {
                imgRef.current = e.target;
                handleImageLoad();
              }
              setFinishedLoadingImages((prev) => {
                const newFinished = [...prev];
                newFinished[index] = e.target.complete;
                return newFinished;
              });
            }}
            style={{
              width: "100%",
              objectFit: "contain",
              height: "100%",
              objectPosition: "top",
              display: visible ? "block" : "none"
            }}
          />
        );
      })}
    </div>
  );
}
