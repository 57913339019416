import { useReducer } from 'react';

// https://github.com/streamich/react-use/blob/master/docs/useToggle.md
const toggleReducer = (state, nextValue) =>
  typeof nextValue === 'boolean' ? nextValue : !state;

const useToggle = (initialValue) => {
  return useReducer(toggleReducer, initialValue);
};

export default useToggle;