import React from "react";

export default function LastName(props) {
  return (
    <>
      <div className="sp-field sp-block">
        <label htmlFor={props.label}>{props.label}</label>

        <input type="text" id={"LastName"} name={"LastName"} />
      </div>
    </>
  );
}
