import React, { useState } from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonOutlined from "../../../design/design_components/neo/button/NeoButtonOutlined.base";
import { CONST } from "../../../consts/consts";
import { postContactStatusChange } from "../../../service/Contacts.service";
import useToastContext from "../../../hooks/useToastContext.hook";
import { useHistory } from "react-router-dom";

export default function BlackListButton({ contactData }) {
  const toast = useToastContext();
  const history = useHistory();
  const [modalBackListVisibility, setModalBackListVisibility] = useState(false);
  const [modalSuccessActionVisibility, setModalSuccessActionVisibility] =
    useState(false);

  const isBlockedContact = () => {
    return contactData?.CONTACT_STATUS === CONST.CONTACT_STATUS.BLOCK.label;
  };

  const handleClickToggleBlacklist = async () => {
    let statusToChange;
    let labelChoosed;
    if (isBlockedContact()) {
      statusToChange = CONST.CONTACT_STATUS.ACTIVE.ID;
      labelChoosed = CONST.CONTACT_STATUS.ACTIVE.label;
    } else {
      statusToChange = CONST.CONTACT_STATUS.BLOCK.ID;
      labelChoosed = CONST.CONTACT_STATUS.BLOCK.label;
    }

    let changeSubmit = await postContactStatusChange({
      contactId: contactData.ID,
      statusId: statusToChange,
    });

    if (!changeSubmit.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        "Error",
        "No se pudo realizar el cambio de estatus."
      );
    } else {
      let state = { ...contactData };
      state.CONTACT_STATUS = labelChoosed;
      history.replace({ ...history.location, state });
    }

    setModalBackListVisibility(false);
    setModalSuccessActionVisibility(true);
  };

  const footerTemplateBlackListModal = (
    <>
      <NeoButtonOutlined
        label="Cancelar"
        onClick={() => setModalBackListVisibility(false)}
      />
      <NeoButtonMain label={isBlockedContact()
        ? "Desbloquear "
        : "Bloquear "
      } onClick={handleClickToggleBlacklist} />
    </>
  );
  const footerTemplateSuccessActionModal = (
    <NeoButtonMain
      label="Aceptar"
      onClick={() => setModalSuccessActionVisibility(false)}
    />
  );
  return (
    <>
      <NeoButtonMain
        label={isBlockedContact() ? "Desbloquear" : "Bloquear"}
        icon={isBlockedContact() ? "pi pi-check" : "pi pi-ban"}
        onClick={() => setModalBackListVisibility(true)}
      />
      <NeoDialog
        header={isBlockedContact()
          ? "Desbloquear contacto"
          : "Bloquear contacto"
        }
        visible={modalBackListVisibility}
        closable={false}
        footer={footerTemplateBlackListModal}
      >
        <p>
          {isBlockedContact()
            ? "¿Está seguro de desbloquear este contacto? El contacto podrá volver a navegar en tu red."
            : "¿Está seguro de bloquear este contacto? El contacto no podrá navegar en tu red."}
        </p>
      </NeoDialog>
      <NeoDialog
        header={isBlockedContact()
          ? "Bloqueo exitoso"
          : "Desbloqueo exitoso"
        }
        visible={modalSuccessActionVisibility}
        closable={false}
        footer={footerTemplateSuccessActionModal}
      >
        <p>
          {isBlockedContact()
            ? "El contacto ya no podrá acceder a internet"
            : "El contacto ya puede navegar en tu red"
          }
        </p>
      </NeoDialog>
    </>
  );
}
