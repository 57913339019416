import { ELEMENTS_COMPONENTS, GROUPS_OPTIONS } from "../consts/splashPages.consts";

const stateInit = Object.values(GROUPS_OPTIONS)
// Temporal fix for full name
ELEMENTS_COMPONENTS.map(element => {
	if (!element.lock && element.id !== 2) {
    stateInit[element.group].items.push(element);
  }
})

export default (state = stateInit, action) => {
	switch (action.type) {
		case "reset":
			ELEMENTS_COMPONENTS.map(option => option.inUse = false)
			return state
		case "disableOption":
			ELEMENTS_COMPONENTS.find((option) => option.id === action.idOption).inUse = true
			return state
		case "eneableOption":
			ELEMENTS_COMPONENTS.find((option) => option.id === action.idOption).inUse = false
			return state
		case "disableOptionForm":
			//deja activa solo las opciones de texto, la condición debera cambiar en un futuro
			ELEMENTS_COMPONENTS.map(option => option.inUse = option.group !== GROUPS_OPTIONS.TEXTS.id)
			return state
		default:
			return state
	}
}
