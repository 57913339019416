import { auth, storage } from "../config/firebase.config";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  postUploadNewImageMedia,
  postUploadNewVideoMedia,
} from "../service/Media.service";
import { useEffect, useState } from "react";
import { CONST } from "../consts/consts";
import useToastContext from "./useToastContext.hook";
import Routes from "../routers/Routes.router.json";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NeoSpinner from "../design/design_components/neo/overlay/NeoSpinner.base";

export default function useUploadMedia() {
  const userId = auth.currentUser.uid;
  const toast = useToastContext();
  const [response, setResponse] = useState(null);
  const history = useHistory();

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const uploadUncompressedImageMedia = async (file, metaData) => {
    let path = `neowifi/media/image/${userId}/${new Date().valueOf()}_${
      file.name
    }`;
    const fileRef = ref(storage, path);
    uploadBytes(fileRef, file).then((snapshot) => {
      getDownloadURL(fileRef).then((url) => {
        uploadImageToStorage({
          imageURL: url,
          metaData: metaData,
        }).then((result) => {
          if (result.success) {
            toast.setMessage(
              CONST.SEVERITY.SUCCESS,
              CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
              CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
            );
            history.push(Routes.media.home);
          } else {
            toast.setMessage(
              CONST.SEVERITY.ERROR,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
            );
            delay(2000).then((e) => {
              history.go(0);
            });
          }
          return result;
        });
      });
    });
  };

  const updateUncompressedImageMedia = async (file, metaData, refDelete) => {
    await deleteObject(refDelete);
    let path = `neowifi/media/image/${userId}/${new Date().valueOf()}_${
      file.name
    }`;
    const fileRef = ref(storage, path);
    uploadBytes(fileRef, file).then((snapshot) => {
      getDownloadURL(fileRef).then((url) => {
        uploadImageToStorage({
          imageURL: url,
          metaData: metaData,
        }).then((result) => {
          if (result.success) {
            toast.setMessage(
              CONST.SEVERITY.SUCCESS,
              CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
              CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
            );
            history.push(Routes.media.home);
          } else {
            toast.setMessage(
              CONST.SEVERITY.ERROR,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
            );
            delay(2000).then((e) => {
              history.go(0);
            });
          }
          return result;
        });
      });
    });
  };

  const uploadUncompressedVideoMedia = async (file, metaData) => {
    let path = `neowifi/media/video/${userId}/${new Date().valueOf()}_${
      file.name
    }`;
    const fileRef = ref(storage, path);
    uploadBytes(fileRef, file).then((snapshot) => {
      getDownloadURL(fileRef).then((url) => {
        uploadVideoToStorage({
          videoURL: url,
          metaData: metaData,
        }).then((result) => {
          if (result.success) {
            toast.setMessage(
              CONST.SEVERITY.SUCCESS,
              CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
              CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
            );
            history.push(Routes.media.home);
          } else {
            toast.setMessage(
              CONST.SEVERITY.ERROR,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
            );

            delay(2000).then((e) => {
              history.go(0);
            });
          }
        });
      });
    });
  };

  const updateUncompressedVideoMedia = async (file, metaData, refDelete) => {
    await deleteObject(refDelete);
    let path = `neowifi/media/video/${userId}/${new Date().valueOf()}_${
      file.name
    }`;
    const fileRef = ref(storage, path);
    uploadBytes(fileRef, file).then((snapshot) => {
      getDownloadURL(fileRef).then((url) => {
        uploadVideoToStorage({
          videoURL: url,
          metaData: metaData,
        }).then((result) => {
          if (result.success) {
            toast.setMessage(
              CONST.SEVERITY.SUCCESS,
              CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
              CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
            );
            history.push(Routes.media.home);
          } else {
            toast.setMessage(
              CONST.SEVERITY.ERROR,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
              CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
            );

            delay(2000).then((e) => {
              history.go(0);
            });
          }
        });
      });
    });
  };

  const uploadImageToStorage = async (data) => {
    let result = await postUploadNewImageMedia(data);
    setResponse(result);
    return result;
  };

  const uploadVideoToStorage = async (data) => {
    let result = await postUploadNewVideoMedia(data);
    setResponse(result);
    return result;
  };

  return [
    uploadUncompressedImageMedia,
    uploadUncompressedVideoMedia,
    updateUncompressedImageMedia,
    updateUncompressedVideoMedia,
  ];
}
