import Routes from "../../../routers/Routes.router.json";
import General from "../General.view";
import Marketing from "../Marketing.view";
import Stores from "../Stores.view";
import Activity from "../Activity.view";
import SocialNetworks from "../SocialNetworks.view";
import Contacts from "../Contacts.view";
import AdPerformance from "../AdPerformance.view";
import DirectMarketing from "../DirectMarketing.view";
import ConnectionMonitoring from "../ConnectionMonitoring.view";
import AdPerformanceByAd from "../AdPerformanceByAd.view";
import MultichannelSends from "../MultichannelSends.view";
import PhygitalConversions from "../PhygitalConversions.view";
import DemographicInformation from "../DemographicInformation.view";
import Devices from "../Devices.view";
import ContactChannels from "../ContactChannels.view";
import GrowthAnalysis from "../GrowthAnalysis.view";
import VisitsCategory from "../VisitsCategory.view";
import AverageVisit from "../AverageVisit.view";
import Validations from "../Validations.view";

/**
 * This variable is used to define the structure of the dashboard
 * menu. The key is the name of the section and the value is an array
 * with the id of the dashboards that belong to that section.
 */
const dashboardMenuStructure = {
  Generales: [1, 8, 9, 10],
  Marketing: [2, 11, 12, 13],
  Sucursales: 3,
  Actividad: 4,
  "Redes Sociales": 5,
  Audiencias: [6,14,15,16,17,18,19,20],
};

function getItemsMenu(history, userDashboards, onMenuClick = () => {}) {
   const dashboards = [];

  const generalesDashboard = getGeneralesMenu(
    history,
    userDashboards,
    onMenuClick
  );

  if (generalesDashboard) {
    dashboards.push(generalesDashboard);
  }

  const marketingDashboard = getMarketingMenu(
    history,
    userDashboards,
    onMenuClick
  );

  if (marketingDashboard) {
    dashboards.push(marketingDashboard);
  }

  const sucursalesDashboard = getSucursalesMenu(
    history,
    userDashboards,
    onMenuClick
  );

  if (sucursalesDashboard) {
    dashboards.push(sucursalesDashboard);
  }

  const actividadDashboard = getActividadMenu(
    history,
    userDashboards,
    onMenuClick
  );

  if (actividadDashboard) {
    dashboards.push(actividadDashboard);
  }

  const redesSocialesDashboard = getRedesSocialesMenu(
    history,
    userDashboards,
    onMenuClick
  );

  if (redesSocialesDashboard) {
    dashboards.push(redesSocialesDashboard);
  }

  const audienciasDashboard = getAudienciasMenu(
    history,
    userDashboards,
    onMenuClick
  );

  if (audienciasDashboard) {
    dashboards.push(audienciasDashboard);
  }

  return dashboards;
}

/**
 * @param {Object} history
 * @param {int[]} userDashboards
 */
function getGeneralesMenu(history, userDashboards, onMenuClick) {
  // If the user does not have any general dashboard, then we should not display
  if (
    userDashboards.every((d) => !dashboardMenuStructure.Generales.includes(d))
  ) {
    return null;
  }

  let items;

  /**
   * If the user has any dashboard that is not the first one, then
   * we should display the general dashboards dropdown. If that is not the case
   * then we will display the old general dashboard (ID = 1) as the only option instead
   * of the dropdown.
   */
  if (
    userDashboards.some(
      (d) => d !== 1 && dashboardMenuStructure.Generales.includes(d)
    )
  ) {
    const generalDashboards = {
      1: {
        label: "Contactos registrados",
        command: () => {
          onMenuClick(1);
          history.push({
            pathname: Routes.statistics.home,
          });
        },
      },
      8: {
        label: "Desempeño de anuncios",
        command: () => {
          onMenuClick(8);
          history.push({
            pathname: Routes.statistics.adPerformance,
          });
        },
      },
      9: {
        label: "Envíos de marketing directo",
        command: () => {
          onMenuClick(9);
          history.push({
            pathname: Routes.statistics.directMarketing,
          });
        },
      },
      10: {
        label: "Monitoreo de conexiones",
        command: () => {
          onMenuClick(10);
          history.push({
            pathname: Routes.statistics.connectionMonitoring,
          });
        },
      },
    };

    items = dashboardMenuStructure.Generales.filter(
      (id) => userDashboards.includes(id) && generalDashboards[id]
    ).map((id) => {
      return generalDashboards[id];
    });
  }

  const menu = {
    id: 1,
    label: "Generales",
    icon: "fa fa-chart-line",
    items: items,
  };

  if (items === undefined) {
    menu.isOldDashboard = true;
    menu.command = () => {
      onMenuClick(1);
      history.push({
        pathname: Routes.statistics.home,
      });
    };
  }

  return menu;
}

/**
 * @param {Object} history
 * @param {int[]} userDashboards
 */
function getMarketingMenu(history, userDashboards, onMenuClick) {
  // If the user does not have any marketing dashboard, then we should not display
  if (
    userDashboards.every((d) => !dashboardMenuStructure.Marketing.includes(d))
  ) {
    return null;
  }

  const marketingDashboards = {
    11: {
      label: "Desempeño por tipo de anuncio",
      command: () => {
        onMenuClick(11);
        history.push({
          pathname: Routes.statistics.adPerformanceByAd,
        });
      },
    },
    12: {
      label: "Envíos multicanal",
      command: () => {
        onMenuClick(12);
        history.push({
          pathname: Routes.statistics.multichannelSends,
        });
      },
    },
    13: {
      label: "Conversiones phygital",
      command: () => {
        onMenuClick(13);
        history.push({
          pathname: Routes.statistics.phygitalConversions,
        });
      },
    },
  };

  const items = dashboardMenuStructure.Marketing.filter(
    (id) => userDashboards.includes(id) && marketingDashboards[id]
  ).map((id) => {
    return marketingDashboards[id];
  });

  const menu = {
    id: 2,
    label: "Marketing",
    icon: "fa fa-bullhorn",
    items: items.length > 0 ? items : undefined,
  };

  if (items.length === 0) {
    menu.isOldDashboard = true;
    menu.command = () => {
      onMenuClick(2);
      history.push({
        pathname: Routes.statistics.marketing,
      });
    };
  }

  return menu;
}

/**
 * @param {Object} history
 */
function getSucursalesMenu(history, userDashboards, onMenuClick) {
  if (!userDashboards.includes(3)) return null;

  return {
    id: 3,
    label: "Sucursales",
    icon: "fa fa-store",
    command: () => {
      onMenuClick(3);
      history.push({
        pathname: Routes.statistics.stores,
      });
    },
    isOldDashboard: true,
  };
}

/**
 * @param {Object} history
 */
function getActividadMenu(history, userDashboards, onMenuClick) {
  if (!userDashboards.includes(4)) return null;

  return {
    id: 4,
    label: "Actividad",
    icon: "fa fa-running",
    command: () => {
      onMenuClick(4);
      history.push({
        pathname: Routes.statistics.activity,
      });
    },
    isOldDashboard: true,
  };
}

/**
 * @param {Object} history
 */
function getRedesSocialesMenu(history, userDashboards, onMenuClick) {
  if (!userDashboards.includes(5)) return null;

  return {
    id: 5,
    label: "Redes Sociales",
    icon: "fa fa-users",
    command: () => {
      onMenuClick(5);
      history.push({
        pathname: Routes.statistics.socialNetworks,
      });
    },
    isOldDashboard: true,
  };
}

/**
 * @param {Object} history
 */
function getAudienciasMenu(history, userDashboards, onMenuClick) {
  // If the user does not have any audience dashboard, then we should not display
  if (
    userDashboards.every((d) => !dashboardMenuStructure.Audiencias.includes(d))
  ) {
    return null;
  }

  const audienceDashboards = {
    14: {
      label: "Información demográfica",
      command: () => {
        onMenuClick(14);
        history.push({
          pathname: Routes.statistics.demographicInformation,
        });
      },
    },
    15: {
      label: "Dispositivos",
      command: () => {
        onMenuClick(15);
        history.push({
          pathname: Routes.statistics.devices,
        });
      },
    },
    16: {
      label: "Canales de contacto",
      command: () => {
        onMenuClick(16);
        history.push({
          pathname: Routes.statistics.contactChannels,
        });
      },
    },
    17: {
      label: "Análisis de crecimiento",
      command: () => {
        onMenuClick(17);
        history.push({
          pathname: Routes.statistics.growthAnalysis,
        });
      },
    },
    18: {
      label: "Categorías de visitas",
      command: () => {
        onMenuClick(18);
        history.push({
          pathname: Routes.statistics.visitsCategory,
        });
      },
    },
    19: {
      label: "Visita promedio",
      command: () => {
        onMenuClick(19);
        history.push({
          pathname: Routes.statistics.averageVisit,
        });
      },
    },
    20: {
      label: "Validaciones",
      command: () => {
        onMenuClick(20);
        history.push({
          pathname: Routes.statistics.validations,
        });
      },
    },
  };

  const items = dashboardMenuStructure.Audiencias.filter(
    (id) => userDashboards.includes(id) && audienceDashboards[id]
  ).map((id) => {
    return audienceDashboards[id];
  });

  const menu = {
    id: 6,
    label: "Audiencias",
    icon: "fa fa-address-book",
    items: items.length > 0 ? items : undefined,
  };

  if (items.length === 0) {
    menu.isOldDashboard = true;
    menu.command = () => {
      onMenuClick(6);
      history.push({
        pathname: Routes.statistics.contacts,
      });
    };
  }


  return menu;
}

/**
 * @param {Object[]} userDashboards
 * @returns
 */
function getAllDashboards(userDashboards) {
  const dashboardIsCustom = /** @param {int} id */ (id) =>
    Boolean(userDashboards?.find((d) => d.DASHBOARD_ID === id)?.IS_CUSTOM);

  return [
    {
      id: 1,
      customComponent: General,
      customPath: Routes.statistics.home,
      displayTitle: !dashboardIsCustom(1),
      displayDownloadButton: !dashboardIsCustom(1),
      displayFilters: !dashboardIsCustom(1),
    },
    {
      id: 2,
      customComponent: Marketing,
      customPath: Routes.statistics.marketing,
      displayTitle: false,
      displayDownloadButton: false,
      displayFilters: false,
    },
    {
      id: 3,
      customComponent: Stores,
      customPath: Routes.statistics.stores,
      displayTitle: false,
      displayDownloadButton: false,
      displayFilters: false,
    },
    {
      id: 4,
      customComponent: Activity,
      customPath: Routes.statistics.activity,
      displayTitle: false,
      displayDownloadButton: false,
      displayFilters: false,
    },
    {
      id: 5,
      customComponent: SocialNetworks,
      customPath: Routes.statistics.socialNetworks,
      displayTitle: false,
      displayDownloadButton: false,
      displayFilters: false,
    },
    {
      id: 6,
      customComponent: Contacts,
      customPath: Routes.statistics.contacts,
      displayTitle: false,
      displayDownloadButton: false,
      displayFilters: false,
    },
    {
      id: 8,
      customComponent: DirectMarketing,
      customPath: Routes.statistics.directMarketing,
      displayTitle: !dashboardIsCustom(8),
      displayDownloadButton: !dashboardIsCustom(8),
      displayFilters: !dashboardIsCustom(8),
    },
    {
      id: 9,
      customComponent: AdPerformance,
      customPath: Routes.statistics.adPerformance,
      displayTitle: !dashboardIsCustom(9),
      displayDownloadButton: !dashboardIsCustom(9),
      displayFilters: !dashboardIsCustom(9),
    },
    {
      id: 10,
      customComponent: ConnectionMonitoring,
      customPath: Routes.statistics.connectionMonitoring,
      displayTitle: !dashboardIsCustom(10),
      displayDownloadButton: !dashboardIsCustom(10),
      displayFilters: !dashboardIsCustom(10),
    },
    {
      id: 11,
      customComponent: AdPerformanceByAd,
      customPath: Routes.statistics.adPerformanceByAd,
      displayTitle: !dashboardIsCustom(11),
      displayDownloadButton: !dashboardIsCustom(11),
      displayFilters: !dashboardIsCustom(11),
    },
    {
      id: 12,
      customComponent: MultichannelSends,
      customPath: Routes.statistics.multichannelSends,
      displayTitle: !dashboardIsCustom(12),
      displayDownloadButton: !dashboardIsCustom(12),
      displayFilters: !dashboardIsCustom(12),
    },
    {
      id: 13,
      customComponent: PhygitalConversions,
      customPath: Routes.statistics.phygitalConversions,
      displayTitle: !dashboardIsCustom(13),
      displayDownloadButton: !dashboardIsCustom(13),
      displayFilters: !dashboardIsCustom(13),
    },
    {
      id: 14,
      customComponent: DemographicInformation,
      customPath: Routes.statistics.demographicInformation,
      displayTitle: !dashboardIsCustom(14),
      displayDownloadButton: !dashboardIsCustom(14),
      displayFilters: !dashboardIsCustom(14),
    },
    {
      id: 15,
      customComponent: Devices,
      customPath: Routes.statistics.devices,
      displayTitle: !dashboardIsCustom(15),
      displayDownloadButton: !dashboardIsCustom(15),
      displayFilters: !dashboardIsCustom(15),
    },
    {
      id: 16,
      customComponent: ContactChannels,
      customPath: Routes.statistics.contactChannels,
      displayTitle: !dashboardIsCustom(16),
      displayDownloadButton: !dashboardIsCustom(16),
      displayFilters: !dashboardIsCustom(16),
    },
    {
      id: 17,
      customComponent: GrowthAnalysis,
      customPath: Routes.statistics.growthAnalysis,
      displayTitle: !dashboardIsCustom(17),
      displayDownloadButton: !dashboardIsCustom(17),
      displayFilters: !dashboardIsCustom(17),
    },
    {
      id: 18,
      customComponent: VisitsCategory,
      customPath: Routes.statistics.visitsCategory,
      displayTitle: !dashboardIsCustom(18),
      displayDownloadButton: !dashboardIsCustom(18),
      displayFilters: !dashboardIsCustom(18),
    },
    {
      id: 19,
      customComponent: AverageVisit,
      customPath: Routes.statistics.averageVisit,
      displayTitle: !dashboardIsCustom(19),
      displayDownloadButton: !dashboardIsCustom(19),
      displayFilters: !dashboardIsCustom(19),
    },
    {
      id: 20,
      customComponent: Validations,
      customPath: Routes.statistics.validations,
      displayTitle: !dashboardIsCustom(20),
      displayDownloadButton: !dashboardIsCustom(20),
      displayFilters: !dashboardIsCustom(20),
    }
  ];
}

export { getItemsMenu, getAllDashboards };
