import React, { useState, useEffect } from 'react';
import NeoCalendar from '../form/NeoCalendar.base';

const NeoDateRangeFilter = ({ currentViewDate, allowViewDateChange = true, ...props }, ref) => {
  const [dateSelected, setDateSelected] = useState(null);
  const [viewDate, setViewDate] = useState(currentViewDate || new Date());

  useEffect(() => {
    if (props.shouldEmptyDateFilter) {
      setDateSelected(null);
      props.setShouldEmptyDateFilter(false);
    }
  }, [dateSelected, props, props.shouldEmptyDateFilter]);

  useEffect(() => {
    if (currentViewDate) {
      setViewDate(currentViewDate);
    }
  }, [currentViewDate])


  function onDateSelectedChanges( e ){
    setDateSelected( e.value )
    ref.current.filter( e.value, props.field, props.matchFilter )
  }

  function handleOnViewDateChange (e) {
    if (!allowViewDateChange) return;
    setViewDate(e.value);
  }
  
  return ( 
    <NeoCalendar value={dateSelected} showButtonBar 
      icon={true} onChange={onDateSelectedChanges} 
      selectionMode="range" placeholder={props.placeholder} 
      className="p-column-filter"
      dateFormat='dd/mm/yy'
      viewDate={viewDate}
      onViewDateChange={handleOnViewDateChange}
      {...props}
    />

  )
}

export default React.forwardRef(NeoDateRangeFilter);
