import { ProgressSpinner } from "primereact/progressspinner";

export default function InternalSpinner(props) {
  const size = props.size ?? 75;

  return (
    <ProgressSpinner
      style={{ width: `${size}px`, height: `${size}px`, margin: "0" }}
    />
  );
}
