import { auth } from "../config/firebase.config";
import { CONST } from "../consts/consts";

const postNewUser = async (userData) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
    // userId
  };

  try {
    result = await fetch(
      `${CONST.API_USER_ROUTE}/registerNewUser`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getUserApBilled = async () => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(`${CONST.API_USER_ROUTE}/apBilled`, requestOptions);
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getUserStatus = async () => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
  };

  try {
    result = await fetch(`${CONST.API_USER_ROUTE}/status`, requestOptions);
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

const getLoginPrivateToken = async (sessionId, token) => {
  let response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  let result;
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sessionId: sessionId,
      token: token,
    }),
  };

  try {
    result = await fetch(
      `${CONST.API_USER_ROUTE}/getLoginToken`,
      requestOptions
    );
    if (!result.ok) {
      let errorResult = await result.json();
      response.message = errorResult.details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  const resultJson = await result.json();
  response.result = resultJson.result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

export { postNewUser, getUserApBilled, getUserStatus, getLoginPrivateToken };
