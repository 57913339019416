import { useRef, useState } from "react";
import { storage } from "../config/firebase.config";
import NeoInputFile from "../design/design_components/neo/form/NeoInputFile.base";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { uploadLogo } from "../service/Settings.service";
import NeoNote from "../design/design_components/neo/data/NeoNote.base";
import useToastContext from "./useToastContext.hook";
import { CONST } from "../consts/consts";
import NeoInputPhoto from "../design/design_components/neo/form/NeoInputPhoto.base";

export default function useCompressedImageUploader(setImageLoading) {
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const imageRef = useRef(null);
  const toast = useToastContext();
  const userId = 1;
  const IMAGE_SIZE_FILE = 200000;
  const [uploadLabel, setUploadLabel] = useState("Cargar imagen");

  const uploadUncompressedImage = (image) => {
    let path = `logo/${userId}/logo.png`;
    setImageLoading(true);
    const fileRef = ref(storage, path);
    uploadBytes(fileRef, image).then((snapshot) => {
      getDownloadURL(fileRef).then((url) => {
        uploadLogoUrl(url);
      });
    });
    setImageLoading(false);
    setSelectedImage(null);
    setUploadLabel("Cargar imagen");
  };

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const uploadLogoUrl = async (url) => {
    await uploadLogo(url);
    setImage(url);
    await delay(500);
  };

  const onSelect = () => {
    if (selectedImage.size > IMAGE_SIZE_FILE) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        "No se pudo actualizar su logo",
        `Solo se puede subir una imagen no mayor a ${IMAGE_SIZE_FILE / 1000}KB`
      );
    } else {
      uploadUncompressedImage(selectedImage);
    }
  };

  const handleOnChange = (file) => {
    setUploadLabel(file.name);
    setSelectedImage(file);
    setImage(URL.createObjectURL(file));
  };

  const selectedImages = (path) => {
    return (
      <>
        <NeoInputPhoto
          height="100px"
          url={`${image}`}
          tooltip={`Peso máximo de imagen ${IMAGE_SIZE_FILE / 1000}KB\n
          Solo se permite imagenes .png\n
          Tamaño recomendado de imagen 250 x 150 px`}
          onChange={handleOnChange}
        />
      </>
    );
  };

  const imageUploadLogo = () => {
    return selectedImages(`logo/${userId}/logo.png`);
  };

  return [imageUploadLogo, image, setImage, onSelect, selectedImage];
}
