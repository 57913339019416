export default function StoreHeatmapTab({
  coordX,
  coordY,
  background,
  border,
  label,
  department,
  image,
  children,
}) {
  const top = `${(coordY) * image?.clientHeight / image?.naturalHeight}px`;
  const left = `${(coordX) * image?.clientWidth/ image?.naturalWidth}px`;
  return (
    <div className="ap-container">
      <div className="ap" style={{ top, left, background, border }} />
      <div className="ap-info" style={{ top, left }}>
        <p className="ap-location">{label}</p>
        <p className="ap-department">{department}</p>
        <p className="ap-users">{children}</p>
      </div>
    </div>
  );
}
