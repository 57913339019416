import React from "react";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import LogoWhite from "../../../design/assets/img/wfi/logo.svg";

export default function LogoDeal() {
  return (
    <NeoColumn col="12" extra="px-0">
      <a href={`${process.env.REACT_APP_LANDING_PAGE_URL}`}>
        <img className="logo-intro" src={LogoWhite} alt="NeoWifi" />
      </a>
    </NeoColumn>
  );
}
