import React from "react";
import { Route, Switch } from "react-router-dom";
import LandingPage from "../landingPage/LandingPage.view";
import SummaryDeal from "../views/deals/SummaryDeal.view";
import Routes from "./Routes.router.json";
import Deals from "../views/deals/Deals.view";
import Login from "../views/login/Login.view";
import LoginTest from "../views/login/LoginTest.view";
import LoginSSO from "../views/login/LoginSSO.view";
import { ToastContextProvider } from "../contexts/ToastContext.context";
import RecieveReview from "../views/reviews/RecieveReview.view";
import RequestPrivateToken from "../views/login/RequestPrivateToken.view";

export default function Unauthorize() {
  return (
    <ToastContextProvider>
      <Switch>
        <Route exact path={Routes.landingPage.home} component={LandingPage} />
        <Route exact path={Routes.deals.summaryDeal} component={SummaryDeal} />
        <Route exact path={Routes.deals.home} component={Deals} />
        <Route exact path={Routes.login.home} component={Login} />
        <Route exact path={Routes.login.sso} component={LoginSSO} />
        <Route exact path={Routes.login.test} component={LoginTest} />
        <Route exact path={Routes.review.recieve} component={RecieveReview} />
        <Route
          exact
          path={Routes.login.privateToken}
          component={RequestPrivateToken}
        />
        <Route exact path={"/"} component={LoginTest} />
      </Switch>
    </ToastContextProvider>
  );
}
