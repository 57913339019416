import React, { useRef, useState, useEffect } from "react";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base";
import { dateFormat, filterDate } from "../../../utils/date.utils";
import * as MEDIA_CONSTS from "../../../consts/media.consts.js";

export default function MediaStatisticsStoreTable(_props) {
  const [visits, setVisitis] = useState([]);
  const visitsTableRef = useRef();

  useEffect(() => {
    setVisitis(_props.value);
  }, [_props.value])

  const visitClickCollection = [
    { label: "Sí", value: true },
    { label: "No", value: false }
  ];

  const emailFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={visitsTableRef}
        field={"CONTACT.EMAIL.VALUE"}
        placeholder={"Buscar por correo"}
        filterMatch={"contains"}
      />
    );
  };

  const clicFilterElement = () => {
    return (
      <NeoMultiSelectFilter
        ref={visitsTableRef}
        options={visitClickCollection}
        field="VISIT.CLICK"
        placeholder="Todos"
        selectedItemsLabel="No seleccionado"
      />
    );
  };

  const deviceFilterElement = () => {
    return (
      <NeoInputTextFilter
        ref={visitsTableRef}
        field={"CONTACT.DEVICE"}
        placeholder={"Buscar por dispositivo"}
        filterMatch={"contains"}
      />
    );
  };

  const createdAtFilterElement = () => {
    return (
      <NeoDateRangeFilter
        ref={visitsTableRef}
        field={"VISIT.CREATED_AT"}
        placeholder={"Buscar por fecha"}
        matchFilter="custom"
      />
    );
  };

  const clicBodyTemplate = (click) => {
    return (
      <>
        <span className="p-column-title">Clic</span>
        {click ? "Si" : "No"}
      </>
    );
  }

  const createdAtBodyTemplate = (createdAt) => {
    return (
      <>
        <span className="p-column-title">Fecha</span>
        {dateFormat(createdAt)}
      </>
    );
  };

  return (
    <NeoTable
      emptyMessage="Esta sucursal no tiene visitas"
      ref={visitsTableRef}
      value={visits}
      removableSort
      paginator
      rows={8}
    >
      <NeoTableColumn
        field="CONTACT.EMAIL.VALUE"
        header="Email"
        placeholder="Email"
        sortable
        filter
        filterElement={emailFilterElement()}
        filterMatchMode="contains"
      />
      {Number(_props.fileTypeId) !== MEDIA_CONSTS.TYPE.VIDEO.ID &&
        <NeoTableColumn
          field="VISIT.CLICK"
          header="Clic"
          body={(e) => clicBodyTemplate(e.VISIT.CLICK)}
          sortable
          filter
          filterElement={clicFilterElement()}
          filterMatchMode="contains"
        />
      }
      <NeoTableColumn
        field="CONTACT.DEVICE"
        header="Dispositivo"
        placeholder="Dispositivo"
        sortable
        filter
        filterElement={deviceFilterElement()}
        filterMatchMode="contains"
      />
      <NeoTableColumn
        field="VISIT.CREATED_AT"
        header="Fecha"
        body={(e) => createdAtBodyTemplate(e.VISIT.CREATED_AT)}
        sortable
        filter
        filterElement={createdAtFilterElement()}
        filterFunction={filterDate}
        filterMatchMode="custom"
      />
    </NeoTable>
  );
}
