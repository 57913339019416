import React from "react";

export default function TermAndCondition(props) {
  return (
    <>
      <>
        <p>
          La dirección de los términos y condiciones se modifica en la sección
          de configuración
        </p>
        <br />
        <p>¿Desea ir al panel de configuración?</p>
        <strong>Se perderá todos los cambios realizados</strong>
      </>
    </>
  );
}
