import React from "react";

export default function Birthday(props) {
  return (
    <>
      <div className="sp-field sp-block">
        <label htmlFor="birthday">{props.label}</label>
        <input type="date" id="birthday" name="birthday" />
      </div>
    </>
  );
}
