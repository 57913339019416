import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoSpinner from "../../design/design_components/neo/overlay/NeoSpinner.base";
import Icono from "../../design/assets/img/wfi/icons/icon-multimedia.svg";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import ModifyMediaButton from "./components/ModifyMediaButton.component";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";
import { getStatistcMedia } from "../../service/Media.service";
import NeoCard from "../../design/design_components/neo/panel/NeoCard.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoDataMain from "../../design/design_components/neo/data/NeoDataMain.base";
import NeoInnerTitle from "../../design/design_components/neo/title/NeoInnerTitle.base";
import MediaStatisticsTable from "./components/MediaTableStatistics.component";
import * as MEDIA_CONSTS from "../../consts/media.consts.js";

export default function MediaStatistics() {
  const { mediaId, fileTypeId, mediaName } = useParams();
  const [loading, setLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState({
    MEDIA_TRACKER_RENDERED: 0,
    MEDIA_TRACKER_CLICKS: 0,
    STORES: []
  });

  useEffect(() => {
    let mount = true;
    if (mount) {
      setLoading(true);
      getStatistics();
    }

    return () => {
      mount = false;
    };
  }, []);

  const getStatistics = async () => {
    setLoading(true);
    const rawStatistcMedia = await getStatistcMedia(mediaId, fileTypeId);
    setStatisticsData(rawStatistcMedia.result[0]);
    setLoading(false);
  };

  return (
    <>
      {loading ? <NeoSpinner /> : null}
      <NeoGridContainer>
        <NeoTitleSecond
          title="Detalle de anuncio"
          subtitle={mediaName}
          col="12"
          icon={Icono}
          goback={"True"}
        />

        <NeoButtonSection
          align="right"
          col="12"
          md="6"
          extra="p-col-align-center p-flex-sm-row p-flex-column"
        >
          {/* <ModifyMediaButton /> */}
        </NeoButtonSection>
      </NeoGridContainer>

      {/* This must change; Must be a component*/}

      <NeoCard extra="p-mb-2">
        <NeoInnerTitle extra="primero">Estadísticas generales</NeoInnerTitle>
        <NeoColumn>
          <NeoDataMain
            label="Impresiones totales"
            extra="p-text-center"
            fact={statisticsData.MEDIA_TRACKER_RENDERED}
          />
        </NeoColumn>
        {Number(fileTypeId) !== MEDIA_CONSTS.TYPE.VIDEO.ID &&
          <NeoColumn>
            <NeoDataMain
              label="Clics totales"
              extra="p-text-center"
              fact={statisticsData.MEDIA_TRACKER_CLICKS}
            />
          </NeoColumn>
        }
      </NeoCard>

      <MediaStatisticsTable
        mediaId={mediaId}
        fileTypeId={fileTypeId}
        mediaName={mediaName}
        value={statisticsData?.STORES}
      />
    </>
  );
}
