import { useLooker } from "../../hooks/useLooker.hook";
import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
import Logo from "../../design/assets/img/wfi/dashboard_audiencias.png";
import { CONST } from '../../consts/consts';
import useToastContext from '../../hooks/useToastContext.hook';
import { getDataLookerDashboard } from '../../service/Looker.service';
import ExcelJs from "exceljs";
import { dateFormat } from '../../utils/date.utils';
import { auth } from "../../config/firebase.config";

import DoNotRefreshPageDialog from "./components/DoNotRefreshPageDialog.component";

const DemographicInformationView = ({ 

  dateRange,
  apIds,
  displayTitle,
  displayDownloadButton,
  onInit,
}) => {
  useEffect(() => {
    if (onInit) {
      onInit();
    }
  });

  //console.time("renderTime");
  const [loadingExcel, setLoadingExcel] = useState(false); // Estado para el spinner en el botón de descarga
  // Obtener la URL del tablero de control general
  const { url } = useLooker("14");


  const [modifiedUrl, setModifiedUrl] = useState(null);
  const [shouldRenderIframe, setShouldRenderIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  // Función para formatear las fechas enviadas al iframe
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Usar toast para indicar el error
  const toast = useToastContext();


  // Descarga de datos del tablero
  async function crearExcel() {
    setLoadingExcel(true); // Activa el spinner
    let dataLooker;
    const startDate = formatDate(dateRange.from);
    const endDate = formatDate(dateRange.to);
    const dashboardType = 14;

    dataLooker = await getDataLookerDashboard(startDate, endDate, apIds, dashboardType);
    //console.log('dataLooker:', dataLooker); 
    if (!dataLooker.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
      setLoading(false);
    } else {
   
      const workbook = new ExcelJs.Workbook();
      const worksheet = workbook.addWorksheet();


    //const timeZone = 'America/Mexico_City';
    // Definir encabezados
    const currentHeaders = [
      { header: 'sucursal', key: 'store' },
      { header: 'latitud', key: 'latitude' },
      { header: 'longitud', key: 'longitude' },
      { header: 'zona', key: 'zone' },
      { header: 'departamento', key: 'department' },
      { header: 'es_anonima', key: 'is_anonymous' },
      { header: 'es_conexion', key: 'is_connection' },
      { header: 'es_visita', key: 'is_visit' },
      { header: 'fecha', key: 'created_at' },
      { header: 'ap_nombre', key: 'ap_name' },
      { header: 'contacto_id', key: 'contact_id' },
      { header: 'contacto_nombre', key: 'contacto' },
      { header: 'contacto_email', key: 'email' },
      { header: 'contacto_telefono', key: 'phone' },
      { header: 'genero', key: 'gender' },
      { header: 'nacimiento', key: 'birthdate' },
      { header: 'edad', key: 'edad_calculada' },
      ];
    worksheet.columns = currentHeaders;
    //const dataArray = Array.isArray(dataLooker.result[0]) ? dataLooker.result[0] : Object.values(dataLooker.result[0]);
      
    dataLooker.result.forEach(row => {
      //const createdAtValue = row.created_at ? row.created_at.value : '';
      worksheet.addRow({
        store: row.store,
        latitude: row.latitude,
        longitude: row.longitude,
        zone: row.zone,
        department: row.department,
        is_anonymous: row.is_anonymous,
        is_connection: row.is_connection,
        is_visit: row.is_visit,
        created_at: row.created_at.value,
        ap_name: row.ap_name,
        contact_id: row.contact_id,
        contacto: row.contacto,
        email: row.email,
        phone: row.phone,
        gender: row.gender,
        birthdate: row.birthdate ? row.birthdate.value : '',
        edad_calculada: row.edad_calculada,
        });
      });
   await workbook.csv.writeBuffer().then((data) => {
        const blob = new Blob(["\ufeff" + data], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${dateFormat(Date.now())}_audiencias_informacion_demografica.csv`);
        link.click();
        setLoadingExcel(false); // Desactiva el spinner
      },
    );
  }
}
  useEffect(() => {
    if (
      url &&
      dateRange?.from !== null &&
      dateRange?.to !== null &&
      dateRange?.to !== "null"
    ) {
      const { from, to } = dateRange;
      const params = {
        startDate: formatDate(from),
        endDate: formatDate(to),
        apIds:apIds,
        userId:auth.currentUser.uid
      };

      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString);
      const urlWithAdditionalText = `${url}?params=${encodedParams}`;

      //console.log("URL con parámetros --------!!!!!!!:", urlWithAdditionalText);
      setModifiedUrl(urlWithAdditionalText);
    } else {
      setShouldRenderIframe(false); 
    }
  }, [url, dateRange]);

  return (
    <>
   

    
      <div className="statistics-title-download">
        {displayTitle && (
          <>
            <img src={Logo} alt="Generales" className="title-image" />
            Audiencias | Información demográfica
          </>
        )}
        {displayDownloadButton && (
          <>
            <NeoButtonSection align="right">
              <NeoButtonMain
                label={loadingExcel ? "Cargando..." : "Descargar registros"}
                icon={loadingExcel ? "" : "pi pi-download"}
                onClick={crearExcel}
                disabled={loadingExcel} // Desactiva el botón mientras se carga
                className="button-with-spinner"
              />
            </NeoButtonSection>
          </>
        )}
      </div>

      {loading && <div className="loader">
       <ProgressSpinner
          style={{ width: "45px", height: "45px", margin: "10px" }}
          className="custom-spinner" 
          />Cargando información...</div>} 
          <iframe
            title="Información demográfica"
            src={modifiedUrl}
            allowFullScreen
            onLoad={() => {
              setLoading(false);
          }}
        />
      <DoNotRefreshPageDialog open={loadingExcel} />
    </>
  );
};


export default DemographicInformationView;

