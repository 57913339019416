import React from "react";

export default function Email(props) {
  return (
    <>
      <div className="sp-field sp-block">
        <label htmlFor={"email"}>{props.label} </label>
        <input type="email" id="email" name="email" />
      </div>
    </>
  );
}
