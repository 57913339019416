import React from "react";

export default function Logo(props) {
  return (
    <>
      <p>El logotipo se modifica en la sección de configuración</p>
      <br />
      <p>¿Desea ir al panel de configuración?</p>
      <strong>Se perderá todos los cambios realizados</strong>
    </>
  );
}
