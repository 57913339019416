import React from "react";
import NeoInputText from "../../../../../../design/design_components/neo/form/NeoInputText.base";

export default function Submit(props) {
  return (
    <>
      <NeoInputText
        value={props.label}
        label={"Texto del botón"}
        onChange={(e) => props.setLabel(e.target.value)}
      />
    </>
  );
}
