import React from "react";

export default function TextField(props) {
  return (
    <>
      <div className="sp-field sp-block">

        <label htmlFor={props.label}>{props.label}</label>

        <input type="text" id={"FullName"} name={"FullName"} />
      </div>
    </>
  );
}
