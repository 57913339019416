import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { getUserStatus } from "../../service/User.service";
import Routes from "../../routers/Routes.router.json";

export default function PrivateRoute({ component: Component, roles, ...rest }) {
  const [deals, setDeals] = useState(null);

  useEffect(() => {
    let mount = true;

    if (mount) {
      getDeals();
    }
    return () => {
      mount = false;
    };
  }, []);

  const getDeals = async () => {
    setDeals((await getUserStatus()).result);
  };

  function toRender(props) {
    if (deals && !deals.apsConfigured) {
      return (
        <Redirect
          to={{
            pathname: Routes.deals.shippingConfiguration,
            state: { from: props.location },
          }}
        />
      );
    } else {
      return <Component {...props} />;
    }
  }

  return <Route {...rest} render={(props) => toRender(props)} />;
}
