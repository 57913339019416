const isEmptyTheState = (data) => {
  const newData = Object.entries(data);
  let isEmpty = false;
  newData.forEach((prop) => {
    if (!prop[1]) {
      isEmpty = true;
    }
  });
  return isEmpty;
};

const validateMail = (value) => {
  const formato =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let itIsValid = true;
  let message = null;

  if (!formato.test(value)) {
    message = "El correo no tiene formato válido";
    itIsValid = false;
  }

  return { itIsValid: itIsValid, message: message };
};

const validateExistence = (value) => {
  let itIsValid = true;
  let message = null;

  if (
    value === undefined ||
    value === "" ||
    value === false ||
    value === null
  ) {
    message = "Este campo es necesario";
    itIsValid = false;
  }

  return { itIsValid: itIsValid, message: message };
};

const mxCurrencyFormatter = new Intl.NumberFormat("es-MX", {
  style: "currency",
  currency: "MXN",
});

const addressDataFormatting = ({
  country = "México",
  postalCode = "",
  state = "",
  city = "",
  street = "",
  internalNumber = "",
  colony = "",
}) => {
  if (!country) return;
  if (!postalCode) return;
  if (!state) return;
  if (!city) return;
  if (!street) return;
  if (!colony) return;
  return `${street} ${
    internalNumber ? internalNumber : ""
  }, ${colony}, ${postalCode} ${city}, ${state}, ${country}`;
};

const filterRangeNumber = (value, filter) => {
  if (!filter[0] && !filter[1]) {
    return true;
  }

  if (filter[0] && !filter[1]) {
    return value >= filter[0];
  }

  if (!(filter[0] && filter[1])) {
    return value <= filter[1];
  }

  return filter[0] <= value && filter[1] >= value;
};
function isValidUrl(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export {
  isEmptyTheState,
  validateMail,
  validateExistence,
  mxCurrencyFormatter,
  addressDataFormatting,
  filterRangeNumber,
  isValidUrl,
};
