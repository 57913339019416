import React from 'react';
import ContactsTable from './components/ContactsTable.component';
import NeoGridContainer from '../../design/design_components/neo/layout/NeoGridContainer.base'
import NeoTitleMain from '../../design/design_components/neo/title/NeoTitleMain.base'
import NeoButtonSection from '../../design/design_components/neo/layout/NeoButtonSection.base'
import ExportContactButton from './components/ExportContactButton.component';
import RevalidateContactsButton from './components/RevalidateContactsButton.component';
import NeoColumn from '../../design/design_components/neo/layout/NeoColumn.base';
import Icono from '../../design/assets/img/wfi/icons/icon-contacts.svg';

export default function Contacts() {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain title="Contactos" icon={Icono} />

        {/* <NeoButtonSection align="right">
          <RevalidateContactsButton />

          <ExportContactButton />
        </NeoButtonSection> */}
      </NeoGridContainer>

      <ContactsTable />
    </>
  );
}
