import React, { useEffect, useState } from "react";
import BaseCalendar, {
  baseApiAddLocale,
} from "../../sisu/form/BaseCalendar.sisu";

baseApiAddLocale("es", {
  firstDayOfWeek: 1,
  dayNames: [
    "Domingo",
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
  ],
  dayNamesShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
  dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
  monthNamesShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  today: "Hoy",
  clear: "Limpiar",
});

const NeoCalendar = (props) => {
  const [style, setStyle] = useState("");

  useEffect(() => {
    let mount = true;
    if (mount) {
      if (props.error) {
        setStyle(`${props.className} p-invalid p-mr-0`);
      } else {
        setStyle(props.className);
      }
    }
    return () => {
      mount = false;
    };
  }, [props.error, props.className]);

  return (
    <>
      <div
        className={`p-field p-col 
                ${props.extra ? props.extra : ""}
                ${props.col ? `p-col-${props.col}` : `p-col-12`}
                ${props.md ? `p-md-${props.md}` : ""}
                ${props.sm ? `p-sm-${props.sm}` : ""}`}
      >
        <span className="p-float-label">
          <BaseCalendar {...props} className={style} locale="es" />
          <label htmlFor={props.id}>{props.label}</label>
          {props.error ? (
            <small className="p-invalid p-error p-d-block">{props.error}</small>
          ) : null}
          {props.children}
        </span>
      </div>
    </>
  );
};

export default NeoCalendar;
