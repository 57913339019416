import React from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import EmailsTemplateTable from "./components/EmailsTemplateTable.component";
import Icono from '../../design/assets/img/wfi/icons/icon-mails.svg';

export default function MailingList() {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain
          title="Notificaciones de correo eléctronico"
          icon={Icono}
        />
      </NeoGridContainer>
      <EmailsTemplateTable />
    </>
  );
}
