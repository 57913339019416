import { auth } from "../config/firebase.config";
import { CONST } from "../consts/consts";
import BACKEND from '../consts/backend.consts';

export async function getVisits (body) {
  console.log(body)
  const response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: auth.currentUser.uid,
    },
    body: JSON.stringify(body),
  };

  let result;
  try {
    result = await fetch(
      `${BACKEND.BASE_URL}/api/v2/visit`,
      requestOptions
    );
    if (!result.ok) {
      response.message = (await result.json()).details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  response.result = (await result.json()).result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
};

export async function getVisit (visitId) {
  const response = { ...CONST.RESPONSE_BASIC_STRUCTURE, result: null };
  const requestOptions = {
    method: "GET",
    headers: {
      Authorization: auth.currentUser.uid,
    },
  };

  let result;
  try {
    result = await fetch(
      `${BACKEND.BASE_URL}/api/v2/visit/${visitId}`,
      requestOptions
    );
    if (!result.ok) {
      response.message = (await result.json()).details;
      throw new Error();
    }
  } catch (error) {
    return response;
  }

  response.result = (await result.json()).result;
  response.success = true;
  response.message = CONST.MESSAGES.SUCCESS;
  return response;
}
