import { useState } from "react";
import NeoButtonMain from "../design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "../design/design_components/neo/button/NeoButtonOutlined.base";
import NeoDropDownFilter from "../design/design_components/neo/form/NeoDropDownFilter.base";

export default function useModalBlock(
  blockFunction,
  cancelModalBlockFunction,
  setModalVisibilityExpected,
  optionsDropdown,
  notDialog
) {
  const [dropDownValue, setDropDownValue] = useState(null);

  const handleCancelBlock = () => {
    cancelModalBlockFunction();
    setDropDownValue(null);
    setModalVisibilityExpected(false);
  };

  const handleSubmit = () => {
    if (dropDownValue != null || notDialog) {
      blockFunction(dropDownValue);
      setDropDownValue(null);
      setModalVisibilityExpected(false);
    }
  };

  const ElementDropdown = () => {
    return (
      <NeoDropDownFilter
        value={dropDownValue}
        options={optionsDropdown}
        onChange={(e) => setDropDownValue(e)}
        optionValue={"label"}
        optionLabel={"label"}
        optionGroupLabel={"label"}
        optionGroupChildren={"items"}
        placeholder={"Selecciona el elemento"}
        optionDisabled={"inUse"}
      />
    );
  };

  const footerModal = (
    <>
      <NeoButtonOutlined label="Cancelar" onClick={handleCancelBlock} />
      <NeoButtonMain label={"Aceptar"} onClick={handleSubmit} />
    </>
  );

  return [dropDownValue, ElementDropdown, footerModal];
}
