import React from "react";
import image from '../../design/assets/img/wfi/success-buy-send.png';
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoFlexContainer from "../../design/design_components/neo/layout/NeoFlexContainer.base";

export default function Shipping() {
  return <>



    <NeoFlexContainer jc="center" ai="center">
      <NeoColumn md="6" extra="text-align-center">
        <div className="   p-mb-5">
          <h1>¡Gracias por contratarnos!</h1>
          <h2>Estamos preparando el envío de tus Access Points, te enviaremos un correo con toda la información</h2>
        </div>
        <img width="500" src={image} />
      </NeoColumn>
    </NeoFlexContainer>




  </>
}
