import React from "react";

export default function Contract() {
  return (
    <>
      <div title="Page 1">
        <div>
          <p>
            <strong>
              MUY IMPORTANTE: POR FAVOR, LEA EN PRIMER LUGAR EL PRESENTE
              DOCUMENTO
            </strong>
          </p>
          <p>
            Wolters Kluwer Tax and Accountig España, S.L., con N.I.F. nº
            B05430756, y domicilio social en Paseo de la Castellana nº 93,
            28046, Madrid (en adelante “WK TAA”) es titular de los derechos de
            explotación del programa de ordenador cuyas funcionalidades están
            descritas en la "Ficha" ubicada en{" "}
            <a href="https://www.wolterskluwer.com/es-es/solutions/a3">
              https://www.wolterskluwer.com/es-es/solutions/a3
            </a>
          </p>
          <p>
            <strong>CLÁUSULAS:</strong>
          </p>

          <p>
            <strong>PRIMERA.- OBJETO.</strong>
          </p>
          <p>
            WK TAA otorga al CLIENTE una licencia de uso del presente producto,
            personal, revocable, intransferible, no exclusiva y no
            sublicenciable especificada en el encabezado del contrato según las
            características establecidas en la “Ficha del producto o
            Aplicación”, disponible a través de la Web{" "}
            <a href="https://www.wolterskluwer.com/es-es/solutions/a3">
              https://www.wolterskluwer.com/es-es/solutions/a3
            </a>
            , (en adelante la “APLICACIÓN”). Se excluye de la presente licencia
            cualquier funcionalidad no definida en la ficha de la APLICACIÓN.
            Todos los derechos no otorgados al CLIENTE de forma explícita, se
            los reservará WK TAA y sus licenciatarios en los casos en que esto
            aplique. WK TAA podrá ofrecer dos tipos de modalidad de
            aplicaciones: “On premise” y/o por conexión remota/web y/ modelo
            “Cloud”.
          </p>
          <p>
            Asimismo, el presente contrato determina las condiciones
            establecidas para el desarrollo de la prestación de servicios de
            mantenimiento que WK TAA presta al CLIENTE según los servicios
            relacionados en la cláusula TERCERA y que se especifican en la
            factura correspondiente y para el número de usuarios indicados en la
            misma.
          </p>
          <p>
            Hemos redactado esta licencia de uso de software y prestación de
            servicios de mantenimiento de las aplicaciones de WK TAA, donde se
            regulan las condiciones de uso de la APLICACIÓN (a las que llamamos
            “Condiciones”), con el fin de que conozca las reglas que rigen
            nuestra relación con usted. Estas Condiciones constituyen un
            contrato legal vinculante entre usted (en adelante “CLIENTE”) y WK
            TAA, así como otras entidades que forman parte de la relación
            contractual. El presente documento regula las condiciones de uso de
            la APLICACIÓN y su aceptación expresa es necesaria para la puesta en
            marcha del servicio.
          </p>
        </div>
        <div>
          <p>
            <strong>SEGUNDA. - GESTIÓN DE ACCESOS</strong>
          </p>
          <p>
            La licencia permite al CLIENTE, a través de los perfiles de usuarios
            designados acceder y editar los accesos al entorno de la Aplicación.
            A estos efectos se pueden distinguir los siguientes perfiles según
            el grado de control y acceso a la APLICACIÓN:{" "}
          </p>

          <p>
            <u>ADMINISTRADOR: </u>Persona física nombrada y facultada por el
            CLIENTE al objeto de gestionar todos los términos y condiciones del
            Contrato, lo cual incluirá, sin ánimo limitativo, la aceptación de
            nuevos términos y condiciones, la creación de nuevos Usuarios, la
            solicitud de Servicios y la activación de servicios o módulos
            adicionales.
          </p>

          <p>
            <u>USUARIO: </u>Persona física autorizada por el CLIENTE para
            utilizar la Aplicación, incluido el Administrador. Normalmente serán
            empleados o colaboradores del CLIENTE.
          </p>

          <p>
            <u>COLABORADOR: </u>Persona física autorizada por el Administrador
            para utilizar un acceso especial para colaboradores que permite el
            acceso a partes de la Aplicación Servicio web o a servicios
            o/módulos concretos. Normalmente serán CLIENTEs del CLIENTE.
          </p>

          <p>
            Si el número de Usuarios o de Colaboradores no se correspondiese con
            el correspondiente a la suscripción de la Licencia contratada por el
            CLIENTE, el Proveedor de servicios podrá cobrar al CLIENTE por los
            accesos adicionales.
          </p>

          <p>
            El acceso a la APLICACIÓN se realizará de la siguiente manera según
            la APLICACIÓN contratada:
            <br />
            - A través de la Cuenta Wolters Kluwer de WK TAA: La Cuenta Wolters
            Kluwer permitirá acceder a la APLICACIÓN mediante el mail y
            contraseña debidamente habilitados a tal efecto. La creación de la
            Cuenta Wolters Kluwer TAA estará sujeta a términos y condiciones
            particulares.
            <br />- A través de un código de usuario y/o mail y una contraseña
            de acceso. de carácter personal e intransferibles.{" "}
          </p>
        </div>
        <div>
          <p>
            <strong>
              TERCERA.- MÓDULOS ADICIONALES Y SERVICIOS DE TERCEROS
            </strong>
          </p>

          <p>
            Para el supuesto en el que el CLIENTE, por circunstancias futuras y
            debido a las nuevas necesidades que le surjan con el uso, desee
            contratar cualquier módulo adicional de WK TAA vinculado a la
            APLICACIÓN, además de facturarse el mismo según las tarifas vigentes
            en ese momento, a su utilización le serán de aplicación todas las
            cláusulas contenidas en el presente contrato, atendiendo a la
            modalidad “On Premise” y/o “Cloud”.
          </p>

          <p>
            En el caso de que la APLICACIÓN incluya una utilidad para facilitar
            la migración de los datos desde una anterior aplicación, WK TAA no
            se responsabilizará ni garantizará el traspaso íntegro y exacto de
            los datos. Su misión es exclusivamente automatizar parte del proceso
            de migración de datos, por lo tanto, esta utilidad en ningún caso
            podrá ser utilizada para realizar enlaces, traspasos o migraciones
            de forma periódica desde otras aplicaciones.
          </p>

          <p>
            WK TAA podrá poner a disposición del CLIENTE interfaces de
            programación de aplicaciones (en adelante, “APIs”) mediante las que
            el CLIENTE podrá integrar aplicaciones y herramientas de terceros
            ajenos a WK TAA en la APLICACIÓN.
          </p>

          <p>
            El uso de las integraciones, aplicaciones y servicios de terceros
            mediante APIs se regirá, en su caso, por los propios términos y
            condiciones del tercero proveedor de dicha API, y podrán ser
            directamente facturados por el Proveedor de dicha API. El CLIENTE
            manifiesta y garantiza que leerá y, en caso de estar conforme,
            aceptará dichos términos y condiciones conforme a su único y
            exclusivo criterio y responsabilidad.
          </p>

          <p>
            WK TAA no ofrece ninguna garantía de ningún tipo con respecto a las
            integraciones, aplicaciones y/o servicios de terceros o a su
            rendimiento, ya sea expresa, implícita, estatutaria o de otro tipo,
            incluyendo, sin limitación, cualquier garantía de título,
            comerciabilidad, no infracción o idoneidad para un propósito
            particular, todas las cuales se rechazan por el presente documento
            en la medida máxima permitida por la ley.
          </p>

          <p>
            EL CLIENTE reconoce y manifiesta que el uso de integraciones,
            aplicaciones y/o servicios de terceros vía API no son ni pueden ser,
            en ningún caso, considerados responsabilidad de WK TAA. En este
            sentido, el CLIENTE reconoce y manifiesta que no tiene nada que
            reclamar a WK TAA por estos conceptos y que utilizará las
            integraciones, aplicaciones y/o servicios de terceros a través de la
            API en la APLICACIÓN bajo su única y exclusiva responsabilidad.
          </p>
        </div>
        <div>
          <p>
            <strong>CUARTA.- CONTENIDO DEL SERVICIO.</strong>
          </p>

          <p>
            El servicio contratado por el CLIENTE incluirá un acceso a las
            aplicaciones/módulos y usuarios contratados, de acuerdo a las
            especificaciones técnicas que se describen en el documento
            denominado “Requisitos Mínimos” ubicado en la página Web:{" "}
            <a href="https://www.wolterskluwer.com/es-es/solutions/a3/requisitos-minimos-software">
              https://www.wolterskluwer.com/es-es/solutions/a3/requisitos-minimos-software
            </a>
            . mediante la utilización de una clave de acceso y contraseña, para
            cada uno de los usuarios.
          </p>

          <p>
            WK TAA ofrece unos servicios generales y asociados al uso de la
            APLICACIÓN según la modalidad contratada. Si el Mantenimiento
            contratado incluye servicio de soporte podrá acceder al mismo dentro
            del calendario laboral de WK TAA.{" "}
          </p>

          <p>
            <strong>Soporte Estándar:</strong>
          </p>

          <p>
            - Actualización periódica de la APLICACIÓN a la normativa vigente
            y/o nuevas versiones mejoradas a través de nuevas versiones, de
            acuerdo a las garantías definidas en la cláusula quinta.
          </p>
          <p>
            - Acceso ilimitado a información actualizada, comunicados y
            notificaciones de WK TAA a través de a3responde.
          </p>
          <p>- Solicitudes de soporte desde la web y la aplicación.</p>
          <p>
            - Atención telefónica para gestión de incidencias y soporte dentro
            de horas hábiles de trabajo, según el calendario laboral de WK TAA,
            y con los límites establecidos para el Soporte Estándar.
          </p>

          <p>
            <strong>
              Soporte Excellence además de los servicios del Soporte Estándar
              incluirá:
            </strong>
          </p>
          <p>- Priorización de consultas.</p>
          <p>- Gestión centralizada.</p>
          <p>- Horario de atención ampliada.</p>
          <p>- Seguimiento de la cuenta.</p>
          <p>- Bolsa de 25 horas anuales.</p>
          <p>- Círculo de Excelencia.</p>

          <p>
            <strong>Servicios SMART:</strong>
          </p>

          <p>
            WK TAA ofrece servicios adicionales, que serán facturados de forma
            independiente, entre los que se pueden encontrar los siguientes:
            Círculo de Excelencia, Programa de Certificación, Bolsa de horas
            para fórmulas, Bolsa de horas tecnológicas, Servicio tecnológico de
            mantenimiento preventivo, Consultoría tecnológica, Servicio de
            acompañamiento activo, Horario de atención personalizado,
            Desarrollos a medida, a3Implanta, Formación, Consultoría. Los
            Servicios SMART podrán estar sujetos a condiciones particulares.{" "}
          </p>

          <p>
            Para más información sobre el Soporte y los Servicios ofrecidos por
            WK TAA, puede consultar el siguiente link{" "}
            <a href="https://www.wolterskluwer.com/es-es/solutions/servicios">
              https://www.wolterskluwer.com/es-es/solutions/servicios
            </a>
          </p>

          <p>
            Si el Servicio de Soporte lo presta un distribuidor y/o colaborador
            de WK TAA, el CLIENTE y el distribuidor y/o colaborador pactarán sus
            propias condiciones relativas al servicio.
          </p>

          <p>
            Los servicios recogidos en la presente cláusula asociados a la
            APLICACIÓN podrán ser prestados por terceros colaboradores con los
            que WK TAA tiene suscrito el correspondiente contrato y que
            garantizan la calidad del servicio según los estándares de WK TAA.
          </p>

          <p>
            Cualquier servicio no incluido en esta cláusula o en los links
            aclaratorios sobre los mismos quedan fuera del marco de este
            contrato y de sus condiciones y podrá suponer un incremento en los
            costes asociados a los servicios del presente contrato.
          </p>

          <p>
            Entre otros, no se encuentran incluidos los siguientes servicios: a)
            Servicios relacionados con la configuración de hardware y redes,
            b)Trabajo de definición de vistas, reportes, fórmulas, graficas,
            definición de importaciones o conexiones con software de terceros,
            c) Soporte in situ, d) Conversión de ficheros o recuperación y/o
            importación de copias de seguridad, e) Configuración, formación y
            cualquiera de las actividades ofrecidas a través del catálogo de
            servicios, f) Soporte para la operación de otros softwares,
            incluidos los de terceras partes que se puedan lanzar desde la
            aplicación o con los que conecte la aplicación (Ejemplo: portales
            administraciones públicas), g) Reparación de ficheros dañados por
            causas no atribuibles a la aplicación, h) El aprovisionamiento de
            nuevos productos, i) Soporte a la conectividad a Internet, j)
            Soporte a dispositivos que no cumplan los requisitos mínimos
            establecidos para la aplicación, k) Análisis e interpretación de las
            discrepancias que pudieran producirse por diferencias entre la
            información introducida en las aplicaciones y los organismos
            oficiales, l) Asistencia remota con control por parte del técnico,
            m)La asistencia sin haber realizado los procesos previos para la
            determinación del origen de la incidencia y haber consultado la
            documentación en línea existente para su resolución, n) Las acciones
            previas necesarias para la identificación del origen de incidencia
            y/o cuadre con los modelos oficiales, o) Asesoramiento Legal.
          </p>

          <p>
            El CLIENTE acepta que el servicio Posventa ofrecido por WK TAA podrá
            verse sometido a circunstancias aleatorias que retrasen el Servicio.
            WK TAA no garantiza ni se compromete a cumplir con ningún plazo
            determinado de respuesta y podrá limitar o negar el servicio si se
            determina, siguiendo un criterio razonable, que se está actuando de
            una manera que implique el uso indebido del servicio o estén fuera
            del ámbito del mismo.{" "}
          </p>
        </div>
      </div>
    </>
  );
}
