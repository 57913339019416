import React from "react";
import NeoInputText from "../../../../../../design/design_components/neo/form/NeoInputText.base";

export default function LastName(props) {
  return (
    <>
      <NeoInputText
        value={props.label}
        label={"Etiqueta"}
        onChange={(e) => props.setLabel(e.target.value)}
      />
    </>
  );
}
