import React, { useEffect, useState, useReducer } from "react";
import { useParams } from "react-router-dom";
import { CONST } from "../../../consts/consts";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoSelectButton from "../../../design/design_components/neo/button/NeoSelectButton.base";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import NeoInnerTitle from "../../../design/design_components/neo/title/NeoInnerTitle.base";
import useToastContext from "../../../hooks/useToastContext.hook";
import {
  getSplashPage,
  postSplashPage,
} from "../../../service/SplashPages.service";
import ScreenContainer from "./ScreenContainer.component";
import optionsReduce from "../../../utils/elementsDropdownReducer.util";

export default function ContentSplashPageDetail() {
  const id = useParams();
  const toast = useToastContext();
  const [screenType, setScreenType] = useState("welcome");
  const [stateGlobalScreens, setStateGlobalScreens] = useState({
    welcome: {
      children: [],
      id: 1,
    },
    connect: {
      children: [],
      id: 2,
    },
    welcomeBack: {
      children: [],
      id: 3,
    },
  });
  const [optionsDropdown, dispatch] = useReducer(optionsReduce);

  useEffect(() => {
    let mounted = true;
    setSplashPages();
    return () => {
      mounted = false;
    };
  }, []);

  const setSplashPages = async () => {
    let stateScreensResult = { ...stateGlobalScreens };
    let splashpages = await getSplashPage(id.id);
    splashpages.result.map((sp) => {
      switch (sp.TYPE_SPLASH_PAGE_ID) {
        case 1:
          stateScreensResult.welcome = {
            ...stateScreensResult.welcome,
            ...JSON.parse(sp.ELEMENTS),
          };
          break;
        case 2:
          stateScreensResult.connect = {
            ...stateScreensResult.connect,
            ...JSON.parse(sp.ELEMENTS),
          };
          break;

        case 3:
          stateScreensResult.welcomeBack = {
            ...stateScreensResult.welcomeBack,
            ...JSON.parse(sp.ELEMENTS),
          };
          break;

        default:
          break;
      }
    });
    changeStateOption(stateScreensResult[screenType].children);
    setStateGlobalScreens(stateScreensResult);
  };

  const screenNavigationOptions = [
    { name: "Pantalla de registro", value: "welcome" },
    { name: "Pantalla de bienvenida", value: "connect" },
    { name: "Pantalla de bienvenida de nuevo", value: "welcomeBack" },
  ];

  const changeStateOption = (listElement) => {
    dispatch({ type: "reset" });
    listElement.map((child) => {
      if (child.unique) {
        dispatch({
          type: "disableOption",
          idOption: child.id,
        });
      }
    });
  };

  const handleSelection = (value) => {
    if (value !== null) {
      setScreenType(value);
      changeStateOption(stateGlobalScreens[value].children);
      if (value !== "welcome") dispatch({ type: "disableOptionForm" });
    }
  };

  const handleSubmit = async () => {
    let submit = await postSplashPage({
      ...stateGlobalScreens,
      splashPagesGroupId: id.id,
    });

    if (submit.success) {
      toast.setMessage(
        CONST.SEVERITY.SUCCESS,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
        CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
      );
    } else {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    }
  };

  return (
    <NeoGridContainer>
      <NeoColumn col="12"  >
        <NeoCard >
          <NeoInnerTitle extra="primero">1. Elige la splash page que vas a editar</NeoInnerTitle>
          <NeoColumn col="12" extra="p-text-center" >
            <NeoSelectButton
              value={screenType}
              options={screenNavigationOptions}
              onChange={(e) => handleSelection(e.value)}
              optionLabel="name"
            />
          </NeoColumn>

          <NeoInnerTitle >2. Usa el editor para ajustar lo que verán tus clientes </NeoInnerTitle>

          <ScreenContainer
            stateScreen={stateGlobalScreens[screenType]}
            stateGlobal={stateGlobalScreens}
            setStateGlobalScreens={setStateGlobalScreens}
            screenType={screenType}
            optionsDropdown={optionsDropdown}
            dispatch={dispatch}
          />
        </NeoCard>
      </NeoColumn>
      <NeoButtonSection align="right">
        <NeoButtonMain label="Guardar grupo" onClick={handleSubmit} />
      </NeoButtonSection>
    </NeoGridContainer>
  );
}
