import React from "react";
import NeoInputTextarea from "../../../../../../design/design_components/neo/form/NeoInputTextarea.base";

export default function NormalText(props) {
  return (
    <>
      <NeoInputTextarea
        value={props.text}
        label={"Contenido del texto"}
        onChange={(e) => props.setText(e.target.value)}
        rows={5}
        cols={30}
        maxLength={150}
      />
    </>
  );
}
