import React from 'react'
import NeoGridContainer from '../../design/design_components/neo/layout/NeoGridContainer.base'
import NeoTitleMain from '../../design/design_components/neo/title/NeoTitleMain.base'
import FormDeals from './components/FormDeals.component'
import NeoSingleContainer from '../../design/design_components/neo/layout/NeoSingleContainer.base'
import NeoCard from '../../design/design_components/neo/panel/NeoCard.base'
import NeoColumn from '../../design/design_components/neo/layout/NeoColumn.base'
import LogoDeal from "./components/LogoDeal.component";

export default function Deals({ location }) {
  return (
    <>
      <NeoSingleContainer>
        <NeoGridContainer col="12" extra="h-100" subExtra="h-100">
          <LogoDeal />
          <NeoColumn md="10" lg="6" extra="p-md-offset-1 p-lg-offset-3  h-100">
            <NeoCard >
              <NeoTitleMain title="Contrata NeoWiFi" extra="p-col-12" />
              <FormDeals location={location} />
            </NeoCard>
          </NeoColumn>
        </NeoGridContainer>
      </NeoSingleContainer >
    </>
  );
}
