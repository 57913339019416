import React, { useState } from "react";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoButtonOutlined from "../../../design/design_components/neo/button/NeoButtonOutlined.base";
import ElementContainer from "./ElementContainer.component";
import BlockAdd from "./BlockAdd.component";
import BlockEdit from "./BlockEdit.component";
import {
  ELEMENTS_COMPONENTS,
  GO_TO_SETTINGS_IDS,
} from "../../../consts/splashPages.consts";
import { useHistory } from "react-router-dom";

export default function ScreenContainer({
  stateScreen,
  stateGlobal,
  setStateGlobalScreens,
  screenType,
  optionsDropdown,
  dispatch,
}) {
  const [modalEditBlockVisibility, setModalEditBlockVisibility] =
    useState(false);
  const [
    modalElementTypeSelectionVisibility,
    setModalElementTypeSelectionVisibility,
  ] = useState(false);
  const [
    modalConfirmDeleteBlockVisibility,
    setModalConfirmDeleteBlockVisibility,
  ] = useState(false);

  const history = useHistory();

  const [currentValue, setCurrentValue] = useState({
    propsChild: { label: "" },
  });
  const [currentKeyComponent, setCurrentKeyComponent] = useState(0);

  const reorderElements = (newArrayOfChildren) => {
    let newChildrens = newArrayOfChildren.map((child, childIndex) => ({
      ...child,
      key: childIndex,
    }));

    let newStateGlobal = { ...stateScreen };
    newStateGlobal.children = newChildrens;

    setStateGlobalScreens({
      ...stateGlobal,
      [screenType]: newStateGlobal,
    });
  };

  const moveElement = (side, key) => {
    let newPosition = key + side;
    let arrayOfChildrens = [...stateScreen.children];

    if (newPosition >= 0 && newPosition < arrayOfChildrens.length) {
      let temp = arrayOfChildrens[key];
      arrayOfChildrens[key] = arrayOfChildrens[newPosition];
      arrayOfChildrens[newPosition] = temp;
      reorderElements(arrayOfChildrens);
    }
  };

  const deleteElement = () => {
    let newStateScreen = { ...stateScreen };
    newStateScreen.children.splice(
      newStateScreen.children.findIndex(
        (child) => child.key === currentKeyComponent
      ),
      1
    );
    if (currentValue.unique) {
      dispatch({
        type: "eneableOption",
        idOption: currentValue.id,
      });
    }
    reorderElements(newStateScreen.children);
    setModalConfirmDeleteBlockVisibility(false);
  };

  const handleMoveComponent = (side, key) => {
    if (side === "down") {
      moveElement(1, key);
    } else {
      moveElement(-1, key);
    }
  };

  const handleComponent = (key) => {
    setCurrentValue(stateScreen.children[key]);
    setCurrentKeyComponent(key);
    setModalEditBlockVisibility(true);
  };

  const handleDeleteComponent = (key) => {
    setCurrentValue(stateScreen.children[key]);
    setCurrentKeyComponent(key);
    setModalConfirmDeleteBlockVisibility(true);
  };

  const handleEditBlock = () => {
    if (GO_TO_SETTINGS_IDS.includes(currentValue.id)) {
      history.push("/configuracion");
    }
    setModalEditBlockVisibility(false);
    let newStateScreen = { ...stateScreen };
    newStateScreen.children[currentKeyComponent] = currentValue;
    setStateGlobalScreens({
      ...stateGlobal,
      [screenType]: newStateScreen,
    });
  };

  const footerModalDeleteComponent = (
    <>
      <NeoButtonOutlined
        label="Cancelar"
        onClick={() => setModalConfirmDeleteBlockVisibility(false)}
      />
      <NeoButtonMain label={"Aceptar"} onClick={() => deleteElement()} />
    </>
  );

  const getComponentById = (id) => {
    let elements = [...ELEMENTS_COMPONENTS];
    return elements.find((element) => element.id === id).Component;
  };

  return (
    <>
      <NeoColumn col="6" sm="12" md="7" lg="6">
        <div className="splash-container ">

          {stateScreen.children.map((child, indexKey) => {
            let Component;
            if (child.Component) {
              Component = child.Component;
            } else {
              Component = getComponentById(child.id);
            }
            let propsChild = { ...child.propsChild };
            return (
              <ElementContainer
                key={indexKey}
                editElement={() => handleComponent(indexKey)}
                deleteElement={() => handleDeleteComponent(indexKey)}
                moveDownElement={() => handleMoveComponent("down", indexKey)}
                moveUpElement={() => handleMoveComponent("up", indexKey)}
                locked={child.lock}
              >
                <Component {...propsChild} key={indexKey} />
              </ElementContainer>
            );
          })}
        </div>
      </NeoColumn >
      <NeoColumn col="6" sm="12" md="5" lg="6">
        <p>En este editor puedes:</p>
        <ul>
          <li><strong>Modificar</strong> los textos de tus splash pages</li>
          <li><strong>Agregar</strong> o elimina bloques de texto, o formulario</li>
          <li><strong>Reorganizar</strong> tus bloques</li>
          <li><strong>Previsualizar</strong> como lo veran tus clientes</li>
        </ul>
        <NeoButtonSection jc="end">

          <NeoButtonMain
            label="Agregar nuevo bloque"
            icon="pi pi-plus"
            extra="p-d-block p-mx-auto"
            alternative="secondary"
            onClick={() => setModalElementTypeSelectionVisibility(true)}
          />
        </NeoButtonSection>
      </NeoColumn>


      <BlockEdit
        modalEditBlockVisibility={modalEditBlockVisibility}
        handleEditBlock={handleEditBlock}
        setModalEditBlockVisibility={setModalEditBlockVisibility}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
      />
      <BlockAdd
        stateScreen={stateScreen}
        modalElementTypeSelectionVisibility={
          modalElementTypeSelectionVisibility
        }
        setModalElementTypeSelectionVisibility={
          setModalElementTypeSelectionVisibility
        }
        optionsDropdown={optionsDropdown}
        dispatch={dispatch}
      />
      <NeoDialog
        visible={modalConfirmDeleteBlockVisibility}
        closable={false}
        footer={footerModalDeleteComponent}
      >
        ¿Deseas eliminar este elemento?
      </NeoDialog>
    </>
  );
}
