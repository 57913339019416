export const TYPE = {
  VIDEO: {
    ID: 1,
  },
  IMAGE: {
    ID: 2,
  },
  CUSTOM: {
    ID: 3,
  }
};