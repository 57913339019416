import React, { useEffect, useState } from "react";
import { CONST } from "../../../../../../consts/consts";
import useToastContext from "../../../../../../hooks/useToastContext.hook";
import { getSettings } from "../../../../../../service/Settings.service";

export default function TermAndCondition(props) {
  const [link, setLink] = useState("");
  const toast = useToastContext();

  useEffect(() => {
    let mount = true;

    if (mount) {
      settingLink();
    }

    return () => {
      mount = false;
    };
  }, []);

  const settingLink = async () => {
    let userSettings = await getSettings();
    if (userSettings.success) {
      setLink(userSettings.result.PRIVACY_LINK);
    } else {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        "Ha ocurrido un error",
        "Hubo un error al momento de recuperar su link de terminos de privacidad"
      );
    }
  };
  return (
    <p className="sp-block sp-text sp-terms-block">
      Al usar nuestro servicio de WiFi, confirmas haber leído y estar de acuerdo con nuestros{" "}
      <a href={link}> Términos y Condiciones</a>.
    </p>
  );
}
