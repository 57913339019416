import React from "react";
import { Menubar } from "primereact/menubar";
import LogoImg from "../design/assets/img/wfi/logo.png";
import ApImg from "./AP.png";
import { Panel } from "primereact/panel";
import "./langingPage.scss";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";

export default function LandingPage() {
  const history = useHistory();

  const menu = [
    {
      label: "Iniciar Sesión",
      command: () => {
        history.push("/login");
      },
    },
    {
      label: "Iniciar Sesión SSO",
      command: () => {
        history.push("/logintest");
      },
    },
  ];

  return (
    <>
      <div className="layout-topbar p-mb-3">
        <Menubar
          className="p-jc-between"
          start={<img alt="logo" src={LogoImg} />}
          model={menu}
        ></Menubar>
      </div>
      <div className="p-mb-3">
        <img src={ApImg} alt="AP" className="centerImage"></img>
      </div>

      <div className="p-grid">
        <div className="p-col">
          <Panel header="Paquete 1" className="p-pl-6">
            <h4>1 AP</h4>
            <h4>1000 Validaciones</h4>
            <h4>1000 Emails</h4>
            <br />
            <h4>$ 800 x AP</h4>
            <Button
              label="Contratar ahora"
              onClick={() => history.push("/contratacion/1", { APNumber: 1 })}
            />
          </Panel>
        </div>
        <div className="p-col">
          <Panel header="Paquete 2" className="p-px-1">
            <h4>3 AP</h4>
            <h4>3000 Validaciones</h4>
            <h4>3000 Emails</h4>
            <br />
            <h4>$ 700 x AP</h4>
            <Button
              label="Contratar ahora"
              onClick={() => history.push("/contratacion/3", { APNumber: 3 })}
            />
          </Panel>
        </div>
        <div className="p-col">
          <Panel header="Paquete 3" className="p-pr-6">
            <h4>5 AP</h4>
            <h4>5000 Validaciones</h4>
            <h4>5000 Emails</h4>
            <br />
            <h4>$ 600 x AP</h4>
            <Button
              label="Contratar ahora"
              onClick={() => history.push("/contratacion/5")}
            />
          </Panel>
        </div>
      </div>
    </>
  );
}
