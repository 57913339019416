import React, { useState } from 'react'
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoDropdown from "../../../design/design_components/neo/form/NeoDropdown.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoGridContainer from "../../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoInputTextarea from "../../../design/design_components/neo/form/NeoInputTextarea.base";
import NeoButtonMain from '../../../design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonOutlined from '../../../design/design_components/neo/button/NeoButtonOutlined.base';
import { getPostalCodeInfo } from "../../../service/Deals.service";
import { mapTheInfoCPForDropdown } from "../../../utils/zipCode.utils";
import { isEmptyTheShippingConfigState } from "../../../utils/zipCode.utils";
import NeoInnerSubtitle from '../../../design/design_components/neo/title/NeoInnerSubtitle.base';

const defaultFormStateAddAddress = {
  storeName: "",
  postalCode: "",
  state: "",
  city: "",
  address: "",
  references: "",
  internalNumber: "",
  colony: "",
  receiverName: "",
  apsCounterToSend: "1",
};

export default function NewAddress({
  modalAddAddressVisibility,
  setModalAddAddressVisibility,
  setTableData,
  tableData
}) {

  const [formColonyData, setFormColonyData] = useState([]);
  const [errorRequestPostalCode, setErrorRequestPostalCode] = useState(false);
  const [loadingButtonValidatePostalCode, setLoadingButtonValidatePostalCode] =
    useState(false);
  const [formStateAddAddress, setFormStateAddAddress] = useState({
    ...defaultFormStateAddAddress,
  });

  const handleChangeForm = (e) => {
    const name = e.target ? e.target.name : e.originalEvent.target.name;
    const value = e.value ?? e.target.value;
    setFormStateAddAddress({ ...formStateAddAddress, [name]: value });
  };

  const handleClickValidatePostalCode = async (e) => {
    setLoadingButtonValidatePostalCode(true);
    let response;
    let codePostalInfo;
    try {
      response = await getPostalCodeInfo(formStateAddAddress.postalCode);
      codePostalInfo = response.result;
    } catch (error) {
      setLoadingButtonValidatePostalCode(false);
    }
    if (!codePostalInfo) {
      setErrorRequestPostalCode(true);
      setLoadingButtonValidatePostalCode(false);
      return;
    }
    setErrorRequestPostalCode(false);
    setFormStateAddAddress({
      ...formStateAddAddress,
      state: codePostalInfo.estado,
      city: codePostalInfo.ciudad,
    });
    const codePostalInfoMaped = mapTheInfoCPForDropdown(
      codePostalInfo.asentamiento
    );
    setFormColonyData(codePostalInfoMaped);
    setLoadingButtonValidatePostalCode(false);
  };

  const handleClickSaveAddress = () => {
    if (isEmptyTheShippingConfigState(formStateAddAddress)) {
      return;
    }
    setModalAddAddressVisibility(false);
    const formattedAddress = {
      postalCode: formStateAddAddress.postalCode,
      state: formStateAddAddress.state,
      city: formStateAddAddress.city,
      street: formStateAddAddress.address,
      internalNumber: formStateAddAddress.internalNumber,
      colony: formStateAddAddress.colony,
    };
    setTableData([
      ...tableData,
      {
        position: tableData.length,
        storeName: formStateAddAddress.storeName,
        address: formattedAddress,
        references: formStateAddAddress.references,
        receiverName: formStateAddAddress.receiverName,
        apsCounterToSend: formStateAddAddress.apsCounterToSend,
      },
    ]);
    setFormStateAddAddress({ ...defaultFormStateAddAddress });
    setFormColonyData([])
  };

  const renderFooterModalAddAddress = (
    <>
      <NeoButtonOutlined
        label="Cancelar"
        onClick={() => {
          setFormStateAddAddress({ ...defaultFormStateAddAddress });
          setFormColonyData([]);
          setModalAddAddressVisibility(false);
          setErrorRequestPostalCode(false);
          setLoadingButtonValidatePostalCode(false);
        }}
      />
      <NeoButtonMain
        label="Guardar sucursal"
        disabled={isEmptyTheShippingConfigState(formStateAddAddress)}
        onClick={handleClickSaveAddress}
      />
    </>
  );

  return <NeoDialog
    extra="dialog-m"
    header="Agregar nueva sucursal"
    closable={false}
    visible={modalAddAddressVisibility}
    footer={renderFooterModalAddAddress}
  >
    <NeoGridContainer>
      <NeoColumn col="6">
        <NeoInputText
          label="Nombre de sucursal"
          name="storeName"
          value={formStateAddAddress.storeName}
          onChange={handleChangeForm}
        />
      </NeoColumn>

      <NeoColumn col="6">
        <NeoInputText
          label="Cantidad de APs a enviar a sucursal"
          name="apsCounterToSend"
          disabled
          keyfilter={/^[0-9,$]*$/}
          value={formStateAddAddress.apsCounterToSend}
          onChange={handleChangeForm}
        />
      </NeoColumn>

      <NeoColumn col="12">
        <NeoInputText
          label="Nombre del destinatario del envío"
          name="receiverName"
          value={formStateAddAddress.receiverName}
          onChange={handleChangeForm}
        />
      </NeoColumn>

      <NeoColumn col="12">
        <NeoInnerSubtitle>Dirección de sucursal</NeoInnerSubtitle>
      </NeoColumn>

      <NeoColumn col="8" extra="p-pb-0">
        <NeoInputText
          label="Dirección calle y numero"
          name="address"
          value={formStateAddAddress.address}
          onChange={handleChangeForm}
        />
      </NeoColumn>

      <NeoColumn col="4" extra="p-pb-0">
        <NeoInputText
          label="Número interno (opcional)"
          name="internalNumber"
          keyfilter={/^[0-9,$]*$/}
          value={formStateAddAddress.internalNumber}
          onChange={handleChangeForm}
        />
      </NeoColumn>

      <NeoColumn col="6" extra="p-pb-0">
        <div className="p-d-flex input-btn ">
          <NeoInputText
            col="auto"
            error={errorRequestPostalCode ? " " : ""}
            label="Código postal"
            keyfilter={/^[0-9,$]*$/}
            name="postalCode"
            value={formStateAddAddress.postalCode}
            onChange={handleChangeForm}
          />
          <NeoButtonMain
            label="Validar código postal"
            loading={loadingButtonValidatePostalCode}
            disabled={
              formStateAddAddress.postalCode?.toString().length > 5 ||
              formStateAddAddress.postalCode?.toString().length < 5 ||
              !formStateAddAddress.postalCode
            }
            onClick={handleClickValidatePostalCode}
          />
        </div>
      </NeoColumn>

      <NeoColumn col="6">
        <NeoDropdown
          label="Colonia"
          name="colony"
          options={formColonyData}
          value={formStateAddAddress.colony}
          onChange={handleChangeForm}
        />
      </NeoColumn>

      <NeoColumn>
        <NeoInputText label="Pais" value="México" disabled />
      </NeoColumn>

      <NeoColumn>
        <NeoInputText
          label="Estado"
          name="state"
          disabled
          value={formStateAddAddress.state}
          onChange={handleChangeForm}
        />
      </NeoColumn>

      <NeoColumn>
        <NeoInputText
          label="Ciudad"
          name="city"
          disabled
          value={formStateAddAddress.city}
          onChange={handleChangeForm}
        />
      </NeoColumn>

      <NeoColumn col="12">
        <NeoInputTextarea
          label="Referencias (opcional)"
          name="references"
          value={formStateAddAddress.references}
          onChange={handleChangeForm}
        />
      </NeoColumn>
    </NeoGridContainer>
  </NeoDialog>
}
