const isEmptyTheShippingConfigState = (data) => {
  const newData = Object.entries(data);
  let isEmpty = false;
  newData.forEach((prop) => {
    if (prop[0] === "internalNumber") return;
    if (prop[0] === "references") return;
    if (!prop[1]) {
      isEmpty = true;
    }
  });
  return isEmpty;
};

const mapTheInfoCPForDropdown = (settlements) => {
  return settlements.map((settlement) => ({
    label: settlement,
    value: settlement,
  }));
};

export { isEmptyTheShippingConfigState, mapTheInfoCPForDropdown };
