import NeoColumn from '../../design/design_components/neo/layout/NeoColumn.base';
import NeoGridContainer from '../../design/design_components/neo/layout/NeoGridContainer.base';
import NeoSingleContainer from '../../design/design_components/neo/layout/NeoSingleContainer.base';
import LogoWhite from "../../design/assets/img/wfi/logo.svg";
import NeoCard from '../../design/design_components/neo/panel/NeoCard.base';
import NeoTable from '../../design/design_components/neo/table/NeoTable.base';
import NeoTableColumn from '../../design/design_components/neo/table/NeoTableColumn.base';
import NeoButtonMain from '../../design/design_components/neo/button/NeoButtonMain.base';
import NeoButtonSection from '../../design/design_components/neo/layout/NeoButtonSection.base';
import { useState } from 'react';

const Connections = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = async (provider) => {
    setLoading(true);
    try {
      const result = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v2/ap/connections?provider=${provider}`, {method: "GET",});
      if (!result.ok) {
        throw new Error();
      }
      const {connections} = (await result.json());
      setData(connections);
    } catch (error) {
      setData([]);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
    <NeoSingleContainer>
      <NeoGridContainer col="12" subExtra="h-100">
        <NeoColumn col="12" extra="px-0">
          <img className="logo-intro" src={LogoWhite} alt="NeoWifi" />
        </NeoColumn>

        <NeoColumn md="10" extra="p-md-offset-1 ">
          <NeoCard extra="text-align-center">
          <NeoButtonSection>
            <NeoButtonMain
              label="Aruba"
              onClick={() => {getData("ARUBA")}}
              loading={loading}
            />
            <NeoButtonMain
              label="Ruckus"
              onClick={() => {getData("RUCKUS")}}
              loading={loading}
            />
          </NeoButtonSection>
            <NeoTable
              emptyMessage={"No hay conexiones registradas"}
              value={data}
              loading={loading}
            >
              <NeoTableColumn
                field="provider"
                header="Provider"
                filter
                sortable
                filterMatchMode="contains"
              />
              <NeoTableColumn
                field="ap_mac"
                header="AP MAC"
                filter
                sortable
                filterMatchMode="contains"
              />
              <NeoTableColumn
                field="device_mac"
                header="Device MAC"
                filter
                sortable
                filterMatchMode="contains"
              />
              <NeoTableColumn
                field="ssid"
                header="SSID"
                filter
                sortable
                filterMatchMode="contains"
              />
            </NeoTable>
          </NeoCard>
        </NeoColumn>
      </NeoGridContainer>
    </NeoSingleContainer>
    </>
  )
}

export default Connections;