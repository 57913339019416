import { useHistory, useLocation, matchPath } from "react-router-dom"
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function useApp() {
  const history = useHistory();
  const location = useLocation();
  const query = {
    params: useParams()
  }

  const redirect = (_path = '', _params = {}) => {
    Object.entries(_params).forEach(([key, value]) => {
      _path = _path.replaceAll(`:${key}`, value);
    });

    history.push(_path);
  }

  return {
    redirect,
    location,
    matchPath,
    query
  }
}