export default function ContactInfoCamp({
  name,
  value,
  defaultValue = "-",
  style,
}) {
  if (value === "" || value === null) {
    return null;
  }
  return (
    <div style={{ display: "grid", gap: "0.25rem", ...style }}>
      <p style={{ margin: "0" }}>{name}</p>
      <p style={{ margin: "0", fontWeight: "bold" }}>{value ?? defaultValue}</p>
    </div>
  );
}
