import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { CONST } from "../../../consts/consts";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import useToastContext from "../../../hooks/useToastContext.hook";
import { putValidation } from "../../../service/Contacts.service";
import { useHistory } from "react-router-dom";

export default function RevalidateContactButton() {
  const [modalRevalidateVisibility, setModalRevalidateVisibility] =
    useState(false);
  const { id } = useParams();
  const toast = useToastContext();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [resultValidation, setResultValidation] = useState("");

  const revalidateHandle = async () => {
    setLoading(true);
    let submit = await putValidation({ contactId: id });
    console.log(submit);
    if (submit.success) {
      setResultValidation(submit.result);
      setModalRevalidateVisibility(true);
    } else {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        submit.message
      );
    }
    setLoading(false);
  };

  const handleModal = () => {
    setModalRevalidateVisibility(false);
    history.go(0);
  };

  const footerTemplateRevalidateModal = (
    <NeoButtonMain label="Aceptar" onClick={() => handleModal()} />
  );

  return (
    <>
      <NeoButtonMain
        label={"Revalidar correo eléctronico"}
        icon="pi pi-check-circle"
        onClick={() => revalidateHandle()}
        loading={loading}
      />
      <NeoDialog
        header="Revalidación de contacto"
        footer={footerTemplateRevalidateModal}
        visible={modalRevalidateVisibility}
        closable={false}
      >
        <p>
          El contacto se revalidó y tiene como resultado{" "}
          <b>{resultValidation}</b>
        </p>
      </NeoDialog>
    </>
  );
}
