import { InputSwitch } from "primereact/inputswitch";

export default function BaseInputSwitch(props) {
  return (
    <>
      <InputSwitch
        {...props}
        className={`${props.custom ? props.custom : ""} ${
          props.extra ? props.extra : ""
        }`}
      />
      <label className={props.labelClass}> {props.label}</label>
    </>
  );
}
