import React, { useEffect } from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonOutlined from "../../../design/design_components/neo/button/NeoButtonOutlined.base";
import NeoInputNumber from "../../../design/design_components/neo/form/NeoInputNumber.base";
import BaseConfigurationModal from "./BaseConfigurationModal.component";

export default function ParametersModal(props) {
  useEffect(() => {
    let mount = true;
    if (mount) {
      props.determineTypeValue(props.template);
      if (!props.valueNumber) {
        props.setValueNumber(
          props.template?.VALUE ||
            props.template?.WEEK_PERIOD ||
            props.template?.REVIEW_PERIOD
        );
      }
    }
    return () => {
      mount = false;
    };
  }, [props.visible]); // eslint-disable-line react-hooks/exhaustive-deps

  const footer = (
    <>
      {/* <NeoButtonOutlined label="Regresar" onClick={() => goBack()} />
      <NeoButtonMain label="Continuar" onClick={() => confirmation()} /> */}
      <>
        <NeoButtonOutlined label="Regresar" onClick={() => goBack()} />
        <NeoButtonMain label="Guardar" onClick={() => handleSubmit()} />
      </>
    </>
  );

  // const createStateDescription = () => {
  //   if (props.typeValue === TYPE_VALUES.frequency) {
  //     return frequencyDescription();
  //   }
  //   if (props.typeValue === TYPE_VALUES.period) {
  //     return lostDescription();
  //   }
  // };

  // const frequencyDescription = () => {
  //   props.setDescription(
  //     <>
  //       <p>
  //         Ahora el {props.template?.NAME.toLowerCase()} se enviará cuando el
  //         cliente haya realizado {props.valueNumber} {singular("visita")} en el
  //         mes.
  //       </p>
  //       <p>
  //         Actualmente se envía cuando realiza {props.template?.VALUE}{" "}
  //         {singular("visita", props.template?.VALUE)}.
  //       </p>
  //     </>
  //   );
  // };

  // const lostDescription = () => {
  //   props.setDescription(
  //     <>
  //       <p>
  //         Ahora el {props.template?.NAME.toLowerCase()} se enviará cuando el
  //         cliente no se haya conectado en {props.valueNumber}{" "}
  //         {singular("semana")}.
  //       </p>
  //       <p>
  //         Actualmente se envía a las {props.template?.WEEK_PERIOD}{" "}
  //         {singular("semana", props.template?.WEEK_PERIOD)}.
  //       </p>
  //     </>
  //   );
  // };

  // const confirmation = () => {
  //   createStateDescription();
  //   props.setVisibleConfirmation(true);
  //   props.setHeader(
  //     `Cambios de configuración del ${props.template?.NAME.toLowerCase()}`
  //   );
  //   props.setVisible(false);
  // };

  const goBack = () => {
    props.setConfigurationVisible(true);
    props.setHeader(props.template?.NAME);
    props.setValueNumber(null);
    props.setVisible(false);
    props.setTypeValue(null);
  };

  const handleSubmit = () => {
    props.setVisible(false);
    props.submit();
  };

  const singular = (word, variable) => {
    if (variable) return variable === 1 ? `${word}` : `${word}s`;
    return props.valueNumber === 1 ? `${word}` : `${word}s`;
  };

  const frequencyContent = () => {
    return (
      <>
        <p>Número de visitas para envío de correo</p>
        <NeoInputNumber
          value={props.valueNumber}
          onChange={(e) => props.setValueNumber(e.value)}
          suffix={` ${singular("visita")}`}
          min={1}
          max={100}
        />
      </>
    );
  };

  const weeksContent = () => {
    return (
      <>
        <p>Número de semanas para considerar a un cliente como perdido</p>
        <NeoInputNumber
          value={props.valueNumber}
          onChange={(e) => props.setValueNumber(e.value)}
          suffix={` ${singular("semana")}`}
          min={1}
          max={52}
        />
      </>
    );
  };

  const reviewContent = () => {
    return (
      <>
        <p>Tiempo necesario para poder envíar el correo</p>
        <NeoInputNumber
          value={props.valueNumber}
          onChange={(e) => props.setValueNumber(e.value)}
          suffix={` ${singular("hora")}`}
          min={1}
          max={24}
        />
      </>
    );
  };

  const setContent = () => {
    if (props.template?.VALUE) {
      return frequencyContent();
    } else if (props.template?.WEEK_PERIOD) {
      return weeksContent();
    } else if (props.template?.REVIEW_PERIOD) {
      return reviewContent();
    } else {
      return null;
    }
  };

  return (
    <>
      <BaseConfigurationModal
        header={props.header}
        template={props.template}
        visible={props.visible}
        setVisible={props.setVisible}
        setValueNumber={props.setValueNumber}
        footer={footer}
      >
        {setContent()}
      </BaseConfigurationModal>
    </>
  );
}
