import React from "react";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import BaseConfigurationModal from "./BaseConfigurationModal.component";

export default function ConfigurationModal(props) {
  const stateButton = (state) => {
    return state ? "Desactivar" : "Reactivar";
  };
  const stateIcon = (state) => {
    return state ? "pi-ban" : "pi-send";
  };
  const newState = (state) => {
    return state
      ? "dejará de enviarse. ¿Está seguro de desactivarlo?"
      : "empezará a enviarse. ¿Está seguro de reactivarlo?";
  };

  const configurableButton = () => {
    if (props.template?.CONFIGURABLE)
      return (
        <NeoButtonMain
          label={"Configurar "}
          icon="pi pi-cog"
          onClick={handleConfigurationSubmit}
        />
      );
    return null;
  };

  const createStateDescription = () => {
    props.setDescription(
      <p>
        El {props.template?.NAME.toLowerCase()}{" "}
        {newState(props.template?.ACTIVE)}
      </p>
    );
  };

  const handleChangingState = () => {
    createStateDescription();
    props.setTypeValue(null);
    props.setValueNumber(null);
    props.setHeader(
      `${stateButton(
        props.template?.ACTIVE
      )} ${props.template?.NAME.toLowerCase()}`
    );
    props.setVisibleConfirmation(true);
    props.setVisible(false);
  };

  const handleConfigurationSubmit = () => {
    props.setHeader(`Configuración de ${props.template?.NAME.toLowerCase()}`);
    props.setParametersVisible(true);
    props.setValueNumber(null);
    props.setTypeValue(null);
    props.setVisible(false);
  };

  const footer = () => {
    return (
      <>
        <NeoButtonMain
          label={`${stateButton(props.template?.ACTIVE)} envío`}
          icon={`${stateIcon(props.template?.ACTIVE)} pi`}
          onClick={handleChangingState}
        />
        {configurableButton()}
      </>
    );
  };

  return (
    <>
      <BaseConfigurationModal
        header={props.header}
        template={props.template}
        visible={props.visible}
        setVisible={props.setVisible}
        setValueNumber={props.setValueNumber}
        footer={footer()}
      >
        <p>{props.emailDescription}</p>
      </BaseConfigurationModal>
    </>
  );
}
