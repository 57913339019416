import React, { useEffect, useState } from 'react'
import NeoButtonMain from '../../../design/design_components/neo/button/NeoButtonMain.base'
import NeoButtonOutlined from "../../../design/design_components/neo/button/NeoButtonOutlined.base";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoDropdown from "../../../design/design_components/neo/form/NeoDropdown.base";
import { validateExistence, validateMail } from "../../../utils/commons.util";
import Routes from "../../../routers/Routes.router.json";
import NeoInputMask from "../../../design/design_components/neo/form/NeoInputMask.base";
import { useParams, useHistory } from "react-router-dom";

export default function FormDeals() {
  const [APNumber, setAPNumber] = useState(1);
  const [userEmail, setUserEmail] = useState("");
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const { ap } = useParams();
  const [modalExistingMailVisibility, setModalExistingMailVisibility] =
    useState(false);
  const STORE_DROPDOWN_VALUES = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6 o más", value: 6 },
  ];
  const [phone, setPhone] = useState(null);

  useEffect(() => {
    setAPNumber(Number(ap) !== null ? Number(ap) : 1);
  }, [ap]);

  const handleClickLogIn = () => {
    console.log("Redirigir a formulario de inicio de sesión");
    setModalExistingMailVisibility(false);
  };

  const footerTemplateExistingMailModal = (
    <>
      <NeoButtonOutlined
        label="Cambiar Correo"
        onClick={() => setModalExistingMailVisibility(false)}
      />
      <NeoButtonMain label={"Iniciar Sesión"} onClick={handleClickLogIn} />
    </>
  );

  const handleClickContinue = () => {
    if (validateForm()) {
      // Should be a function to validate the email
      const mailExist = false;
      if (mailExist) {
        setModalExistingMailVisibility(true);
      } else {
        history.push(`${Routes.deals.summaryDeal}`, {
          email: userEmail,
          apCount: APNumber,
        });
      }
    }
  };

  const validateForm = () => {
    let isValid = true;
    let result = validateMail(userEmail);
    let listError = {};
    if (!result.itIsValid) {
      listError.userEmail = result.message;
      isValid = false;
    }
    result = validateExistence(APNumber);
    if (!result.itIsValid) {
      listError.APNumber = result.message;
      isValid = false;
    }
    setErrors(listError);
    return isValid;
  };

  const handleClickCustom = () => {
    console.log("contact!");
  };

  const renderNormalProcess = () => {
    return (
      <>
        <NeoInputText
          label="Correo electrónico del contratante"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
          error={errors.userEmail}
        />

        <NeoButtonSection align="center">
          <NeoButtonMain label="Continuar" onClick={handleClickContinue} />
        </NeoButtonSection>
      </>
    );
  };

  const renderCustomDeal = () => {
    return (
      <>
        <NeoInputMask
          mask="(99)-9999-9999"
          value={phone}
          label="Teléfono"
          onChange={(e) => setPhone(e.value)}
        />

        <NeoButtonSection align="center" extra="p-col-3">
          <NeoButtonMain label="Contactanos" onClick={handleClickCustom} />
        </NeoButtonSection>
      </>
    );
  };

  return (
    <>
      {console.log(ap, APNumber)}
      <NeoDropdown
        label="Cantidad de sucursales a contratar"
        value={APNumber}
        options={STORE_DROPDOWN_VALUES}
        onChange={(e) => setAPNumber(e.value)}
        name="apNumberInput"
      />

      {APNumber === 6 ? renderCustomDeal() : renderNormalProcess()}
      <NeoDialog
        header="Aviso correo existente"
        footer={footerTemplateExistingMailModal}
        visible={modalExistingMailVisibility}
        closable={false}
      >
        <p>El correo ya se encuentra registrado.</p>
      </NeoDialog>
    </>
  );
}
