import { useLooker } from "../../hooks/useLooker.hook";
import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
import Logo from "../../design/assets/img/wfi/dashboard_generales.png";
import { CONST } from "../../consts/consts";
import useToastContext from "../../hooks/useToastContext.hook";
import DoNotRefreshPageDialog from "./components/DoNotRefreshPageDialog.component";
import { getDataLookerDashboard } from '../../service/Looker.service';
import ExcelJs from "exceljs";
import { dateFormat } from '../../utils/date.utils';
import { auth } from "../../config/firebase.config";


const GeneralView = ({
  dateRange,
  apIds,
  displayTitle,
  displayDownloadButton,
  onInit,
}) => {
useEffect(() => {
    if (onInit) {
      onInit();
    }
  });

  //console.time("renderTime");
  
  const [loadingExcel, setLoadingExcel] = useState(false); // Estado para el spinner en el botón de descarga
  
  // Obtener la URL del tablero 
  const { url } = useLooker("1");


  const [modifiedUrl, setModifiedUrl] = useState(null);
  //const [shouldRenderIframe, setShouldRenderIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  // Función para formatear las fechas enviadas al iframe
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Usar toast para indicar el error
  const toast = useToastContext();


  // Descarga de datos del tablero
  async function crearExcel() {
    setLoadingExcel(true); // Activa el spinner
    let dataLooker;
    const startDate = formatDate(dateRange.from);
    const endDate = formatDate(dateRange.to);
    const dashboardType = 1;

    dataLooker = await getDataLookerDashboard(startDate, endDate, apIds, dashboardType);
    if (!dataLooker.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
   
      const workbook = new ExcelJs.Workbook();
      const worksheet = workbook.addWorksheet();


    //const timeZone = 'America/Mexico_City';
    // Definir encabezados
    const currentHeaders = [
      { header: 'contacto_id', key: 'contact_id' },
      { header: 'contacto_nombre', key: 'contact_name' },
      { header: 'contacto_email', key: 'contact_email' },
      { header: 'contacto_telefono', key: 'contact_phone' },
      { header: 'contacto_genero', key: 'contact_gender' },
      { header: 'tipo', key: 'tipo' },
      { header: 'fecha', key: 'fecha' }
      ];
    worksheet.columns = currentHeaders;
    //const dataArray = Array.isArray(dataLooker.result[0]) ? dataLooker.result[0] : Object.values(dataLooker.result[0]);
      
    dataLooker.result.forEach(row => {
      //const createdAtValue = row.created_at ? row.created_at.value : '';
      worksheet.addRow({
          contact_id: row.contact_id,
          contact_name: row.contact_name,
          contact_email: row.contact_email,
          contact_phone: row.contact_phone,
          contact_gender: row.contact_gender,
          tipo: row.tipo,
          fecha: row.fecha.value
        });
      });
   await workbook.csv.writeBuffer().then((data) => {
        const blob = new Blob(["\ufeff" + data], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${dateFormat(Date.now())}_generales_contactos_registrados.csv`);
        link.click();
        setLoadingExcel(false); // Desactiva el spinner
        
      }
    );
  }
}
  useEffect(() => {
    if (displayTitle) {
    if (
      url &&
      dateRange?.from !== null &&
      dateRange?.to !== null &&
      dateRange?.to !== "null"
    ) {
      const { from, to } = dateRange;
      const params = {
        startDate: formatDate(from),
        endDate: formatDate(to),
        apIds:apIds,
        userId:auth.currentUser.uid
      };

      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString);
      const urlWithAdditionalText = `${url}?params=${encodedParams}`;

      //console.log("URL con parámetros --------!!!!!!!:", urlWithAdditionalText);
      setModifiedUrl(urlWithAdditionalText);


    } else {
      //setShouldRenderIframe(false); 
    }
  } else {
    // Si displayTitle es false, solo se hace setModifiedUrl con la url
    setModifiedUrl(url);
  }
  }, [url, dateRange, displayTitle]);

  return (
    <>
       

     
      <div className="statistics-title-download">
        {displayTitle && (
          <>
            <img src={Logo} alt="Generales" className="title-image" />
            Generales | Contactos registrados
          </>
        )}
        {displayDownloadButton && (
          <>
            <NeoButtonSection align="right">
              <NeoButtonMain
                label={loadingExcel ? "Cargando..." : "Descargar registros"}
                icon={loadingExcel ? "" : "pi pi-download"}
                onClick={crearExcel}
                disabled={loadingExcel} // Desactiva el botón mientras se carga
                className="button-with-spinner"
              />
            </NeoButtonSection>
          </>
        )}
      </div>

      {loading && <div className="loader">
       <ProgressSpinner
          style={{ width: "45px", height: "45px", margin: "10px" }}
          className="custom-spinner" 
          />Cargando información...</div>} 
          <iframe
            title="Dashboard General"
            src={modifiedUrl}
            allowFullScreen
            onLoad={() => {
              setLoading(false);
          }}
        />

      <DoNotRefreshPageDialog open={loadingExcel} />
    </>
  );
};

export default GeneralView;
