import React, { useState } from "react";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoDialog from "../../../design/design_components/neo/overlay/NeoDialog.base";
import NeoInputMask from "../../../design/design_components/neo/form/NeoInputMask.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";

export default function UserDataModal(props) {
  const [phoneError, setPhoneError] = useState(null);
  const [companyError, setCompanyError] = useState(null);
  const errorMessage = "* Este campo no puede estar vacio";

  const validatePhone = () => {
    if (props.phone === "") {
      setPhoneError(errorMessage);
      return false;
    }
    return true;
  };

  const validateCompany = () => {
    if (props.company === "") {
      setCompanyError(errorMessage);
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateCompany() & validatePhone()) {
      props.submit();
    }
  };

  return (
    <>
      <NeoDialog
        header={"Por favor ingrese sus datos"}
        visible={props.visible}
        draggable={false}
        closable={false}
        footer={
          <NeoButtonMain
            label="Guardar"
            autoFocus
            onClick={handleSubmit}
            loading={props.loading}
          />
        }
      >
        <NeoInputText
          md="12"
          label="Empresa"
          value={props.company}
          onChange={(event) => props.setCompany(event.target.value)}
          onBlur={() => validateCompany()}
          error={companyError}
        />

        <NeoInputMask
          md="12"
          label="Teléfono"
          mask="(99) 9999-9999"
          value={props.phone}
          unmask={true}
          onChange={(event) => props.setPhone(event.target.value)}
          onBlur={() => validatePhone()}
          error={phoneError}
        />
      </NeoDialog>
    </>
  );
}
