import React, { useState } from "react";
import NeoFlexContainer from "../../../design/design_components/neo/layout/NeoFlexContainer.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";

export default function ElementContainer({
  children,
  editElement,
  deleteElement,
  moveDownElement,
  moveUpElement,
  locked,
}) {
  const [isHoverMe, setIsHoverMe] = useState(false);

  const [containerClass, setContainerClass] = useState("");

  const handleMouseEnter = () => {
    setContainerClass("bloque-splash-container hover");
    setIsHoverMe(true);
  };
  const handleMouseLeave = () => {
    setContainerClass("bloque-splash-container");
    setIsHoverMe(false);
  };

  const options = () => {
    if (isHoverMe) {
      return (
        <NeoFlexContainer jc="center" ai="center" extra="splash-edit-buttons" >
          <NeoButtonMain
            icon="pi pi-arrow-up"
            extra="p-button-rounded  p-mr-2"
            onClick={moveUpElement}
          />
          <NeoButtonMain
            icon="pi pi-arrow-down"
            extra="p-button-rounded  p-mr-2"
            onClick={moveDownElement}
          />
          <NeoButtonMain
            icon="pi pi-pencil"
            extra="p-button-rounded  p-mr-2"
            onClick={editElement}
          />
          {locked ? null : (
            <NeoButtonMain
              icon="pi pi-trash"
              extra="p-button-rounded "
              onClick={deleteElement}
            />
          )}
        </NeoFlexContainer>
      );
    }
  };

  return (
    <>
      <div
        className={containerClass}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {options()}

        {children}
      </div>
    </>
  );
}
