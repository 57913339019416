const BaseInputPhoto = (props) => {

  const handleFileInputChange = (event) => {
    const [file] = event.target.files;
    const {type} = file;
    if(type == 'image/png' || type == 'image/jpg' || type == 'image/jpeg') {
      props.onChange(file);
    }
    event.target.value = '';
  }

  return (
    <input
      className='p-d-none'
      id={props.id}
      type='file'
      accept='image/png,image/jpg,image/jpeg'
      onChange={handleFileInputChange}
    />
  );
}

export default BaseInputPhoto;