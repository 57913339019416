import { FileUpload } from "primereact/fileupload";
import React, { useRef, useState } from "react";
import useToastContext from "../../../hooks/useToastContext.hook";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import { CONST } from "../../../consts/consts";

const FileUploadSingle = (props, ref) => {
  const toast = useToastContext();
  const [maxSize, setMaxSize] = useState(
    props.maxSize ? props.maxSize : 20000000
  );
  const [typeFileToAccept, setTypeFileToAccept] = useState(
    props.typeFileToAccept ? props.typeFileToAccept : []
  );

  const onTemplateSelect = (e) => {
    let files = ref.current.state.files;
    if (files.length > 1) {
      files.pop();
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        "Solo se permite agregar un archivo a la vez."
      );
    }
    if (!typeFileToAccept.includes(files[0].type)) {
      files.pop();
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        "No se permite este tipo de archivos"
      );
    }

    props.setFileSelected(files[0]);
  };

  const onTemplateRemove = (file, callback) => {
    props.setFileSelected(null);
    callback();
  };

  const emptyTemplate = () => {
    return (
      <div className="p-d-flex p-ai-center p-dir-col">
        <i className="pi pi-image p-mt-3 p-p-5"></i>
        <span className="p-my-5">Arrastra aquí tu archivo</span>
      </div>
    );
  };

  const showError = (e) => {
    toast.setMessage(
      CONST.SEVERITY.ERROR,
      "Solo se permite agregar un archivo a la vez."
    );
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;

    return <NeoCard>{chooseButton}</NeoCard>;
  };

  const itemTemplate = (file, props) => {
    return (
      <div className="p-ai-center">
        <div className="p-ai-center">
          {file.type.includes("image") ? (
            <img
              alt={file.name}
              role="presentation"
              src={URL.createObjectURL(file)}
              width={100}
            />
          ) : (
            <i className="pi pi-video"></i>
          )}

          <span className="p-dir-col p-text-left p-ml-3">{file.name}</span>
        </div>
        <Tag
          value={props.formatSize}
          severity="warning"
          className="p-px-3 p-py-2"
        />
        <Button
          type="button"
          icon="pi pi-times"
          className="p-button-outlined p-button-rounded p-button-danger p-ml-auto"
          onClick={() => onTemplateRemove(file, props.onRemove)}
        />
      </div>
    );
  };

  const chooseOptions = {
    label: "Cargar archivo",
    className: "custom-choose-btn p-button-rounded p-button-outlined",
    disabled: true,
  };

  return (
    <FileUpload
      ref={ref}
      name="demo[]"
      url="https://primefaces.org/primereact/showcase/upload.php"
      onError={showError}
      accept={"image/*, video/*"}
      maxFileSize={maxSize}
      onSelect={onTemplateSelect}
      headerTemplate={headerTemplate}
      itemTemplate={itemTemplate}
      emptyTemplate={emptyTemplate}
      chooseOptions={chooseOptions}
    />
  );
};

export default React.forwardRef(FileUploadSingle);
