import React from "react";

export default function Phone(props) {
  return (
    <>
      <div className="sp-field sp-block">
        <label htmlFor={props.label}>{props.label}</label>

        <input
          type="text"
          id={"Phone"}
          name={"Phone"}
          pattern="[0-9]+"
          maxLength={10}
        />
      </div>
    </>
  );
}
