import React, { useState } from "react";
import NeoInputText from "../../../design/design_components/neo/form/NeoInputText.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoInnerTitle from "../../../design/design_components/neo/title/NeoInnerTitle.base";
import NeoInputPassword from "../../../design/design_components/neo/form/NeoInputPassword.base";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import { signIn } from "../../../utils/auth_signup_password.util";
import NeoLink from "../../../design/design_components/neo/button/NeoLink.base";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../config/firebase.config";
import { useHistory } from "react-router-dom";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const inputOnKeyDown = (event) => {
    if (event.key === "Enter") {
      console.log("envia");
    }
  };

  const handleSignIn = () => {
    signIn(email, password);
    history.push("/");
  };

  const handleForgetPassword = async () => {
    if (email === "") {
      alert("Por favor introduce un email.");
    } else {
      sendPasswordResetEmail(auth, email);
      alert("Correo enviado");
    }
  };

  return (
    <>
      <NeoColumn extra="px-0">
        <NeoInnerTitle extra="primero">Inicio de sesión</NeoInnerTitle>

        <NeoInputText
          id="correo"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          label="Correo electrónico"
          onKeyDown={inputOnKeyDown}
        />

        <NeoInputPassword
          id="contrasena"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Contraseña"
          noFeedback
          onKeyDown={inputOnKeyDown}
        />
        <NeoLink
          label="¿Olvidaste tu contraseña?"
          onClick={handleForgetPassword}
        />
        <NeoButtonSection>
          <NeoButtonMain
            label="Iniciar sesión"
            onClick={handleSignIn}
            loading={loading}
          />
        </NeoButtonSection>
      </NeoColumn>
    </>
  );
}
