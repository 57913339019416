import React, { useEffect, useState } from "react";
import NeoInputSwitch from "../../../design/design_components/neo/form/NeoInputSwitch.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import NeoInnerTitle from "../../../design/design_components/neo/title/NeoInnerTitle.base";
import NeoButtonSection from "../../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "../../../design/design_components/neo/button/NeoButtonMain.base";
import NeoLink from "../../../design/design_components/neo/button/NeoLink.base";
import { getSettings, putSettings } from "../../../service/Settings.service";
import useToastContext from "../../../hooks/useToastContext.hook";
import { CONST } from "../../../consts/consts";
import NeoSpinner from "../../../design/design_components/neo/overlay/NeoSpinner.base";
import useCompressedImageUploader from "../../../hooks/useCompressedImageUploader.hook";
import PrivacyTermsDialog from "./PrivacyTermsDialog.component";
import NeoInputPhoto from "../../../design/design_components/neo/form/NeoInputPhoto.base";

export default function SettingDetails() {
  const [neoValidadorSwitch, setNeoValidadorSwitch] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [privacyTermsModalVisibility, setPrivacyTermsModalVisibility] =
    useState(false);
  const [urlPrivacyTerms, setUrlPrivacyTerms] = useState("");
  const [FileUpload, logo, setLogo, onLogoSubmit, selectedImage] =
    useCompressedImageUploader(setImageLoading);
  const toast = useToastContext();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setUserSettings();
    }
    return () => {
      mounted = false;
    };
  }, []);

  const setUserSettings = async () => {
    let userSettings = await getSettings();

    if (!userSettings.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
    } else {
      setNeoValidadorSwitch(Boolean(userSettings.result.NEOVALIDADOR_SETTINGS));
      setLogo(userSettings.result.URL_LOGOS);
      setUrlPrivacyTerms(userSettings.result.PRIVACY_LINK);
    }
  };

  const submitConfiguration = async () => {
    try {
      if (selectedImage) {
        onLogoSubmit();
      }

      let requestSettings = {
        neoValidadorEmail: neoValidadorSwitch,
        privacyTermUrl: urlPrivacyTerms,
      };
      let submitRequest = await putSettings(requestSettings);
      if (!submitRequest.success) {
        toast.setMessage(
          CONST.SEVERITY.ERROR,
          CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
          CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
        );
      } else {
        toast.setMessage(
          CONST.SEVERITY.SUCCESS,
          CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.HEADLINE,
          CONST.TOAST_MESSAGES.SUCCESS_TO_UPDATE.DETAILS
        );
      }
    } catch (e) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.HEADLINE,
        CONST.TOAST_MESSAGES.FAIL_TO_UPLOAD.DETAILS
      );
    }
  };

  return (
    <>
      {loading ? <NeoSpinner /> : null}
      <NeoCard>
        <NeoInnerTitle extra="primero">Validación de correos</NeoInnerTitle>
        <NeoColumn col="12">
          <NeoInputSwitch
            checked={neoValidadorSwitch}
            onChange={(e) => setNeoValidadorSwitch(e.value)}
            label="Habilitar la validación automática de los correos de los clientes"
          />
        </NeoColumn>
      </NeoCard>
      <NeoCard>
        <NeoInnerTitle extra="primero">
          Logo a usar en Splash Pages y correos electrónicos
        </NeoInnerTitle>
        {/* 
        <NeoInputPhoto
          height="100px"
          url={`${logo}`}
          circle
          tooltip="Peso máximo de imagen 200KB
          `logoSolo se permite imagenes .png
          Tamaño recomendado de imagen 250 × 150 px"
          // onChange={handleFileChange}//
        />
          tooltip="La foto de perfil debe ser cuadrada, con un tamaño máximo de 5 MB, un borde mínimo de 192px y máximo de 640px."
          //onChange={handleFileChange}//
        /> */}
        <NeoColumn sm="9" col="12" extra="splashpage-logo-container">
          {/* <img
            src={`${logo}`}
            style={{
              display: imageLoading ? "block" : "none",
              width: "200px",
              heigth: "200px",
            }}
            onLoad={() => setImageLoading(true)}
          />
          {!imageLoading && (
            <i
              className="pi pi-spin pi-spinner"
              style={{ fontSize: "2em" }}
            ></i>
          )} */}
          <FileUpload />
        </NeoColumn>
      </NeoCard>

      <NeoCard>
        <NeoInnerTitle extra="primero">Términos y condiciones</NeoInnerTitle>
        <NeoColumn>
          <NeoButtonSection extra="p-jc-start" align="left">
            <NeoLink
              label="Probar link actual "
              onClick={() => window.open(urlPrivacyTerms, "_blank").focus()}
            />
            <NeoButtonMain
              label="Cambiar link de términos y condiciones"
              onClick={() => setPrivacyTermsModalVisibility(true)}
            />
          </NeoButtonSection>
        </NeoColumn>
      </NeoCard>
      <PrivacyTermsDialog
        visibility={privacyTermsModalVisibility}
        setVisibility={setPrivacyTermsModalVisibility}
        urlLink={urlPrivacyTerms}
        setUrlLink={setUrlPrivacyTerms}
      />

      <NeoButtonSection align="right">
        <NeoButtonMain
          label="Guardar configuración"
          onClick={submitConfiguration}
        />
      </NeoButtonSection>
    </>
  );
}