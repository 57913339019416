import React, { useState } from 'react';
import NeoInputText from '../form/NeoInputText.base'

const NeoInputTextFilter = (props, ref) => {

  const [elementSelected, setElementSelected] = useState('');

  const onElementSelectedChange = (e) => {
    ref.current.filter(e.target.value, props.field, props.filterMatch)
    setElementSelected(e.target.value)
  };
  
  return ( 
    <NeoInputText value={elementSelected}
      onChange={onElementSelectedChange} placeholder={props.placeholder} 
      className="p-column-filter"
    />
  )
}

export default React.forwardRef(NeoInputTextFilter);
