import React, { useEffect, useState } from "react";
import { getSettings } from "../../../../../../service/Settings.service";

export default function Logo() {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    let mount = true;
    if (mount) {
      getLogo();
    }
    return () => {
      mount = false;
    };
  }, []);

  const getLogo = async () => {
    let settings = await getSettings();
    if (settings.success) {
      setLogo(settings.result.URL_LOGOS);
    }
  };

  return (
    <>
      <div className="sp-block sp-logo-block">

        <img src={logo} />
      </div>
    </>
  );
}
