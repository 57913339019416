const dateTemplate = (fecha) => {
  if (!fecha) {
    return null;
  }
  let date = new Date(fecha);
  date.setHours(date.getHours() - 6);
  return (
    <span>{`${date.getDate()} ${
      monthsSelection()[date.getMonth()].nombre
    } ${date.getFullYear()}`}</span>
  );
};

const dateTimeTemplate = (fecha) => {
  if (!fecha) {
    return null;
  }
  let date = new Date(fecha);
  date.setHours(date.getHours());
  return (
    <span>{`${date.getDate()} ${
      monthsSelection()[date.getMonth()].nombre
    } ${date.getFullYear()} ${addZero(
      date
        .getHours()
        .toLocaleString("en-US", { timeZone: "America/Mexico_City" })
    )}:${addZero(
      date
        .getMinutes()
        .toLocaleString("en-US", { timeZone: "America/Mexico_City" })
    )}`}</span>
  );
};

const addZero = (time) => {
  return /^\d$/.test(time) ? `0${time}` : time
};


const filterDate = (value, filter) => {
  let date = new Date(value)
  
  if (filter[0] && !filter[1]) {
    return date.getTime() >= filter[0].getTime();
  }

  if (!(filter[0] && filter[1])) {
    return false;
  }

  const dateToFilter = date.getTime();
  const firstDate = filter[0].getTime();
  let secondDate = new Date(filter[1].getTime());

  secondDate.setHours(23);
  secondDate.setMinutes(59);
  secondDate.setSeconds(59);
  secondDate = secondDate.getTime();

  return dateToFilter >= firstDate && dateToFilter <= secondDate;
}

const monthsSelection = () => {
  return [
    {"nombre": "Enero", "index": 0},
    {"nombre": "Febrero", "index": 1},
    {"nombre": "Marzo", "index": 2},
    {"nombre": "Abril", "index": 3},
    {"nombre": "Mayo", "index": 4},
    {"nombre": "Junio", "index": 5},
    {"nombre": "Julio", "index": 6},
    {"nombre": "Agosto", "index": 7},
    {"nombre": "Septiembre", "index": 8},
    {"nombre": "Octubre", "index": 9},
    {"nombre": "Noviembre", "index": 10},
    {"nombre": "Diciembre", "index": 11},
  ]
}

const dateFormat = (fecha) => {
  if(!fecha){
    return null
  }
  let date = new Date(fecha)
  return `${date.getDate()} ${monthsSelection()[date.getMonth()].nombre} ${date.getFullYear()}`
}



export {dateTemplate, filterDate, monthsSelection, dateFormat, dateTimeTemplate}