import { useEffect } from "react";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import { useLooker } from "../../hooks/useLooker.hook";

const ActivityView = ({ onInit }) => {
  useEffect(() => {
    if (onInit) {
      onInit();
    }
  });
  const { url } = useLooker("4");
  return <iframe title="Actividad" src={url} allowFullScreen />;
  return (
    <NeoColumn md="12">
      <div style={{ display: "block", paddingBottom: "5rem" }}>
        <iframe
          title="Actividad"
          src="https://lookerstudio.google.com/embed/reporting/4255f7b1-88c9-4884-bdce-6f75b662444b/page/p_2quiho1c8c"
          style={{ border: "none", width: "100%", minHeight: "95rem" }}
          allowFullScreen
        />
      </div>
      <div style={{ display: "block", paddingBottom: "5rem" }}>
        <iframe
          title="Actividad"
          src="https://lookerstudio.google.com/embed/reporting/70dc8739-146d-4273-911b-2cd5dae55397/page/p_qt17gbfc8c"
          style={{ border: "none", width: "100%", minHeight: "50rem" }}
          allowFullScreen
        />
      </div>
    </NeoColumn>
  );
};

export default ActivityView;
