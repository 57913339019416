import React, { useState } from "react";
import NeoDropdown from "../../../design/design_components/neo/form/NeoDropdown.base";

const MONTHS = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre"
];

const YEARS = ["2022", "2023", "2024"];

const START_MONTH = 4;

function generateOptions() {
  const END_MONTH = new Date().getMonth();

  let options = [];
  for (let i = 0; i < YEARS.length; i++) {
    for (let j = 0; j < MONTHS.length; j++) {
      if (i === 0 && j < START_MONTH) {
        continue;
      }
      options.push(`${MONTHS[j]} ${YEARS[i]}`);

      if (i === YEARS.length - 1 && j === END_MONTH) {
        break;
      }
    }
  }
  return options;
}

/**
 * @param {string} period
 * @returns {Date[]}
 */
export function filterPeriodToDate(period) {
  const [month, year] = period.split(" ");
  const firstDayOfMonth = new Date(year, MONTHS.indexOf(month), 1);
  const lastDayOfMonth = new Date(year, MONTHS.indexOf(month) + 1, 0);
  lastDayOfMonth.setDate(lastDayOfMonth.getDate() + 1);
  return [firstDayOfMonth, lastDayOfMonth];
}

export function currentMonthAsValidPeriod() {
  const currentMonth = MONTHS[new Date().getMonth()];
  const currentYear = new Date().getFullYear();
  return `${currentMonth} ${currentYear}`;
}

function PeriodsFilter({ filterPeriod, setFilterPeriod }) {
  const options = generateOptions();

  return (
    <div style={{ minWidth: 257 }}>
      <label
        style={{
          fontSize: "12px",
          color: "#767F87",
          marginLeft: 8,
          marginTop: 20,
          marginBottom: -13,
          display: "block"
        }}
      >
        Periodo
      </label>
      <NeoDropdown
        options={options.reverse()}
        onChange={(e) => setFilterPeriod(e.value)}
        placeholder={options[0]}
        value={filterPeriod}
        showClear
      />
    </div>
  );
}

export default PeriodsFilter;
