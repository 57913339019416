import React from "react";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleSecond from "../../design/design_components/neo/title/NeoTitleSecond.base";
import ContentSplashPageDetail from "./components/ContentSplashPageDetail.component";
import ModalNewSplashPageGroup from "./components/ModalNewSplashPageGroup.component";
import Icono from '../../design/assets/img/wfi/icons/icon-splash-2.svg';

const SplashPageDetails = ({ location }) => {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleSecond
          subtitle="Edición de grupo de splash pages"
          title={location.state.NAME}
          goback="true"
          icon={Icono}
        />
      </NeoGridContainer>
      <ContentSplashPageDetail />
    </>
  );
};

export default SplashPageDetails;
