import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { CONST } from "../../../consts/consts";
import NeoDataSecond from "../../../design/design_components/neo/data/NeoDataSecond.base";
import NeoColumn from "../../../design/design_components/neo/layout/NeoColumn.base";
import NeoCard from "../../../design/design_components/neo/panel/NeoCard.base";
import useToastContext from "../../../hooks/useToastContext.hook";
import { getContactDetail } from "../../../service/Contacts.service";
import { dateTimeTemplate } from "../../../utils/date.utils";
import ContactVisitsTable from "./ContactVisitsTable.component";
import NeoAccordion from "../../../design/design_components/neo/panel/NeoAccordion.base"
import NeoAccordionTab from "../../../design/design_components/neo/panel/NeoAccordionTab.base"
import NeoCardPrincipal from "../../../design/design_components/neo/panel/NeoCardPrincipal.base";
import NeoInnerBlankCardMainText from "../../../design/design_components/neo/table/NeoInnerBlankCardMainText.base";

export default function ContactDetailContent(props) {
  const [loading, setLoading] = useState(false);
  const toast = useToastContext();
  const { id } = useParams();
  const noValue = CONST.NO_VALUE;
  const [contactData, setContactData] = useState({
    EMAIL: null,
    EMAIL_VALIDATION: null,
    VALIDATION_REASON: null,
    PHONE: null,
    NAME: null,
    CONTACT_STATUS: null,
    AVG_DURATION: null,
    LAST_CONNECTION: null,
    VISIT_COUNT: null,
  });

  const [extraContactData, setExtraContactData] = useState([]);

  useEffect(() => {
    let mounted = true;

    setLoading(true);
    if (mounted) {
      getContactDetailData(id);
    }
    setLoading(false);

    return () => {
      mounted = false;
    };
  }, [props.contactData]); // eslint-disable-line react-hooks/exhaustive-deps

  const getContactDetailData = async () => {
    let contactInfo;
    contactInfo = await getContactDetail(id);
    if (!contactInfo.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
      console.log(contactInfo);
    } else {
      setContactData((prevState) => ({
        ...prevState,
        ...contactInfo.result.contactDetail,
      }));
      setExtraContactData(contactInfo.result.extraDetails);
    }
  };

  const templateEmailValidation = (emailValidation) => {
    return <span>{capitalize(emailValidation)}</span>;
  };

  const avgVisitHours = (avgVisit) => {
    return <span>{(avgVisit / 3600).toFixed(1)} hrs</span>;
  };

  const capitalize = (word) => {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  };

  const extractDataWithValues = (data) => {
    return data.filter(
      (element) => element.VALUE !== null && element.CONTACT_FIELD_ID !== 1
    );
  };

  const renderExtraValues = () => {
    let extraContactDataFiltered = extractDataWithValues(extraContactData);
    if (extraContactDataFiltered.length > 0) {
      return (
        <NeoCard>
          {extraContactDataFiltered.map((data) => {
            return (
              <NeoColumn md={7}>
                <NeoDataSecond
                  fact={data.VALUE ? data.VALUE : noValue}
                  label={data.LABEL}
                />
              </NeoColumn>
            );
          })}
        </NeoCard>
      );
    } else {
      return (
        <NeoCardPrincipal>
          <NeoInnerBlankCardMainText>Sin Registros</NeoInnerBlankCardMainText>
        </NeoCardPrincipal>
      );
    }
  };

  return (
    <>
      <NeoCard>
        <NeoColumn md={5}>
          <NeoDataSecond
            fact={contactData.EMAIL ? contactData.EMAIL : noValue}
            label="Correo electrónico"
          />
        </NeoColumn>
        <NeoColumn md={4}>
          <NeoDataSecond
            fact={
              contactData.EMAIL_VALIDATION
                ? templateEmailValidation(contactData.EMAIL_VALIDATION)
                : noValue
            }
            label="Validación del correo electrónico"
          />
        </NeoColumn>
        <NeoColumn md={4}>
          <NeoDataSecond
            fact={
              contactData.CONTACT_STATUS ? contactData.CONTACT_STATUS : noValue
            }
            label="Estado"
          />
        </NeoColumn>
        <NeoColumn md={4}>
          <NeoDataSecond
            fact={
              contactData.LAST_CONNECTION
                ? dateTimeTemplate(contactData.LAST_CONNECTION)
                : noValue
            }
            label="Última conexión"
          />
        </NeoColumn>
        <NeoColumn md={4}>
          <NeoDataSecond
            fact={contactData.VISIT_COUNT ? contactData.VISIT_COUNT : noValue}
            label="Cantidad de visitas"
          />
        </NeoColumn>
      </NeoCard>
      <NeoAccordion>
        <NeoAccordionTab header={"Más información"}>
          {renderExtraValues()}
        </NeoAccordionTab>
      </NeoAccordion>

      <ContactVisitsTable loading={loading} userId={id} />
    </>
  );
}
