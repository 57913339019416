import { useContext } from "react";
import { CONST } from "../consts/consts";
import ToastContext from "../contexts/ToastContext.context";

export default function useToastContext() {
  const { toast } = useContext(ToastContext);

  const setMessage = (
    severity = CONST.SEVERITY.ERROR,
    headline = "Ha ocurrido un error",
    message = "",
    lifeTime = 2000
  ) => {
    toast.current.show({
      severity: severity,
      summary: headline,
      detail: message,
      life: lifeTime,
    });
  };

  return {
    setMessage,
  };
}
