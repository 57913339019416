import { useRef } from 'react';

// https://github.com/streamich/react-use/blob/master/docs/useFirstMountState.md

export function useFirstMountState() {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}