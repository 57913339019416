import { useLooker } from "../../hooks/useLooker.hook";
import React, { useEffect, useState } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import NeoButtonSection from "../../design/design_components/neo/layout/NeoButtonSection.base";
import NeoButtonMain from "../../design/design_components/neo/button/NeoButtonMain.base";
import Logo from "../../design/assets/img/wfi/dashboard_marketing.png";

import { CONST } from '../../consts/consts';
import useToastContext from '../../hooks/useToastContext.hook';
import { getDataLookerDashboard } from '../../service/Looker.service';
import ExcelJs from "exceljs";
import { dateFormat } from '../../utils/date.utils';
import { auth } from "../../config/firebase.config";
import DoNotRefreshPageDialog from "./components/DoNotRefreshPageDialog.component";

const AdPerformanceByAdView = ({
  dateRange,
  apIds,
  displayTitle,
  displayDownloadButton,
  onInit,
}) => {
  useEffect(() => {
    if (onInit) {
      onInit();
    }
  });

  //console.time("renderTime");
  const [loadingExcel, setLoadingExcel] = useState(false); // Estado para el spinner en el botón de descarga
  // Obtener la URL del tablero de control general
  const { url } = useLooker("11");


  const [modifiedUrl, setModifiedUrl] = useState(null);
  const [shouldRenderIframe, setShouldRenderIframe] = useState(false);
  const [loading, setLoading] = useState(true);

  // Función para formatear las fechas enviadas al iframe
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Usar toast para indicar el error
  const toast = useToastContext();

  // Descarga de datos del tablero
  async function crearExcel() {
    setLoadingExcel(true); // Activa el spinner
    let dataLooker;
    const startDate = formatDate(dateRange.from);
    const endDate = formatDate(dateRange.to);
    const dashboardType = 11;

    dataLooker = await getDataLookerDashboard(startDate, endDate, apIds, dashboardType);
    //console.log('dataLooker:', dataLooker); 
    if (!dataLooker.success) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.HEADLINE,
        CONST.TOAST_MESSAGES.DATA_DOES_NOT_LOAD.DETAILS
      );
      setLoading(false);
    } else {
   
      const workbook = new ExcelJs.Workbook();
      const worksheet = workbook.addWorksheet();


   // const timeZone = 'America/Mexico_City';
    // Definir encabezados
    const currentHeaders = [
      { header: 'id_tracker', key: 'ad_tracker_id' },
      { header: 'renderizado', key: 'is_rendered' },
      { header: 'tiene_clic', key: 'is_clicked' },
      { header: 'fecha', key: 'printed_at' },
      { header: 'ad_id', key: 'ad_id' },
      { header: 'ad_nombre', key: 'ad_name' },
      { header: 'ad_tipo', key: 'tipo_ad' },
      { header: 'ap_id', key: 'ap_id' },
      { header: 'ap_nombre', key: 'ap_name' },
      { header: 'ap_mac', key: 'ap_mac' },
      { header: 'sucursal', key: 'store_name' }

      ];
    worksheet.columns = currentHeaders;
   // const dataArray = Array.isArray(dataLooker.result[0]) ? dataLooker.result[0] : Object.values(dataLooker.result[0]);
      
    dataLooker.result.forEach(row => {
      //const createdAtValue = row.created_at ? row.created_at.value : '';
      worksheet.addRow({
        ad_tracker_id: row.ad_tracker_id,
        is_rendered: row.is_rendered,
        is_clicked: row.is_clicked,
        printed_at: row.printed_at.value,
        ad_id: row.ad_id,
        ad_name: row.ad_name,
        tipo_ad: row.tipo_ad,
        ap_id: row.ap_id,
        ap_name: row.ap_name,
        ap_mac: row.ap_mac,
        store_name: row.store_name

        });
      });
   await workbook.csv.writeBuffer().then((data) => {
        const blob = new Blob(["\ufeff" + data], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${dateFormat(Date.now())}_marketing_desempenio_tipo_anuncio.csv`);
        link.click();
        setLoadingExcel(false); // Desactiva el spinner

      },
    );
  }
}

  useEffect(() => {
    if (
      url &&
      dateRange?.from !== null &&
      dateRange?.to !== null &&
      dateRange?.to !== "null"
    ) {
      const { from, to } = dateRange;
      const params = {
        startDate: formatDate(from),
        endDate: formatDate(to),
        apIds:apIds,
        userId:auth.currentUser.uid
      };

      const paramsAsString = JSON.stringify(params);
      const encodedParams = encodeURIComponent(paramsAsString);
      const urlWithAdditionalText = `${url}?params=${encodedParams}`;

      //console.log("URL con parámetros --------!!!!!!!:", urlWithAdditionalText);
      setModifiedUrl(urlWithAdditionalText);
    } else {
      setShouldRenderIframe(false); 
    }
  }, [url, dateRange]);

  return (
    <>
      

     
      <div className="statistics-title-download">
        {displayTitle && (
          <>
            <img src={Logo} alt="mARKETING" className="title-image" />
            Marketing | Desempeño por tipo de anuncio
          </>
        )}
        {displayDownloadButton && (
          <>
            <NeoButtonSection align="right">
              <NeoButtonMain
                label={loadingExcel ? "Cargando..." : "Descargar registros"}
                icon={loadingExcel ? "" : "pi pi-download"}
                onClick={crearExcel}
                disabled={loadingExcel} // Desactiva el botón mientras se carga
                className="button-with-spinner"
              />
            </NeoButtonSection>
          </>
        )}
      </div>

      {loading && <div className="loader">
       <ProgressSpinner
          style={{ width: "45px", height: "45px", margin: "10px" }}
          className="custom-spinner" 
          />Cargando información...</div>} 
          <iframe
            title="AdPerformanceByAd"
            src={modifiedUrl}
            allowFullScreen
            onLoad={() => {
              setLoading(false);
          }}
        />

      <DoNotRefreshPageDialog open={loadingExcel} />
    </>
  );
};

export default AdPerformanceByAdView;
