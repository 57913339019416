import React, { useState, useEffect, useRef, lazy } from "react";
import { getVisits } from "../../../service/Visits.service";
import { CONST } from "../../../consts/consts";
import NeoTooltip from "../../../design/design_components/neo/overlay/NeoTooltip.base";
import NeoRangeNumberFilter from "../../../design/design_components/neo/table/NeoRangeNumberFilter.base";
import NeoDateRangeFilter from "../../../design/design_components/neo/table/NeoDateRangeFilter.base";
import NeoTable from "../../../design/design_components/neo/table/NeoTable.base";
import NeoTableColumn from "../../../design/design_components/neo/table/NeoTableColumn.base";
import NeoInputTextFilter from "../../../design/design_components/neo/table/NeoInputTextFilter.base";
import Routes from "../../../routers/Routes.router.json";
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import NeoRating from "../../../design/design_components/neo/form/NeoRating.base";
import NeoMultiSelectFilter from "../../../design/design_components/neo/table/NeoMultiSelectFilter.base";
import SentimentIconSatisfied from "../../../design/assets/img/wfi/sentiment_satisfied.svg";
import SentimentIconMood from "../../../design/assets/img/wfi/mood.svg";
import SentimentIconNeutral from "../../../design/assets/img/wfi/sentiment_neutral.svg";
import SentimentIconDissatisfied from "../../../design/assets/img/wfi/sentiment_dissatisfied.svg";
import SentimentIconExtremelyDissatisfied from "../../../design/assets/img/wfi/sentiment_extremely_dissatisfied.svg";
import { filterPeriodToDate } from "./PeriodsFilter.component"
import {
  MAC_COLUMN,
  DURATION_COLUMN,
  OS_COLUMN,
  REVIEW_COLUMN,
  SENTIMENT_COLUMN
} from "../filterableColumns.enum.js";
import useUpdateEffect from "../../../hooks/useUpdateEffect.js";
import useIsMounted from "../../../hooks/useIsMounted.js";

// Sentiment analysis
const CLEAR_ALL = "without";
const VERY_SATISFIED = [0.61, 1];
const SATISFIED = [0.21, 0.6];
const NEUTRAL = [-0.19, 0.2];
const DISSATISFIED = [-0.59, -0.2];
const VERY_DISSATISFIED = [-1, -0.6];

function moodToIcon(mood) {
  switch (mood) {
    case CLEAR_ALL:
      return null;
    case VERY_SATISFIED:
      return SentimentIconMood;
    case SATISFIED:
      return SentimentIconSatisfied;
    case NEUTRAL:
      return SentimentIconNeutral;
    case DISSATISFIED:
      return SentimentIconDissatisfied;
    case VERY_DISSATISFIED:
      return SentimentIconExtremelyDissatisfied;
    default:
      return null;
  }
}

// Rating value
const FIVE_STARS = 5;
const FOUR_STARS = 4;
const THREE_STARS = 3;
const TWO_STARS = 2;
const ONE_STAR = 1;
const NO_ANSWER = "noAnswer";
// const EXPIRED = "expired";
const NOT_SENT = "notSent";

/**
 * @template T
 * @param {T} value
 * @returns {T}
 */
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// TABLE SORTING ORDER
const ASC = 1;
const DESC = -1;

/**
 * @param {{columnsSelected: string[], filterPeriod: string}} props
 */
export default function VisitsTable({columnsSelected, filterPeriod}) {
  const isMounted = useIsMounted();
  const ROWS_COUNT = 10;
  const EMAIL_VALIDATIONS = CONST.EMAIL_VALIDATIONS;
  const EMAIL_VALIDATION_COLLECTIONS = Object.entries(EMAIL_VALIDATIONS).map(
    (type) => type[1]
  );
  const previousFilterPeriod = usePrevious(filterPeriod);
  const [shouldEmptyDateFilter, setShouldEmptyDateFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [visits, setVisits] = useState([]);
  const [lazyParams, setLazyParams] = useState(() => ({
    first: 0,
    rows: ROWS_COUNT,
    page: 1,
    sortField: "CREATED_AT",
    sortOrder: DESC,
    filters: {
      ...(filterPeriod && {
        "VISITS-TABLE-PERIOD": {
          matchMode: "visits:period-filter",
          value: filterPeriodToDate(filterPeriod)
        }
      })
    }
  }));

  const dt = useRef(null);
  const history = useHistory(null);

  useUpdateEffect(() => {
    const justClearedFilterPeriod = !!previousFilterPeriod && !filterPeriod;

    if (filterPeriod) {
      setLazyParams((prev) => {
        const newFilters = { ...lazyParams.filters };
        delete newFilters["VISIT.CREATED_AT"];

        setShouldEmptyDateFilter(true)
        
        return {
          ...prev,
          filters: {
            ...newFilters,
            "VISITS-TABLE-PERIOD": {
              matchMode: "visits:period-filter",
              value: filterPeriodToDate(filterPeriod)
            }
          }
        };
      });
    }

    if (justClearedFilterPeriod) {
      const newFilters = { ...lazyParams.filters };
      delete newFilters["VISITS-TABLE-PERIOD"];
      setLazyParams((prev) => ({
        ...prev,
        filters: newFilters
      }));
    }

  }, [filterPeriod]);

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps


  const loadLazyData = async () => {
    setLoading(true);
    const visitsData = await getVisits(lazyParams);
    if (!isMounted()) return;

    if (!visitsData.success) {
      return console.log(visitsData);
    }
    setTotalRecords(visitsData.result.totalRecords);
    setVisits(visitsData.result.visits);
    console.log(visits)
    setLoading(false);
  };

  const onPage = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onSort = (event) => {
    let _lazyParams = { ...lazyParams, ...event };
    setLazyParams(_lazyParams);
  };

  const onFilter = (event) => {
    console.log("onFilter");
    console.log(event);
    let _lazyParams = { ...lazyParams, ...event };
    _lazyParams["first"] = 0;
    setLazyParams(_lazyParams);
  };

  const ContactColumn = (value) => {
    return <span>{value}</span>;
  };

  const ContactFilter = (field, placeholder) => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={field}
        placeholder={placeholder}
        filterMatch={"contains"}
      />
    );
  };

  const CreatedAtColumn = (createdAt) => {
    return (
      <span>
        {Intl.DateTimeFormat("es-MX", {
          month: "long",
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
          year: "numeric",
          day: "numeric"
        }).format(moment(createdAt).toDate())}
      </span>
    );
  };

  const CreatedAtFilter = () => {
    return (
      <NeoDateRangeFilter
        ref={dt}
        shouldEmptyDateFilter={shouldEmptyDateFilter}
        setShouldEmptyDateFilter={setShouldEmptyDateFilter}
        field={"VISIT.CREATED_AT"}
        placeholder={"Rango de fechas"}
        currentViewDate={filterPeriod ? filterPeriodToDate(filterPeriod)[0] : null}
        allowViewDateChange={filterPeriod ? false : true}
        minDate={filterPeriod ? filterPeriodToDate(filterPeriod)[0] : null}
        maxDate={filterPeriod ? filterPeriodToDate(filterPeriod)[1] : null}
        matchFilter="range"
        panelClassName={filterPeriod ? "restrict-current-month" : undefined}
        todayButtonClassName={filterPeriod ? "vis-hidden": undefined}
      />
    );
  };

  const StoreColumn = (name) => {
    return <span>{name}</span>;
  };

  const StoreFilter = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"EMAIL"}
        placeholder={"Buscar por sucursal"}
        filterMatch={"contains"}
      />
    );
  };

  const DurationColumn = (duration) => {
    if (duration === null) {
      return <span>En curso</span>;
    }

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);
    const seconds = Math.floor(duration % 60);

    const format = (part) => String(part).padStart(2, "0");

    return (
      <span>{`${format(hours)}:${format(minutes)}:${format(seconds)}`}</span>
    );
  };

  const DurationFilter = () => {
    return (
      <NeoRangeNumberFilter
        ref={dt}
        field={"VISIT_DETAIL.DURATION"}
        minPlaceholder={"Mín"}
        maxPlaceholder={"Máx"}
        minDigit={0}
        maxDigit={0}
      />
    );
  };

  const OsColumn = (os) => {
    return <span>{os ?? "-"}</span>;
  };

  const MacAddressColumn = (os) => {
    return <span>{os ?? "-"}</span>;
  };

  const OsFilter = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"OS"}
        placeholder={"Buscar por sistema operativo"}
        filterMatch={"contains"}
      />
    );
  };

  const MacFilter = () => {
    return (
      <NeoInputTextFilter
        ref={dt}
        field={"MAC_ADDRESS"}
        placeholder={"Buscar por Mac Address"}
        filterMatch={"contains"}
      />
    );
  };

  const ReviewColumn = (stars) => {
    if (stars === null) {
      return <span>Sin respuesta</span>;
    }
    return <NeoRating value={stars} cancel={false} readOnly />;
  };

  const reviewTemplate = (option) => {
    switch (option) {
      case FIVE_STARS:
        return <NeoRating value={5} cancel={false} readOnly />;
      case FOUR_STARS:
        return <NeoRating value={4} cancel={false} readOnly />;
      case THREE_STARS:
        return <NeoRating value={3} cancel={false} readOnly />;
      case TWO_STARS:
        return <NeoRating value={2} cancel={false} readOnly />;
      case ONE_STAR:
        return <NeoRating value={1} cancel={false} readOnly />;
      case NO_ANSWER:
        return <div className="flex align-items-center">Sin respuesta</div>;
      // case EXPIRED:
      //   return <div className="flex align-items-center">Encuesta vencida</div>;
      case NOT_SENT:
        return (
          <div className="flex align-items-center">Encuesta no enviada</div>
        );
      default:
        return <></>;
    }
  };

  const reviews = [
    FIVE_STARS,
    FOUR_STARS,
    THREE_STARS,
    TWO_STARS,
    ONE_STAR,
    NO_ANSWER,
    // EXPIRED,
    NOT_SENT
  ];

  const ReviewFilter = () => {
    return (
      <NeoMultiSelectFilter
        ref={dt}
        field={"VISIT_DETAIL.REVIEW_STARS"}
        matchFilter="stars"
        options={reviews}
        optionLabel="review"
        placeholder="Buscar por calificación"
        itemTemplate={reviewTemplate}
        selectedItemsLabel={(elements) => {
          if (elements) {
            if (elements.length === reviews.length) return "Todas";
            return `${elements.length} calificaciones`;
          }
        }}
        selectedItemTemplate={(val, elements) => {
          if (!elements || !elements.length) return val;
          const lastElement = elements[elements.length - 1];

          switch (val) {
            // case EXPIRED:
            //   return "Encuesta vencida";
            case NOT_SENT:
              return elements.length === 1
                ? "Encuesta no enviada"
                : `Encuesta no enviada${NOT_SENT === lastElement ? "" : ", "}`;
            case NO_ANSWER:
              return elements.length === 1
                ? "Sin respuesta"
                : `Sin respuesta${NO_ANSWER === lastElement ? "" : ", "}`;
            default:
              return elements.length === 1
                ? val
                : `${val}${val === lastElement ? "" : ", "}`;
          }
        }}
      />
    );
  };

  const SentimentColumn = (id, comment, score) => {
    if ([null, undefined].includes(score) === false) {
      let icon;
      score = Number(score);
      if (score >= 0.61) {
        icon = SentimentIconMood;
      } else if (score >= 0.21) {
        icon = SentimentIconSatisfied;
      } else if (score >= -0.19) {
        icon = SentimentIconNeutral;
      } else if (score >= -0.59) {
        icon = SentimentIconDissatisfied;
      } else {
        icon = SentimentIconExtremelyDissatisfied;
      }
      return (
        <>
          <img src={icon} className={`icon-${id}`} alt="Icon" />
          <NeoTooltip target={`.icon-${id}`} position="top" showdelay={1000}>
            {comment}
          </NeoTooltip>
        </>
      );
    }
    return <span>{"-"}</span>;
  };

  const moods = [
    VERY_SATISFIED,
    SATISFIED,
    NEUTRAL,
    DISSATISFIED,
    VERY_DISSATISFIED,
    CLEAR_ALL
  ];

  const moodTemplate = (option) => {
    return option === CLEAR_ALL ? (
      <div className="flex align-items-center">Sin registro</div>
    ) : (
      <div className="flex align-items-center">
        <img src={moodToIcon(option)} className={`icon-${option}`} alt="Icon" />
      </div>
    );
  };

  const SentimentFilter = () => {
    return (
      <NeoMultiSelectFilter
        ref={dt}
        field={"VISIT_DETAIL.COMMENT_SCORE"}
        matchFilter="ranges"
        options={moods}
        optionLabel="sentiment"
        placeholder="Buscar por emoción"
        itemTemplate={moodTemplate}
        selectedItemsLabel={(elements) => {
          if (elements) {
            if (elements.length === moods.length) return "Todas";
            return `${elements.length} emociones`;
          }
        }}
        selectedItemTemplate={(val, elements) => {
          if (!elements || !elements.length) return val;
          const lastElement = elements[elements.length - 1];

          switch (val) {
            case CLEAR_ALL:
              return elements.length === 1
                ? "Sin registro"
                : `Sin registro${CLEAR_ALL === lastElement ? "" : ", "}`;
            case VERY_SATISFIED:
              return elements.length === 1
                ? "10 al 8.1"
                : `10 al 8.1${VERY_SATISFIED === lastElement ? "" : ", "}`;
            case SATISFIED:
              return elements.length === 1
                ? "8 al 6.1"
                : `8 al 6.1${SATISFIED === lastElement ? "" : ", "}`;
            case NEUTRAL:
              return elements.length === 1
                ? "6 al 4.1"
                : `6 al 4.1${NEUTRAL === lastElement ? "" : ", "}`;
            case DISSATISFIED:
              return elements.length === 1
                ? "4 al 2.1"
                : `4 al 2.1${DISSATISFIED === lastElement ? "" : ", "}`;
            case VERY_DISSATISFIED:
              return elements.length === 1
                ? "2 al 0"
                : `2 al 0${VERY_DISSATISFIED === lastElement ? "" : ", "}`;
            default:
              return elements.length === 1
                ? val
                : `${val}${val === lastElement ? "" : ", "}`;
          }
        }}
      />
    );
  };

  const navigateToVisitDetail = (visit) => {
    if (visit.DURATION !== null) {
      history.push(`${Routes.visits.detailsWithOutParams}/${visit.ID}`, visit);
    }
  };

  function emptyMessageGen() {
    if (lazyParams.filters) {
      const hasFilters = Object.keys(lazyParams.filters).length !== 0;
      if (hasFilters) {
        return "No se encontraron registros de visitas con los filtros aplicados";
      }
    }
    return "No hay visitas registradas";
  }

  const emptyMsg = emptyMessageGen();

  return (
    <div>
      <div className="content-section implementation">
        <NeoTooltip target=".emailValidation" data-pr-showdelay={1000} />
        <div className="card">
          <NeoTable
            extra="visits-table"
            emptyMessage={emptyMsg}
            ref={dt}
            value={visits}
            lazy
            selectionMode="single"
            dataKey="ID"
            autoLayout={true}
            paginator
            first={lazyParams.first}
            rows={ROWS_COUNT}
            totalRecords={totalRecords}
            onPage={onPage}
            onSort={onSort}
            removableSort
            sortField={lazyParams.sortField}
            sortOrder={lazyParams.sortOrder}
            onFilter={onFilter}
            filters={lazyParams.filters}
            loading={loading}
            onRowSelect={(e) => navigateToVisitDetail(e.data)}
            rowClassName={(visit) => {
              return {
                disabled: visit.DURATION === null
              };
            }}
          >
              <NeoTableColumn
                field="CREATED_AT"
                header="Fecha"
                filter
                sortable
                filterElement={CreatedAtFilter()}
                filterMatchMode="range"
                body={(e) => CreatedAtColumn(e.CREATED_AT)}
              />
              <NeoTableColumn
                field="EMAIL"
                header="Contacto"
                filter
                sortable
                filterMatchMode="contains"
                body={(e) => ContactColumn(e.EMAIL)}
                filterElement={ContactFilter("EMAIL", "Filtrar por contacto")}
              />
              <NeoTableColumn
                field="STORE"
                header="Sucursal"
                filter
                sortable
                filterMatchMode="contains"
                body={(e) => StoreColumn(e.NAME)}
                filterElement={StoreFilter()}
              />
            {columnsSelected.includes(DURATION_COLUMN) && (
              <NeoTableColumn
                field="DURATION"
                header="Duración"
                filter
                sortable
                filterMatchMode="contains"
                body={(e) => DurationColumn(e.DURATION)}
                filterElement={DurationFilter()}
              />
            )}
            {columnsSelected.includes(MAC_COLUMN) && (
              <NeoTableColumn
                field="MAC_ADDRESS"
                header="Mac Address"
                filter
                filterMatchMode="contains"
                body={(e) => MacAddressColumn(e.MAC_ADDRESS)}
                filterElement={MacFilter()}
              />
            )}
            {columnsSelected.includes(OS_COLUMN) && (
              <NeoTableColumn
                field="OS"
                header="Dispositivo"
                filter
                filterMatchMode="contains"
                body={(e) => OsColumn(e.OS)}
                filterElement={OsFilter()}
              />
            )}
            {columnsSelected.includes(REVIEW_COLUMN) && (
              <NeoTableColumn
                field="REVIEW_STARS"
                header="Calificación"
                filter
                sortable
                filterMatchMode="contains"
                body={(e) => ReviewColumn(e.REVIEW_STARS)}
                filterElement={ReviewFilter()}
              />
            )}
            {columnsSelected.includes(SENTIMENT_COLUMN) && (
              <NeoTableColumn
                field="COMMENTS"
                header="Emoción"
                filter
                sortable
                filterMatchMode="contains"
                body={(e) => SentimentColumn(e.ID, e.COMMENTS, e.COMMENT_SCORE)}
                filterElement={SentimentFilter()}
              />
            )}
          </NeoTable>
        </div>
      </div>
    </div>
  );
}
