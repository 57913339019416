import { useState, useEffect, useRef } from "react";
import * as generateUtil from "../utils/generate.util";

/**
 * @template TypeComponentData
 * @param {{data: TypeComponentData}} propsComponent Source object
 */
const useComponent = (propsComponent) => {
  const ref = useRef(0);
  ref.current = ref.current + 1;

  const [requests, setRequests] = useState([]);
  const [calls, setCalls] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  let isMount = true;
  const dismount = () => {
    isMount = false;
  }

  useEffect(() => {
    return dismount;
  }, [])

  useEffect(() => {
    if (Array.isArray(requests)) {
      if (requests.length === 0) {
        setIsLoading(false);
      } else {
        setIsLoading(true);
      }
    }
  }, [requests])

  /**
   * @template T
   * @param  {T} initialValue
   * @return {[T, React.Dispatch<T>]} 
   */
  const useCustomState = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const customSetValue = (newValue) => {
      if (isMount) {
        setValue(newValue);
      }
    }

    return [
      value,
      customSetValue
    ]
  }
  /**
  * @param  {Promise<any>} fn
  */
  const useAsync = (fn, options = {}) => {
    if (options?.name) {
      fnCalls({
        name: options.name,
        state: 'init'
      });
    }

    if (options?.delay === undefined) {
      options.delay = 0;
    }

    return async function () {
      const id = generateUtil.id();

      setRequests(prevRequests => [...prevRequests, {
        id
      }]);

      const data = {}

      if (options?.name) {
        data.name = options?.name;

        fnCalls({
          name: options.name,
          state: 'sending'
        });
      }

      if (Object.keys(arguments).length !== 0) {
        data.props = arguments;
      }

      if (options?.logger) {
        fnLogger({
          data,
          state: 'sending'
        });
      }

      try {
        const result = await new Promise((resolve, reject) => {
          setTimeout(async () => {
            try {
              const result = await fn.apply(this, data.props);
              resolve(result);
            } catch (error) {
              reject(error);
            }
          }, options.delay);
        });

        if (options?.logger) {
          fnLogger({
            data,
            state: 'completed',
            response: result
          });
        }

        if (options?.name) {
          fnCalls({
            name: options.name,
            state: 'completed',
            response: result
          });
        }

        return result;
      } catch (error) {
        if (options?.logger) {
          fnLogger({
            data,
            state: 'failed',
            error
          });
        }

        if (options?.name) {
          fnCalls({
            name: options.name,
            state: 'failed'
          });
        }

        throw error;
      } finally {
        setRequests(prevRequests => prevRequests.filter(request => request.id !== id));
      }
    };
  }

  const fnCalls = (params) => {
    if (params.state === 'init') {
      const call = calls[params.name];
      if (call === undefined) {
        setCalls(prevCalls => {
          prevCalls[params.name] = {
            name: params.name,
            state: params.state,
            calls: 0
          }
          return prevCalls;
        })
      }
    } else {
      setCalls(prevCalls => {
        prevCalls[params.name].state = params.state;

        if (params.state === 'sending') {
          prevCalls[params.name].calls++;
        }

        if (params.state === 'completed') {
          prevCalls[params.name].response = params.response;
        }

        return prevCalls;
      })
    }
  }

  const fnLogger = (params) => {
    const obj = {
      ...params.data,
      date: new Date().toLocaleString(),
      state: params.state
    };

    if (params.response) {
      obj.response = params.response;
    }

    if (params.error) {
      obj.error = params.error;
    }

    console.log(obj);
  }

  return {
    useState: useCustomState,
    useEffect,
    useAsync,
    calls,
    isLoading,
    dismount,
    renders: ref.current
  }
}

export default useComponent;