import React from "react";
import NeoSpinner from "../../../../design/design_components/neo/overlay/NeoSpinner.base";

/**
 * @typedef {Object} Props
 * @property {boolean} loading
 * @property {() => void} onClickPlay
 */

export default function PreviewOverlay(/** @type {Props} */{
    loading,
    onClickPlay
}) {
  return (
    <div
      style={{
        background: "rgba(0,0,0, 0.25)",
        position: "absolute",
        zIndex: "5",
        top: "0",
        left: 0,
        height: "100%",
        width: "100%"
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          background: "rgba(0, 0, 0, 0.45)",
          fontSize: "1.5rem",
          width: 48,
          height: 48,
          display: "flex",
          borderRadius: "100%",
          border: "2px solid white"
        }}
      >
        <div
          onClick={() => {
            if (loading) return;
            onClickPlay();
          }}
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
            left: loading ? "" : "3px",
            cursor: "pointer"
          }}
        >
          {loading ? <NeoSpinner /> : "\u25B6"}
        </div>
      </div>
    </div>
  );
}
