import React from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import SettingDetails from "./components/SettingDetails.component";
import Icono from "../../design/assets/img/wfi/icons/icon-settings.svg";

export default function Settings() {
  return (
    <>
      <NeoGridContainer>
        <NeoTitleMain col="12" title="Configuración" icon={Icono} />
      </NeoGridContainer>

      <SettingDetails />
    </>
  );
}
