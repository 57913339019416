import React, { useEffect, useState, useCallback, useMemo } from "react";
import NeoGridContainer from "../../design/design_components/neo/layout/NeoGridContainer.base";
import NeoColumn from "../../design/design_components/neo/layout/NeoColumn.base";
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import PrivateRoute from "../../routers/components/PrivateRoute.component";
import Routes from "../../routers/Routes.router.json";
import NeoTitleMain from "../../design/design_components/neo/title/NeoTitleMain.base";
import MenuBar from "../../design/design_components/sisu/menu/BaseNavBar.sisu";
import Icono from "../../design/assets/img/mnv/icons/icon-dashboard.svg";
import ChartSection from "./components/ChartSection.components";
import { getDashboards } from "../../service/Looker.service";
import { getStoreList } from "../../service/Store.service";
import { getDeparmentList } from "../../service/Store.service";
import NeoMultiSelect from "../../design/design_components/neo/form/NeoMultiSelect.base";
import NeoCalendar from "../../design/design_components/neo/form/NeoCalendar.base";
import { getItemsMenu, getAllDashboards } from "./dashboards";
import { getStoreDetail } from "../../service/Store.service";
//import { toBeInTheDOM } from "@testing-library/jest-dom/dist/matchers";
import useToastContext from "../../hooks/useToastContext.hook";
import { CONST } from "../../consts/consts";

function Home() {
  const history = useHistory();
  const [store, setStore] = useState(null);
  const [ap, setAp] = useState(null);

  //const location = useLocation();
  const [version, setVersion] = useState("");
  const [dashboards, setDashboards] = useState([]);
  const [activeDashboard, setActiveDashboard] = useState(null);

  const [selectedStoreIds, setSelectedStoreIds] = useState([]);
  const [department, setDepartment] = useState([]);
  const toast = useToastContext();

  // Calcular el primer y último día del mes anterior para carga por defecto del calendario
  const now = new Date();
  const firstDayOfLastMonth = new Date(now.getFullYear(),now.getMonth() - 1,1);
  const lastDayOfLastMonth = new Date(now.getFullYear(), now.getMonth(), 0);

  // Inicializar el estado con las fechas calculadas
  const [form, setForm] = useState({
    from: firstDayOfLastMonth,
    to: lastDayOfLastMonth,
  });

  const [dateRange, setDateRange] = useState({
    from: firstDayOfLastMonth,
    to: lastDayOfLastMonth,
  });

  const [apIds, setApIds] = useState("");
  const [secondDropdownOptions, setSecondDropdownOptions] = useState([]);
 
  const [items_menu, setItemsMenu] = useState([]);

  // Inicializando estados para obtener sucursales
  const [state, setState] = useState({
    loading: {
      stores: true,
    }
  });

  useEffect(() => {
    getStores();
    getDeparments();
    document.body.classList.add("statistic-page");
    return () => {
      document.body.classList.remove("statistic-page");
    };
  }, []);

  useEffect(() => {
    (async () => {
      const { success, payload } = await getDashboards();
      if (success && payload?.dashboards?.length > 0) {
        const dashboards = payload?.dashboards ?? [];
        setVersion("looker");
        setDashboards(dashboards);
        setItemsMenu(
          getItemsMenu(
            history,
            dashboards.map((d) => d.DASHBOARD_ID)
          )
        );
      } else {
        setVersion("base");
      }
    })();
  }, []);

  // Obtener las Sucursales
  async function getStores() {
    try {
      const { result, success } = await getStoreList(true, {
        networkStatus: false,
      });
      setOptions((prev) => ({ ...prev, stores: result.storesList }));
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, stores: false },
      }));
    } catch (error) {
      console.error("Error cargando sucursales:", error);
    } finally {
      setState((prev) => ({
        ...prev,
        loading: { ...prev.loading, stores: false },
      }));
    }
  }

    // Obtener los Departamentos
  async function getDeparments() {
      try {
        const { result, success } = await getDeparmentList(true);
        //console.log("Departamentos:", result.deparments);
        if (success) {
          const formattedOptions = result.deparments.map(department => ({
            label: department.NAME, // Usar el campo NAME como label
            value: department.ID,   // Usar el campo ID como value
          }));
    
          setOptionsD((prev) => ({ ...prev, deparments: formattedOptions }));

          setState((prev) => ({
            ...prev,
            loading: { ...prev.loading, deparments: false },
          }));
        }
      } catch (error) {
        console.error("Error cargando departamentos:", error);
        setState((prev) => ({
          ...prev,
          loading: { ...prev.loading, deparments: false },
        }));
      }
    }

 
  
  // Cambio en el miltiselect de sucursales
  const handleStoreChange = useCallback(async (event) => {

    //Limpiar los departamentos
    setDepartment(null);

    // Obtener los IDs de las tiendas seleccionadas
    const selectedStores = event.value;
    setStore(selectedStores);
  
    // Crear un conjunto de IDs seleccionados
    const selectedIds = new Set(selectedStores.map((store) => store.ID));
 

  setSelectedStoreIds(selectedIds);

   // Variable para almacenar las opciones agrupadas del MultiSelect
    let groupedOptions = [];
  
    // Iterar sobre cada tienda seleccionada y obtener los detalles y APs
    for (const storeId of selectedIds) {
      const storeData = await getStoreDetail(storeId); 
     
      if (storeData.success) {
        const storeDetail = storeData.result.storeDetail[0]; // Obtener el detalle de la tienda
        const aps = storeData.result.storeAp.map((ap) => ({
          value: ap.AP_ID, // ID del AP
          label: `${ap.AP_NAME} (${ap.MAC_ADDRESS})`,         // Nombre del AP no puede ser el mismo en el multiselect
          key: ap.AP_ID 
        }));
         // Agrupar los APs bajo el STORE_NAME correspondiente
        groupedOptions.push({
          label: storeDetail.STORE_NAME, // Nombre del grupo
          items: aps                    // APs que pertenecen a este grupo
        });
      } else {
        console.error(`Error al cargar los APs de la tienda con ID ${storeId}`);
      }
    }
  
    // Actualizar las opciones del segundo dropdown con los grupos de APs obtenidos
    setSecondDropdownOptions(groupedOptions);
  
    // Obtener todos los valores (IDs de APs) para actualizar el formulario
    const allValues = groupedOptions.flatMap((group) =>
      group.items.map((item) => item.value)
    );
  
    setForm((prevForm) => ({
      ...prevForm,
      ap: allValues, 
    }));
  
    // Llamar a handleAPChange solo después de que todos los valores hayan sido establecidos
    if (handleAPChange) {
      handleAPChange({ value: allValues });
    }
  }, []);
  
// Usa useEffect para reaccionar a cambios en selectedStoreIds
useEffect(() => {
  //console.log("Tiendas seleccionadas (useEffect):", selectedStoreIds); // Log para depuración
}, [selectedStoreIds]); 

  const [options, setOptions] = useState({
    stores: [],
  });
  const [optionsD, setOptionsD] = useState({
    deparments: [],
  });

  // Cambio en el multiselect de APs
  const handleAPChange = useCallback((event) => {
    setAp(event.value);
    const selectedValues = event.value;
    // Convertir los valores seleccionados a una cadena separada por comas
    const selectedValuesString = selectedValues.join(",");
    //console.log("AP como cadena:", selectedValuesString);
    setApIds(selectedValuesString);
    setForm((prev) => ({
      ...prev,
      ap: event.value,
    }));
  }, []);

   
  const items = useMemo(() => {
    return getAllDashboards(dashboards);
  }, [dashboards]);

  const displayFilters =
    items.find((dashboard) => dashboard.id === activeDashboard)
      ?.displayFilters ?? false;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (items.length > 0) {
      // Asegúrate de que hay items para renderizar
      setLoading(false);
    }
  }, [items, dateRange, ap, apIds]);

  // Creación del componente que carga el iframe de Looker
  const items_component = useMemo(() => {
    return items.map((item) => (
      <PrivateRoute
        key={item.id}
        exact
        path={item.customPath}
        component={() => (
          <item.customComponent
            dateRange={dateRange}
            apIds={apIds}
            loading={loading}
            displayTitle={item.displayTitle}
            displayDownloadButton={item.displayDownloadButton}
            onInit={() => {
              setActiveDashboard(item.id);
            }}
          />
        )}
      />
    ));
  }, [items, dateRange, ap, apIds]);


  // Cambio en el multiselect de departamentos   
  const handleDepartmentChange = useCallback(async (event) => {
    // Verifica si hay sucursales seleccionadas
    if (!selectedStoreIds || selectedStoreIds.length === 0) {
      toast.setMessage(
        CONST.SEVERITY.ERROR,
        "Sucursales Seleccionadas",
         "Por favor seleccione al menos una sucursal antes de continuar."
      );
      setDepartment(null);
      return;
    }else{
      // Seleccionando el valor
      setDepartment(event.value);
      const selectedDepartments = event.value;
      //console.log(selectedDepartments);
      let groupedOptions = [];
      // Iterar sobre cada tienda seleccionada y obtener los detalles y APs filtrados por departamentos
      for (const storeId of selectedStoreIds) {
        const storeData = await getStoreDetail(storeId);
        //console.log("Datos de la tienda con ID", storeId, ":", storeData); // Log para depuración
        if (storeData.success) 
          {
            const storeDetail = storeData.result.storeDetail[0]; // Obtener el detalle de la tienda
            // Filtrar los APs por los departamentos seleccionados
            //console.log("Departamentos seleccionados:", selectedDepartments); // Log para depuración
            const aps = storeData.result.storeAp
            .filter(ap => selectedDepartments.includes(ap.DEPARMENT_ID))
            .map((ap) => ({
              value: ap.AP_ID, // ID del AP
              label: `${ap.AP_NAME} (${ap.MAC_ADDRESS})`, // Nombre del AP
              key: ap.AP_ID
            }));
          //console.log("APs filtrados por departamento de la tienda con ID", storeId, ":", aps); // Log para depuración

      // Agrupar los APs bajo el STORE_NAME correspondiente
      if (aps.length > 0) {
        groupedOptions.push({
          label: storeDetail.STORE_NAME, // Nombre del grupo
          items: aps                    // APs que pertenecen a este grupo
        });
      }
    } else {
      console.error(`Error al cargar los APs de la tienda con ID ${storeId}`);
    }
  }

    // Limpia todas las selecciones actuales en el MultiSelect
    setForm((prevForm) => ({
      ...prevForm,
      ap: []
    }));
  // Actualizar las opciones del segundo dropdown con los grupos de APs obtenidos
  setSecondDropdownOptions(groupedOptions);

  // Obtener todos los valores (IDs de APs) para actualizar el formulario
  const allValues = groupedOptions.flatMap((group) =>
    group.items.map((item) => item.value)
  );

  setForm((prevForm) => ({
    ...prevForm,
    ap: allValues, 
  }));

  // Llamar a handleAPChange solo después de que todos los valores hayan sido establecidos
  if (handleAPChange) {
    handleAPChange({ value: allValues });
  }
      
    }
  }, [selectedStoreIds]);

 
  // Verificando el tablero
  if (version === "looker") {
    return (
      <div className="statistics-main-container">
        <div className="statistics-menu-container">
          <NeoTitleMain title="Analíticos" icon={Icono} />
        </div>
        {displayFilters && (
          <div className="statistics-menu-filters">
            <NeoCalendar
              cols="3"
              label="Periodo"
              value={[form.from, form.to]}
              onChange={(e) => {
                const [startDate, endDate] = e.value;
                setForm((prev) => ({
                  ...prev,
                  from: startDate,
                  to: endDate,
                }));
                setDateRange((prev) => {
                  const newRange = { from: startDate, to: endDate };
                  return newRange;
                });
              }}
              selectionMode="range"
              readOnlyInput
              optionLabel="NAME"
              extra="custom-neocal-class"
            />
            <NeoMultiSelect
              label="Sucursal"
              value={store}
              onChange={handleStoreChange}
              options={options.stores}
              showSelectAll={options.stores?.length > 0}
              optionLabel="NAME"
              name="store"
              display="chip"
            />
            <NeoMultiSelect
              label="Departamento"
              value={department}
              onChange={handleDepartmentChange}
              cols="3"
              options={optionsD.deparments}
              showSelectAll={optionsD.deparments?.length > 0}
            />
            <NeoMultiSelect
              options={secondDropdownOptions}
              cols="3"
              onChange={handleAPChange}
              value={form.ap}
              label="AP"
              optionGroupLabel="label"
              optionGroupChildren="items"
              display="chip"
              isSelectAll={true}
              showSelectAll={secondDropdownOptions?.length > 0}
            />
          </div>
        )}

        <MenuBar
          model={items_menu.map((item) => ({
            label: item.label,
            icon: item.icon,
            command: item.command,
            className: item.className,
            items: item.items || undefined,
          }))}
          className="tab-style-menu"
          activeIndex={items_menu.findIndex(
            (item) => item.className === "active"
          )}
        />
        {loading && (
          <div className="loader">Loading...</div> // Mostrar loading cuando `loading` es true
        )}

        <div className="looker-container">
          <Switch>
            {items_component}
            <Route
              path="*"
              component={() => <Redirect to={Routes.statistics.home} />}
            />
          </Switch>
        </div>
      </div>
    );
  }
  if (version === "base") {
    return (
      <NeoGridContainer>
        <NeoTitleMain title="Dashboard" col="12" icon={Icono} />
        <NeoColumn md="12">
          <ChartSection />
        </NeoColumn>
      </NeoGridContainer>
    );
  }
  return null;
}
export default React.memo(Home);
