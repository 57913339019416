import { useEffect } from "react";
import React from "react";
import NeoTooltip from "../../../../design/design_components/neo/overlay/NeoTooltip.base";
import { Slider } from "primereact/slider";
import avatarNoShadow from "../../../../design/assets/img/wfi/avatar_no_shadow.svg"

/**
 * @typedef {Object} Props
 * @property {boolean} playing
 * @property {(previous: boolean) => boolean} setPlaying
 * @property {import("../../VisitDetails.view").UniquePathSegments} uniquePaths
 */

export default function Player(
  /** @type {Props} */ {
    slider,
    setSlider,
    uniquePaths,
    setModal,
    playing,
    setPlaying,
    style
  }
) {
  useEffect(() => {
    if (!playing) return;

    const interval = setInterval(() => {
      setSlider((slider) => {
        if (parseInt(slider.current + 1, 10) === parseInt(slider.length)) {
          setPlaying(false);
          return {
            ...slider,
            current: slider.length
          }
        }
        return {
          ...slider,
          current: (slider.current + 1) % slider.length
        };
      });
    }, 16);

    return () => {
      clearInterval(interval);
    };
  }, [playing, setPlaying, setSlider]);

  return (
    <div
      style={{
        ...style,
        padding: "0.5rem 1.5rem 1rem",
        borderRadius: "10000px",
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        boxShadow: "0px 4px 8.2px 0px rgba(0, 0, 0, 0.25)"
      }}
    >
      <button
        onClick={() => setPlaying((prev) => !prev)}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0.125rem 0 0 0",
          cursor: "pointer",
          border: "none",
          backgroundColor: "transparent"
        }}
      >
        {playing ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 24 24"
          >
            <path fill="#FF6464" d="M6 19h4V5H6zm8-14v14h4V5z" />
          </svg>
        ) : (
          <svg
            width="16"
            height="16"
            viewBox="0 0 11 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 14V0L11 7L0 14ZM2 10.35L7.25 7L2 3.65V10.35Z"
              fill="#FF6464"
            />
          </svg>
        )}
      </button>

      <div
        style={{
          width: "100%"
        }}
      >
        <div
          style={{
            height: "1rem",
            position: "relative"
          }}
        >
          {uniquePaths.map(({ path }, index, paths) => {
            let progressPct = 0;
            let leftRem = 0.2;
            let showFloorName = index === 0;

            const last = paths.length - 1;
            const isLastPath = index === last;
            const isFirstPath = index === 0;

            if (isLastPath) {
              progressPct = 100;
              leftRem = 0.4;
            } else {
              progressPct = (100 / (uniquePaths.length - 1)) * index;
            }

            if (index > 0) {
              const previousPath = paths[index - 1].path;
              if (previousPath.store_floor_id !== path.store_floor_id) {
                showFloorName = true;
              }
            }

            const amOrPm = path.created_at.getHours() >= 12 ? "PM" : "AM";
            const time = `${path.created_at
              .getHours()
              .toString()
              .padStart(2, "0")}:${path.created_at
              .getMinutes()
              .toString()
              .padStart(2, "0")} ${amOrPm}`;

            return (
              <React.Fragment key={(path.id ?? "") + index}>
                {(isFirstPath || showFloorName) && (
                  <div
                    style={{
                      position: "absolute",
                      width: "0.4rem",
                      height: "0.6rem",
                      left: `calc(${progressPct}% - ${leftRem}rem)`,
                      bottom: "0px"
                    }}
                    data-pr-at="right+5 top"
                    id={`player-ap-${index}`}
                  >
                    <svg
                      style={{
                        position: "absolute",
                        width: "0.4rem",
                        height: "0.6rem",
                        bottom: "0px"
                      }}
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.15479 0H0.654785V7.5L2.90479 9.5L5.15479 7.5V0Z"
                        fill="#17A2CD"
                      />
                    </svg>
                  </div>
                )}
                {!isFirstPath && !showFloorName && (
                  <div
                    style={{
                      position: "absolute",
                      width: "0.4rem",
                      height: "0.6rem",
                      left: `calc(${progressPct}% - ${leftRem}rem)`,
                      bottom: "0px"
                    }}
                    data-pr-at="right+5 top"
                    id={`player-ap-${index}`}
                  >
                    <svg
                      style={{
                        position: "absolute",
                        width: "0.4rem",
                        height: "0.6rem",
                        bottom: "0px"
                      }}
                      width="6"
                      height="10"
                      viewBox="0 0 6 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="ap-point-player"
                    >
                      <path d="M5.15479 0H0.654785V7.5L2.90479 9.5L5.15479 7.5V0Z" />
                    </svg>
                  </div>
                )}

                <NeoTooltip target={`#player-ap-${index}`} position="top">
                  <p>
                    {path.name} <br /> {path.store_floor_name} <br /> Hora{" "}
                    {time}
                  </p>
                </NeoTooltip>
              </React.Fragment>
            );
          })}
        </div>

        <div style={{position: "relative"}}>
          <Slider
            className="p-slider--orange"
            type="range"
            max={slider.length}
            value={slider.current}
            step={1}
            onChange={(e) => {
              const newPercentage = Number(e.value);
              setSlider((slider) => ({
                ...slider,
                current: newPercentage
              }));
            }}
            style={{
              width: "100%"
            }}
          />
          <div style={{position: "absolute", left: (() => {
            const total = slider.length
            const sliderPct = (slider.current / total) * 100;
            return `${sliderPct}%`;
          })(),
          transform: "translate(-50%, -99%)",
          top: "100%",
          zIndex: 2,
          pointerEvents: "none"
          }}>
            <img style={{width: 5, height: "auto"}} alt="avatar user" src={avatarNoShadow} />
          </div>
        </div>
      </div>
      <button
        style={{
          display: "flex",
          alignItems: "center",
          padding: "0.125rem 0 0 0",
          cursor: "pointer",
          border: "none",
          backgroundColor: "transparent"
        }}
        onClick={() => setModal(true)}
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 17.75H9C8.80109 17.75 8.61032 17.671 8.46967 17.5303C8.32902 17.3897 8.25 17.1989 8.25 17C8.25 16.8011 8.32902 16.6103 8.46967 16.4697C8.61032 16.329 8.80109 16.25 9 16.25H15C15.3315 16.25 15.6495 16.1183 15.8839 15.8839C16.1183 15.6495 16.25 15.3315 16.25 15V3C16.25 2.66848 16.1183 2.35054 15.8839 2.11612C15.6495 1.8817 15.3315 1.75 15 1.75H3C2.66848 1.75 2.35054 1.8817 2.11612 2.11612C1.8817 2.35054 1.75 2.66848 1.75 3V9C1.75 9.19891 1.67098 9.38968 1.53033 9.53033C1.38968 9.67098 1.19891 9.75 1 9.75C0.801088 9.75 0.610322 9.67098 0.46967 9.53033C0.329018 9.38968 0.25 9.19891 0.25 9V3C0.25 2.27065 0.539731 1.57118 1.05546 1.05546C1.57118 0.539731 2.27065 0.25 3 0.25H15C15.7293 0.25 16.4288 0.539731 16.9445 1.05546C17.4603 1.57118 17.75 2.27065 17.75 3V15C17.75 15.7293 17.4603 16.4288 16.9445 16.9445C16.4288 17.4603 15.7293 17.75 15 17.75Z"
            fill="#17A2CD"
          />
          <path
            d="M13 9.75C12.8019 9.74741 12.6126 9.66756 12.4725 9.52747C12.3324 9.38737 12.2526 9.19811 12.25 9V5.75H9C8.80109 5.75 8.61032 5.67098 8.46967 5.53033C8.32902 5.38968 8.25 5.19891 8.25 5C8.25 4.80109 8.32902 4.61032 8.46967 4.46967C8.61032 4.32902 8.80109 4.25 9 4.25H13C13.1981 4.25259 13.3874 4.33244 13.5275 4.47253C13.6676 4.61263 13.7474 4.80189 13.75 5V9C13.7474 9.19811 13.6676 9.38737 13.5275 9.52747C13.3874 9.66756 13.1981 9.74741 13 9.75Z"
            fill="#17A2CD"
          />
          <path
            d="M8.50006 10.2501C8.30712 10.2354 8.12763 10.1456 8.00006 10.0001C7.87708 9.86261 7.80908 9.6846 7.80908 9.50012C7.80908 9.31564 7.87708 9.13763 8.00006 9.00012L12.5001 4.50012C12.6422 4.36764 12.8303 4.29552 13.0246 4.29894C13.2189 4.30237 13.4043 4.38109 13.5417 4.5185C13.6791 4.65591 13.7578 4.8413 13.7612 5.0356C13.7647 5.2299 13.6925 5.41795 13.5601 5.56012L9.00006 10.0001C8.8725 10.1456 8.69301 10.2354 8.50006 10.2501Z"
            fill="#17A2CD"
          />
          <path
            d="M5 17.75H2C1.53668 17.7474 1.09309 17.5622 0.765464 17.2345C0.437842 16.9069 0.252625 16.4633 0.25 16V13C0.252625 12.5367 0.437842 12.0931 0.765464 11.7655C1.09309 11.4378 1.53668 11.2526 2 11.25H5C5.46332 11.2526 5.90691 11.4378 6.23454 11.7655C6.56216 12.0931 6.74738 12.5367 6.75 13V16C6.74738 16.4633 6.56216 16.9069 6.23454 17.2345C5.90691 17.5622 5.46332 17.7474 5 17.75ZM2 12.75C1.9337 12.75 1.87011 12.7763 1.82322 12.8232C1.77634 12.8701 1.75 12.9337 1.75 13V16C1.75 16.0663 1.77634 16.1299 1.82322 16.1768C1.87011 16.2237 1.9337 16.25 2 16.25H5C5.0663 16.25 5.12989 16.2237 5.17678 16.1768C5.22366 16.1299 5.25 16.0663 5.25 16V13C5.25 12.9337 5.22366 12.8701 5.17678 12.8232C5.12989 12.7763 5.0663 12.75 5 12.75H2Z"
            fill="#17A2CD"
          />
        </svg>
      </button>
    </div>
  );
}
