import React from "react";
import NeoInputText from "../../../../../../design/design_components/neo/form/NeoInputText.base";

export default function SubtitleText(props) {
  return (
    <>
      <NeoInputText
        value={props.text}
        label={"Subtítulo"}
        onChange={(e) => props.setText(e.target.value)}
      />
    </>
  );
}
