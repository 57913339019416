
import { useLooker } from "../../hooks/useLooker.hook";

const ContactsView = () => {
  const { url } = useLooker("6")
  return (
        <iframe
          title="Contactos"
          src={url}
          allowFullScreen
        />
  )
}

export default ContactsView;
